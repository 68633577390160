/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';
import { languageAction } from '../../../_actions';
import { useDispatch, useSelector } from 'react-redux';



let colors = [
  { color: '#4CD964', },
  { color: '#EAAB00', },
  { color: '#F4A26C', },
  { color: "#2D9CDB", },

  { color: "#FB5050", },

]

const DonutChart = ({ graphData }) => {
  let { data } = graphData && graphData ? graphData : {}
  let { languageCount } = data && data ? data : {}

  let dispatch = useDispatch()
  let selector = useSelector(state => state)
  useEffect(() => {
    let temp = {
      "keyWord": "",
      "pageNo": 1,
      "sortBy": "",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": 10
    }
    dispatch(languageAction.getLanguageList(temp))
  }, [])
  let { language } = selector || {}
  let { list, total } = language && language?.getlanguageSuccess ? language?.getlanguageSuccess : {}



  const result = languageCount && languageCount
    .sort((a, b) => b.value - a.value)
    .map((countItem) => {
      const language = list && total > 0 && list.find(lang => lang?.shortName.toLowerCase() === countItem?.name?.toLowerCase());
      if (language) {
        return { name: language.name, shortName: language.shortName, value: countItem.value };
      }
      return null;
    })
    .filter(Boolean)
    .slice(0, 5);

  const languageCountWithColor = languageCount && languageCount.length > 0 && languageCount.map((item, index) => ({
    ...item,
    color: colors && index < 5 && colors[index]?.color
  }));




  return (
    <ResponsiveContainer width="100%" height={400}>
      <PieChart className=" flex" >
        <Pie
          data={result}
          cx="50%"
          cy="50%"
          innerRadius={60}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
        >
          {result && languageCountWithColor.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        <Tooltip
          contentStyle={{ backgroundColor: "#37375C", border: '1px solid white', borderRadius: '0px 30px 30px 30px', color: 'white' }}
          labelStyle={{ fontWeight: 'bold', color: 'white' }}
          itemStyle={{ color: 'white' }}
        />
        <Legend iconType='circle' iconSize={10} />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default DonutChart;
