/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6"
export default function QuestionAddComponent({ setskillIndicator, skillIndicator, description, handleChanges, setDataToSubmitImage, setDataToSubmitAudio, singleQuestionData, dataToSubmit, setDataToSubmit, elem, setshortname, handleChange, handleChangeImage }) {
    let [open, setOpen] = useState(false)



    useEffect(() => {
        setshortname({ shortName: elem?.shortName })
        // dispatch(questionaction.getAllOption())
    }, [open])


    let handleOpen = () => {
        setOpen(true);
    }

    useEffect(() => {
        setDataToSubmit(prevDataToSubmit => ({
            ...prevDataToSubmit,
            [elem.shortName]: singleQuestionData?.data?.question?.[elem.shortName] || ""
        }));
        setDataToSubmitImage(prevDataToSubmit => ({
            ...prevDataToSubmit,
            [elem.shortName]: singleQuestionData?.data?.image?.[elem.shortName] || ""
        }));
        setDataToSubmitAudio(prevDataToSubmit => ({
            ...prevDataToSubmit,
            [elem.shortName]: singleQuestionData?.data?.audio?.[elem.shortName] || ""
        }));
    }, [singleQuestionData]);



    return (
        <div className=' mb-2'>
            <div  >
                {!open && <div className=' bg-[#C7C7C7] px-2 py-1 items-center mb-1 flex justify-between'>
                    <div>{elem && elem?.name ? elem?.name : ""}</div>
                    <FaPlus onClick={handleOpen} />
                </div>}


                {open && <div className='flex  flex-col items-center justify-center gap-2 relative  p-2 bg-[#EAEAEA]'>
                    <div className='flex gap-2 items-center w-[90%] '>
                        <label className='w-[9%] md:block hidden' >Language</label>
                        <input disabled name='language' className='w-full px-2 py-1 rounded-md outline-none ' type='text' value={elem && elem?.name ? elem?.name : ""} />

                    </div>
                    <div className=' top-0 p-2 absolute right-0 cursor-pointer'> <FaMinus onClick={() => setOpen(false)} /></div>
                    <div className='flex gap-2 flex-wrap w-[90%]'>
                        <label className='w-[9%] ' >name</label>
                        <input
                            id={elem.shortName}
                            value={dataToSubmit && dataToSubmit[elem.shortName] ? dataToSubmit[elem.shortName] : ""}
                            name={elem.shortName}
                            onChange={handleChange}
                            rows={5}
                            className='resize-none w-full px-2 py-1 rounded-md'
                        />
                    </div>

                    <div className='flex  flex-wrap gap-2 w-[90%]'>
                        <label className='w-[9%]' >Description</label>
                        <textarea
                            id={elem.shortName}
                            value={description && description[elem.shortName] ? description[elem.shortName] : ""}
                            name={elem.shortName}
                            onChange={handleChanges}
                            rows={5}
                            className='resize-none w-full px-2 py-1 rounded-md'
                        />
                    </div>
                </div>}

            </div>
        </div>
    )
}
