/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { languageAction } from '../../_actions/language.action';
import IntroductionComponent from './component/IntroductionComponent';
import { useNavigate, useParams } from 'react-router';
import axios from "axios";
import { authHeader } from '../../_helpers';
import { IntroductionAction } from '../../_actions';




const IntroductionById = () => {

  let dispatch = useDispatch()
  let selector = useSelector(state => state)
  let params = useParams()
  useEffect(() => {
    let temp = {
      "keyWord": "",
      "pageNo": 1,
      "sortBy": "",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": 150
    }
    let data = {
      "introductionId": params?.id
    }
    dispatch(languageAction.getLanguageList(temp))
    // dispatch(languageAction.getServiceById(data))
    dispatch(IntroductionAction.getIntroductionById(data))

  }, [])

  let [textdata, setTextdata] = useState({})

  const handleTextEditor = (content, delta) => {
    console.log(content)
    setTextdata({ ...textdata, [delta]: content })
  };

  // const handleTextEditor = (e) => {
  //   setTextdata({...textdata,[e.target.name]:e.target.value})

  // };

  let [dataToSubmitAudio, setDataToSubmitAudio] = useState({
  })
  let [valuetoSubmit, setvaluetoSubmit] = useState({})
  let handleChange = (e) => {
    let { name, value } = e.target
    setvaluetoSubmit({ ...valuetoSubmit, [name]: value })

  }

  let [dataToSubmitImage, setDataToSubmitImage] = useState({
  })
  let handleChangeImage = async (e) => {
    let { name } = e.target;
    const formData = new FormData();
    formData.append('image', e.target.files[0]);
    try {
      const response = await axios.post(`https://orkid.jamsara.com/api/upload`, formData, {
        headers: {
          'Authorization': authHeader().Authorization,
          'Content-Type': 'multipart/form-data'
        }
      });
      const responseData = response.data;

      console.log(responseData?.imageURL);
      setDataToSubmitImage({ ...dataToSubmitImage, [name]: responseData?.imageURL });
      return responseData;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  let navigate = useNavigate()

  let temp = {
    "keyWord": "",
    "pageNo": 1,
    "sortBy": "slug",
    "sortOrder": "asc",
    "fromDate": "",
    "toDate": "",
    "size": 100
  }

  let create = (obj) => {
    dispatch(IntroductionAction.updateIntroduction(obj, temp))
    navigate('/app/introduction')
  }





  let handleUpdateservice = () => {

    let obj = {
      id: params.id,
      "name": valuetoSubmit,
      "image": dataToSubmitImage,
      "content": textdata,
      "audio": dataToSubmitAudio
    }

    create(obj)

  }

  let { language, IntroductionReducer } = selector;
  let { getIntroById } = IntroductionReducer && IntroductionReducer ? IntroductionReducer : {}
  let { getlanguageSuccess } = language || {}
  let { data } = getIntroById && getIntroById ? getIntroById : {}
  let { list, total } = getlanguageSuccess && getlanguageSuccess ? getlanguageSuccess : {}


  return (
    <>
      <div className=' h-[90vh] w-full overflow-y-scroll py-3 px-5 flex '>
        <div className=' p-10 w-full h-full flex flex-col gap-4'>
          {list && total > 0 && list.map((elem, index) => (
            <div key={index}>
              <IntroductionComponent elem={elem} handleChange={handleChange} valuetoSubmit={valuetoSubmit} setvaluetoSubmit={setvaluetoSubmit} data={data} setTextdata={setTextdata} handleTextEditor={handleTextEditor} textdata={textdata}
                dataToSubmitAudio={dataToSubmitAudio}
                setDataToSubmitAudio={setDataToSubmitAudio}
                dataToSubmitImage={dataToSubmitImage}
                setDataToSubmitImage={setDataToSubmitImage}
                handleChangeImage={handleChangeImage}


              />
            </div>
          ))}
          <div className=' w-full flex' >
            <div onClick={handleUpdateservice}>

              <button className=' w-[147px] h-[40px] bg-[#1E5EFF] flex justify-center items-center text-white gap-1 rounded-md mt-4'>Update</button>
            </div>

          </div>

        </div>
      </div>

    </>
  );
};

export default IntroductionById;
