import { languageConstants } from "../_constants";



export default function language(state = {}, action) {
  switch (action.type) {

    


    
    
case languageConstants.CREATE_NEW_LANGUAGE_REQUEST:
  return {
    ...state,
    loading: true
  };
case languageConstants.CREATE_NEW_LANGUAGE_SUCCESS:
  return {
    ...state,

    loading: false,

  };
case languageConstants.CREATE_NEW_LANGUAGE_FAILURE:
  return {
    ...state,
    loading: false,
    error: action.error
  };  





    case languageConstants.UPDATE_NEW_LANGUAGE_SETTING_REQUEST:
      return {
        ...state,
        loading: true
      };
    case languageConstants.UPDATE_NEW_LANGUAGE_SETTING_SUCCESS:
      return {
        ...state,

        loading: false,
    
      };
    case languageConstants.UPDATE_NEW_LANGUAGE_SETTING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };  
    
    
case languageConstants.GET_LANGUAGE_BY_ID_REQUEST:
  return {
    ...state,
    loading: true
  };
case languageConstants.GET_LANGUAGE_BY_ID_SUCCESS:
  return {
    ...state,
    languageSettingTwoById:action?.user,
    loading: false,

  };
case languageConstants.GET_LANGUAGE_BY_ID_FAILURE:
  return {
    ...state,
    loading: false,
    error: action.error
  };  



    
    case languageConstants.GET_LANGUAGE_SETTING_2_REQUEST:
      return {
        ...state,
        loading: true
      };
    case languageConstants.GET_LANGUAGE_SETTING_2_SUCCESS:
      return {
        ...state,
        languageSettingTwo:action?.user,
        loading: false,
    
      };
    case languageConstants.GET_LANGUAGE_SETTING_2_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };  



    
    

    case languageConstants.GET_NOTIFICATION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case languageConstants.GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        getnotification:action?.user,
        loading: false,
    
      };
    case languageConstants.GET_NOTIFICATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };  





case languageConstants.NOTIFICATION_SEND_REQUEST:
  return {
    ...state,
    loading: true
  };
case languageConstants.NOTIFICATION_SEND_SUCCESS:
  return {
    ...state,
    loading: false,

  };
case languageConstants.NOTIFICATION_SEND_FAILURE:
  return {
    ...state,
    loading: false,
    error: action.error
  };  



    
    
case languageConstants.UPDATE_FIRST_SCREEN_REQUEST:
  return {
    ...state,
    loading: true
  };
case languageConstants.UPDATE_FIRST_SCREEN_SUCCESS:
  return {
    ...state,
    loading: false,

  };
case languageConstants.UPDATE_FIRST_SCREEN_FAILURE:
  return {
    ...state,
    loading: false,
    error: action.error
  };    





    case languageConstants.GET_FIRST_SCREEN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case languageConstants.GET_FIRST_SCREEN_SUCCESS:
      return {
        ...state,
        firstScreenData:action?.user,
        loading: false,

      };
    case languageConstants.GET_FIRST_SCREEN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };    
    
    
    
    


case languageConstants.TICKET_RESOLVE_REQUEST:
  return {
    ...state,
    loading: true
  };
case languageConstants.TICKET_RESOLVE_SUCCESS:
  return {
    ...state,
    loading: false,
  };
case languageConstants.TICKET_RESOLVE_FAILURE:
  return {
    ...state,
    loading: false,
    error: action.error
  };    



case languageConstants.REPLY_BY_ADMIN_FOR_TICKET_REQUEST:
  return {
    ...state,
    loading: true
  };
case languageConstants.REPLY_BY_ADMIN_FOR_TICKET_SUCCESS:
  return {
    ...state,
    loading: false,
    replyByAdmin:action?.user

  };
case languageConstants.REPLY_BY_ADMIN_FOR_TICKET_FAILURE:
  return {
    ...state,
    loading: false,
    error: action.error
  };    





    
  

case languageConstants.GET_CHAT_BY_ID_REQUEST:
  return {
    ...state,
    loading: true
  };
case languageConstants.GET_CHAT_BY_ID_SUCCESS:
  return {
    ...state,
    loading: false,
    getChatById:action?.user

  };
case languageConstants.GET_CHAT_BY_ID_FAILURE:
  return {
    ...state,
    loading: false,
    error: action.error
  };    


    case languageConstants.GET_ALL_TICKET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case languageConstants.GET_ALL_TICKET_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllTicket:action?.user
    
      };
    case languageConstants.GET_ALL_TICKET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };






    
case languageConstants.GET_ALL_DONER_REQUEST:
  return {
    ...state,
    loading: true
  };
case languageConstants.GET_ALL_DONER_SUCCESS:
  return {
    ...state,
    loading: false,
    getAllDoner:action?.user

  };
case languageConstants.GET_ALL_DONER_FAILURE:
  return {
    ...state,
    loading: false,
    error: action.error
  };





    case languageConstants.GET_SKILL_ALL_INDICATOR_REQUEST:
      return {
        ...state,
        loading: true
      };
    case languageConstants.GET_SKILL_ALL_INDICATOR_SUCCESS:
      return {
        ...state,
        loading: false,
        getSkillAllIndicatior:action?.user
    
      };
    case languageConstants.GET_SKILL_ALL_INDICATOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    
    



case languageConstants.DELETE_SKILL_REQUEST:
  return {
    ...state,
    loading: true
  };
case languageConstants.DELETE_SKILL_SUCCESS:
  return {
    ...state,
    loading: false,

  };
case languageConstants.DELETE_SKILL_FAILURE:
  return {
    ...state,
    loading: false,
    error: action.error
  };




    case languageConstants.UPDATE_Skill_REQUEST:
      return {
        ...state,
        loading: true
      };
    case languageConstants.UPDATE_Skill_SUCCESS:
      return {
        ...state,
        loading: false,

      };
    case languageConstants.UPDATE_Skill_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case languageConstants.SKILL_ADD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case languageConstants.SKILL_ADD_SUCCESS:
      return {
        ...state,
        loading: false,

      };
    case languageConstants.SKILL_ADD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    
        



    
case languageConstants.GET_SKILL_INDICATOR_REQUEST:
  return {
    ...state,
    loading: true
  };
case languageConstants.GET_SKILL_INDICATOR_SUCCESS:
  return {
    ...state,
    loading: false,
    SkillList:action?.user
  };
case languageConstants.GET_SKILL_INDICATOR_FAILURE:
  return {
    ...state,
    loading: false,
    error: action.error
  };

    
    
    

    case languageConstants.DELETE_BOARD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case languageConstants.DELETE_BOARD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case languageConstants.DELETE_BOARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    




case languageConstants.UPDATE_BOARD_REQUEST:
  return {
    ...state,
    loading: true
  };
case languageConstants.UPDATE_BOARD_SUCCESS:
  return {
    ...state,
    loading: false,
  };
case languageConstants.UPDATE_BOARD_FAILURE:
  return {
    ...state,
    loading: false,
    error: action.error
  };




    case languageConstants.BOARD_ADD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case languageConstants.BOARD_ADD_SUCCESS:
      return {
        ...state,
        BoardData: action?.user,
        loading: false,
      };
    case languageConstants.BOARD_ADD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case languageConstants.ADD_LANGUAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case languageConstants.ADD_LANGUAGE_SUCCESS:
      return {
        ...state,
        addlanguageSuccess: false,
        loading: false,
      };
    case languageConstants.ADD_LANGUAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



      case languageConstants.GET_LANGUAGE_REQUEST:
        return {
          ...state,
          loading: true
        };
      case languageConstants.GET_LANGUAGE_SUCCESS:
        return {
          ...state,
          getlanguageSuccess: action?.user?.data||{},
          loading: false,
        };
      case languageConstants.GET_LANGUAGE_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error
        };


        case languageConstants.UPDATE_LANGUAGE_REQUEST:
          return {
            ...state,
            loading: true
          };
        case languageConstants.UPDATE_LANGUAGE_SUCCESS:
          return {
            ...state,
            getlanguageSuccess: action?.user?.data||{},
            loading: false,
          };
        case languageConstants.UPDATE_LANGUAGE_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.error
          };
  
          case languageConstants.DELETE_LANGUAGE_REQUEST:
          return {
            ...state,
            loading: true
          };
        case languageConstants.DELETE_LANGUAGE_SUCCESS:
          return {
            ...state,
            getlanguageSuccess: action?.user?.data||{},
            loading: false,
          };
        case languageConstants.DELETE_LANGUAGE_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.error
          };
  


          case languageConstants.LANGUAGE_SETTING_ADD_REQUEST:
            return {
              ...state,
              loading: true
            };
          case languageConstants.LANGUAGE_SETTING_ADD_SUCCESS:
            return {
              ...state,
              loading: false,
            };
          case languageConstants.LANGUAGE_SETTING_ADD_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };
    

            case languageConstants.GET_LANGUAGE_SETTING_REQUEST:
              return {
                ...state,
                loading: true
              };
            case languageConstants.GET_LANGUAGE_SETTING_SUCCESS:
              return {
                ...state,
                getlanguageSetting: action?.user?.data||{},
                loading: false,
              };
            case languageConstants.GET_LANGUAGE_SETTING_FAILURE:
              return {
                ...state,
                loading: false,
                error: action.error
              };





              case languageConstants.GET_LOGIN_HISTORY_REQUEST:
                return {
                  ...state,
                  loading: true
                };
              case languageConstants.GET_LOGIN_HISTORY_SUCCESS:
                return {
                  ...state,
                  loginHistoryData: action?.user?.data||{},
                  loading: false,
                };
              case languageConstants.GET_LOGIN_HISTORY_FAILURE:
                return {
                  ...state,
                  loading: false,
                  error: action.error
                };
  



              


  



        case languageConstants.UPDATE_LANGUAGE_SETTING_REQUEST:
          return {
            ...state,
            loading: true
          };
        case languageConstants.UPDATE_LANGUAGE_SETTING_SUCCESS:
          return {
            ...state,
            loading: false,
          };
        case languageConstants.UPDATE_LANGUAGE_SETTING_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.error
          };
  




          case languageConstants.DELETE_LANGUAGE_SETTING_REQUEST:
          return {
            ...state,
            loading: true
          };
        case languageConstants.DELETE_LANGUAGE_SETTING_SUCCESS:
          return {
            ...state,
            loading: false,
          };
        case languageConstants.DELETE_LANGUAGE_SETTING_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.error
          };
  

          
          
          


          case languageConstants.FILE_UPLOADED_REQUEST:
            return {
              ...state,
              loading: true
            };
          case languageConstants.FILE_UPLOADED_SUCCESS:
            return {
              ...state,
              loading: false,
              filedata:action.user
            };
          case languageConstants.FILE_UPLOADED_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };
            case languageConstants.FILE_UPLOADED_CLEAR:
              return {
                ...state,
                loading: false,
                filedata:{}
              };
      
      

              


             
       

case languageConstants.GET_SINGLE_SERVICE_REQUEST:
  return {
    ...state,
    loading: true
  };
case languageConstants.GET_SINGLE_SERVICE_SUCCESS:
  return {
    ...state,
    getSingleServiceData: action?.user?.data||{},
    loading: false,
  };
case languageConstants.GET_SINGLE_SERVICE_FAILURE:
  return {
    ...state,
    loading: false,
    error: action.error
  };





    default:
      return state
  }
}
