import {
  questionConstants
} from '../_constants';

export default function QuestionsManagement(state = {}, action) {
  switch (action.type) {
    
    
    


    case  questionConstants.GET_QUESTION_BY_SETID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case  questionConstants.GET_QUESTION_BY_SETID_SUCCESS:
      return {
        ...state,
        questionList: action?.user,
        loading: false,
      };
    case  questionConstants.GET_QUESTION_BY_SETID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case  questionConstants.QUESTION_GET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case  questionConstants.QUESTION_GET_SUCCESS:
      return {
        ...state,
        questionList: action?.user,
        loading: false,
      };
    case  questionConstants.QUESTION_GET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




      case questionConstants.GET_ALL_OPTIONS_REQUEST:
        return {
          ...state,
          loading: true
        };
      case questionConstants.GET_ALL_OPTIONS_SUCCESS:
        return {
          ...state,
          allOptionsData :action?.user,
          loading: false,
        };
      case questionConstants.GET_ALL_OPTIONS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error
        };




        case questionConstants.QUESTION_CREATE_REQUEST:
          return {
            ...state,
            loading: true
          };
        case questionConstants.QUESTION_CREATE_SUCCESS:
          return {
            ...state,
            loading: false,
          };
        case questionConstants.QUESTION_CREATE_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.error
          };

          case questionConstants.FILE_AUDIO_UPLOADED_REQUEST:
            return {
              ...state,
              loading: true
            };
          case questionConstants.FILE_AUDIO_UPLOADED_SUCCESS:
            return {
              ...state,
              loading: false,
              filedataAudio:action.user
            };
          case questionConstants.FILE_AUDIO_UPLOADED_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };
            case questionConstants.FILE_AUDIO_UPLOADED_CLEAR:
              return {
                ...state,
                loading: false,
                filedata:{}
              };
      


              case questionConstants.GET_SINGLE_QUESTION_REQUEST:
                return {
                  ...state,
                  loading: true
                };
              case questionConstants.GET_SINGLE_QUESTION_SUCCESS:
                return {
                  ...state,
                  loading: false,
                  singleQuestionData : action.user
                };
              case questionConstants.GET_SINGLE_QUESTION_FAILURE:
                return {
                  ...state,
                  loading: false,
                  error: action.error
                };
      



              
              
              

    default:
      return state
  }
}

