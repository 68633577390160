/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { languageAction } from '../../_actions/language.action';
import ServicesComponent from './component/ServicesComponent';
import { useNavigate, useParams } from 'react-router';
import axios from "axios";
import { authHeader } from '../../_helpers';
import Loader from '../../components/Loader/Loader';
import ServiceJOSN from './ServiceById.json'



const LanguageSettingNewAddById = () => {

  let dispatch = useDispatch()
  let selector = useSelector(state => state)
  let params = useParams()
  let temp = {
    "keyWord": "",
    "pageNo": 1,
    "sortBy": "",
    "sortOrder": "asc",
    "fromDate": "",
    "toDate": "",
    "size": 150
  }
  useEffect(() => {

    let data = {
      "id": params?.id
    }
    dispatch(languageAction.getLanguageList(temp))
    dispatch(languageAction.getLanguageSetting2ById(data))

  }, [])

  let [textdata, setTextdata] = useState({})

  // const handleTextEditor = (content, delta) => {
  //   setTextdata({...textdata,[delta]:content})
  // };

  const handleTextEditor = (e) => {
    setTextdata({ ...textdata, [e.target.name]: e.target.value })
    // setTextdata({...textdata,[delta]:content})
  };


  let [valuetoSubmit, setvaluetoSubmit] = useState({})
  let handleChange = (e) => {
    let { name, value } = e.target
    setvaluetoSubmit({ [name]: value })

  }

  let [dataToSubmitImage, setDataToSubmitImage] = useState({
  })
  let handleChangeImage = async (e) => {
    let { name } = e.target;
    const formData = new FormData();
    formData.append('image', e.target.files[0]);
    try {
      const response = await axios.post(`https://orkid.jamsara.com/api/upload`, formData, {
        headers: {
          'Authorization': authHeader().Authorization,
          'Content-Type': 'multipart/form-data'
        }
      });
      const responseData = response.data;

      console.log(responseData?.imageURL);
      setDataToSubmitImage({ ...dataToSubmitImage, [name]: responseData?.imageURL });
      return responseData;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  let navigate = useNavigate()
  let create = (obj) => {
    console.log(obj)

    dispatch(languageAction.updateLanguageSetting2(obj, temp))
    navigate("/app/languageSetting")
  }
  let handleUpdateservice = () => {
    let { language } = selector;
    let { languageSettingTwoById } = language && language ? language : {}
    let { data } = languageSettingTwoById && languageSettingTwoById ? languageSettingTwoById : {}
    let obj = {
      id: params.id,
      "name": data.name || "",
      "key": data.key || "",
      "value": textdata
    }
    create(obj)

  }

  let { language } = selector;
  let { getlanguageSuccess, getSingleServiceData, loading } = language || {}

  let { languageSettingTwoById } = language && language ? language : {}
  let { data } = languageSettingTwoById && languageSettingTwoById ? languageSettingTwoById : {}

  let { list, total } = getlanguageSuccess && getlanguageSuccess ? getlanguageSuccess : {}


  return (
    <>
      <Loader loading={loading} />
      <div className=' h-[90vh] w-full overflow-y-scroll py-3 px-5 flex flex-col'>
        <div className=' flex justify-between p-5'>
          <div className=' font-semibold text-2xl whitespace-nowrap'>{ServiceJOSN.Heading}</div>
        </div>
        <div className=' p-10 w-full h-full flex flex-col gap-4'>
          {list && total > 0 && list.map((elem, index) => (
            <div key={index}>
              <ServicesComponent data={data} elem={elem} handleChange={handleChange} valuetoSubmit={valuetoSubmit} setvaluetoSubmit={setvaluetoSubmit} getSingleServiceData={getSingleServiceData} setTextdata={setTextdata} handleTextEditor={handleTextEditor} textdata={textdata}
                dataToSubmitImage={dataToSubmitImage}
                setDataToSubmitImage={setDataToSubmitImage}
                handleChangeImage={handleChangeImage}

              />
            </div>
          ))}
          <div className=' w-full flex' >
            <div onClick={handleUpdateservice}>

              <button className=' w-[147px] h-[40px] bg-[#1E5EFF] flex justify-center items-center text-white gap-1 rounded-md mt-4'>Update</button>
            </div>

          </div>

        </div>
      </div>

    </>
  );
};

export default LanguageSettingNewAddById;
