import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../_actions';
import LoginJSON from './login.json'
import { useNavigate } from 'react-router';
import Loading from "../../components/Loader/Loader"

const Login = () => {
  const dispatch = useDispatch();
  let selector = useSelector(state => state)
  const navigate = useNavigate();
  const [fieldslogin, setFieldsLogin] = useState({});
  const [errorslogin, setErrorsLogin] = useState({});

  const inputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsLogin(prevState => ({ ...prevState, [name]: value }));
    setErrorsLogin(prevState => ({ ...prevState, [name]: "" }));
  };

  const loginSubmit = (e) => {
    e.preventDefault();
    if (handleValidationLogin()) {
      const { userName, password } = fieldslogin;
      dispatch(userActions.login({ userName: userName, password: password }, navigate ));
    }
  };

  const handleValidationLogin = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldslogin.userName ) {
      formIsValid = false;
      errors.userName = LoginJSON.ErrorMsg.userName;
    }

    if (!fieldslogin.password) {
      formIsValid = false;
      errors.password = LoginJSON.ErrorMsg.password;
    }

    setErrorsLogin(errors);
    return formIsValid;
  };


  let {users} =selector&&selector?selector:{}
  let {loading} = users&&users?users:{}


  return (
    <>
      <Loading loading={loading}/>   
    <section className="bg-gray-50 h-[100vh] flex justify-center items-center dark:bg-gray-900">
  <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
    <div className=" md:min-w-[30rem]  w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
      <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
          Admin Login
        </h1>
        <form className="space-y-4 md:space-y-6" onSubmit={loginSubmit}>
          <div>
            <label
              htmlFor="userName"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              User Name
            </label>
            <input
              type="text"
              name="userName"
              onChange={inputChange}
              id="userName"
              className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="User Name"
              required=""
            />
{errorslogin["userName"] ?
    <div className="invalid-feedback text-red-500">
      {errorslogin["userName"]}
    </div>
    : null}
          </div>
          <div>
            <label
              htmlFor="password"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Password
            </label>
            <input
              type="password"
              name="password"
              id="password"
              placeholder="••••••••"
              onChange={inputChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required=""
            />
                                {errorslogin["password"] ?
                      <div className="invalid-feedback text-red-500">
                        {errorslogin["password"]}
                      </div>
                      : null}
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-start">
            </div>
          </div>
          <button
            type="submit"
            className="w-full text-white bg-blue-600 bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
          >
            Sign in
          </button>
          <p className="text-sm font-light text-gray-500 dark:text-gray-400">
          </p>
        </form>
      </div>
    </div>
  </div>
</section>

    </>
  );
};

export default Login;
