/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { languageAction } from '../../_actions/language.action';
import ServicesComponent from './component/ServicesComponent';
import { useNavigate, useParams } from 'react-router';
import axios from "axios";
import { authHeader } from '../../_helpers';
import { serviceAction } from '../../_actions';
import Loader from '../../components/Loader/Loader';
import ServiceByIdJSON from './ServiceById.json'




const ServiceById = () => {

  let dispatch = useDispatch()
  let selector = useSelector(state => state)
  let params = useParams()
  let temp = {
    "keyWord": "",
    "pageNo": 1,
    "sortBy": "",
    "sortOrder": "asc",
    "fromDate": "",
    "toDate": "",
    "size": 150
  }
  useEffect(() => {

    let data = {
      "serviceId": params?.id
    }
    dispatch(languageAction.getLanguageList(temp))
    dispatch(languageAction.getServiceById(data))

  }, [])

  let [textdata, setTextdata] = useState({})



  const handleTextEditor = (e, name) => {
    setTextdata({ ...textdata, [name]: e })
  };




  let [valuetoSubmit, setvaluetoSubmit] = useState({})
  let handleChange = (e) => {
    let { name, value } = e.target
    setvaluetoSubmit({ ...valuetoSubmit, [name]: value })

  }

  let [dataToSubmitImage, setDataToSubmitImage] = useState({
  })
  let handleChangeImage = async (e) => {
    let { name } = e.target;
    const formData = new FormData();
    formData.append('image', e.target.files[0]);
    try {
      const response = await axios.post(`https://orkid.jamsara.com/api/upload`, formData, {
        headers: {
          'Authorization': authHeader().Authorization,
          'Content-Type': 'multipart/form-data'
        }
      });
      const responseData = response.data;

      console.log(responseData?.imageURL);
      setDataToSubmitImage({ ...dataToSubmitImage, [name]: responseData?.imageURL });
      return responseData;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  let navigate = useNavigate()
  let create = (obj) => {
    dispatch(serviceAction.updateService(obj, temp))
    navigate("/app/service")
  }
  let handleUpdateservice = () => {

    let obj = {
      id: params.id,
      "service": valuetoSubmit,
      "image": dataToSubmitImage,
      "content": textdata
    }
    create(obj)

  }

  let { language } = selector;
  let { getlanguageSuccess, getSingleServiceData, loading } = language ? language : {}
  let { list, total } = getlanguageSuccess && getlanguageSuccess ? getlanguageSuccess : {}


  return (
    <>
      <Loader loading={loading} />
      <div className=' h-[90vh] w-full overflow-y-scroll py-3 px-5 flex flex-col '>
        <div className=' flex justify-between p-5'>
          <div className=' font-semibold text-2xl whitespace-nowrap'>{ServiceByIdJSON.Heading}</div>
        </div>
        <div className=' p-10 w-full h-full flex flex-col gap-4'>
          {list && total > 0 && list.filter((ele) => ele.shortName === "en").map((elem, index) => {
            return <div key={index}>
              <ServicesComponent setTextdata={setTextdata} textdata={textdata} elem={elem} handleChange={handleChange} valuetoSubmit={valuetoSubmit} setvaluetoSubmit={setvaluetoSubmit} getSingleServiceData={getSingleServiceData} handleTextEditor={handleTextEditor}
                dataToSubmitImage={dataToSubmitImage}
                setDataToSubmitImage={setDataToSubmitImage}
                handleChangeImage={handleChangeImage}

              />
            </div>
          })}
          <div className=' w-full flex' >
            <div onClick={handleUpdateservice}>

              <button className=' w-[147px] h-[40px] bg-[#1E5EFF] flex justify-center items-center text-white gap-1 rounded-md mt-4'>Update</button>
            </div>

          </div>

        </div>
      </div>

    </>
  );
};

export default ServiceById;
