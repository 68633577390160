export const reportConstants = {


GET_ALL_SKILL_ANALYSIS_REQUEST:"GET_ALL_SKILL_ANALYSIS_REQUEST",
GET_ALL_SKILL_ANALYSIS_SUCCESS:"GET_ALL_SKILL_ANALYSIS_SUCCESS",
GET_ALL_SKILL_ANALYSIS_FAILURE:"GET_ALL_SKILL_ANALYSIS_FAILURE",

CREATE_SKILL_ANALYSIS_REQUEST:"CREATE_SKILL_ANALYSIS_REQUEST",
CREATE_SKILL_ANALYSIS_SUCCESS:"CREATE_SKILL_ANALYSIS_SUCCESS",
CREATE_SKILL_ANALYSIS_FAILURE:"CREATE_SKILL_ANALYSIS_FAILURE",


GET_SKILL_BY_ID_REQUEST:"GET_SKILL_BY_ID_REQUEST",
GET_SKILL_BY_ID_SUCCESS:"GET_SKILL_BY_ID_SUCCESS",
GET_SKILL_BY_ID_FAILURE:"GET_SKILL_BY_ID_FAILURE",

UPDATE_REPORT_REQUEST:"UPDATE_REPORT_REQUEST",
UPDATE_REPORT_SUCCESS:"UPDATE_REPORT_SUCCESS",
UPDATE_REPORT_FAILURE:"UPDATE_REPORT_FAILURE",



DELETE_SKILL_ANALYSIS_REQUEST:"DELETE_SKILL_ANALYSIS_REQUEST",
DELETE_SKILL_ANALYSIS_SUCCESS:"DELETE_SKILL_ANALYSIS_SUCCESS",
DELETE_SKILL_ANALYSIS_FAILURE:"DELETE_SKILL_ANALYSIS_FAILURE",

};
