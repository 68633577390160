/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import IntroductionJSON from './Introduction.json'
import Model from './Modle/Model';
import Modelupdate from './Modle/Modelupdate';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { IntroductionAction, languageAction } from '../../_actions';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';

import Loading from "../../components/Loader/Loader"
const StaticAudio = () => {
  let dispatch = useDispatch()
  let selector = useSelector(state => state)

  //create
  let [openAddmodel, setopenAddmodel] = useState(false)
  const [formData, setFormData] = useState({});
  let [errorCreate, seterrorCreate] = useState({})

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    seterrorCreate({ ...errorCreate, [e.target.name]: "" })

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleValidationCreate()) {
      let datas = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
      }
      dispatch(IntroductionAction.getPageList(formData, datas))
      setFormData({})
      seterrorCreate({})
      setopenAddmodel(false)
    }

  };

  const handleValidationCreate = () => {

    let formIsValid = true;
    let errors = {};

    if (!formData.fullName || formData.fullName === "") {
      formIsValid = false;
      errors.fullName = "Name cannot be empty";
    }

    if (!formData.email) {
      formIsValid = false;
      errors.email = "Email cannot be empty";
    }
    if (!formData.mobNo) {
      formIsValid = false;
      errors.mobNo = "Mobile Number cannot be empty";
    }
    if (!formData.dob) {
      formIsValid = false;
      errors.dob = "Date of Birth cannot be empty";
    }
    if (!formData.gender) {
      formIsValid = false;
      errors.gender = "Gender  cannot be empty";
    }

    if (!formData.email) {
      formIsValid = false;
      errors.email = "Email  cannot be empty";
    }

    if (!formData.grade) {
      formIsValid = false;
      errors.grade = "Grade  cannot be empty";
    }
    seterrorCreate(errors);
    return formIsValid;
  };


  // updatemodel
  let [openAddmodelUpdate, setopenAddmodelUpdate] = useState(false)
  let [updatedata, setupdatedata] = useState({})
  let [updateError, setupdateError] = useState({})

  let handleChangeUpdate = (e) => {

    setupdatedata({
      ...updatedata,
      [e.target.name]: e.target.value
    });
    setupdateError({ ...errorCreate, [e.target.name]: "" })

  }

  let handleSubmitUpdate = (e) => {
    e.preventDefault();
    if (handleValidationUpdate()) {


      const studentData = {
        "id": updatedata._id,
        "fullName": updatedata.fullName,
        "email": updatedata.email,
        "mobNo": updatedata.mobNo,
        "dob": updatedata.dob,
        "gender": updatedata.gender,
        "grade": updatedata.grade,
        board: updatedata.board,
        medium: updatedata.medium,
        motherTongue: updatedata.motherTongue,
        parentEducation: updatedata.parentEducation,
        parentIncome: updatedata.parentIncome,
        schoolName: updatedata.schoolName,
      };
      let temp = {
        "keyWord": "",
        "pageNo": 1,
        "sortBy": "slug",
        "sortOrder": "asc",
        "fromDate": "",
        "toDate": "",
        "size": 10
      }
      dispatch(IntroductionAction.getPageList(studentData, temp))
      setupdatedata({})
      setopenAddmodelUpdate(false)
    }

  }

  const handleValidationUpdate = () => {
    let formIsValid = true;
    let errors = {};

    if (!updatedata.fullName || updatedata.fullName === "") {
      formIsValid = false;
      errors.fullName = "Name cannot be empty";
    }

    if (!updatedata.email) {
      formIsValid = false;
      errors.email = "Email cannot be empty";
    }
    if (!updatedata.mobNo) {
      formIsValid = false;
      errors.mobNo = "Mobile Number cannot be empty";
    }
    if (!updatedata.dob) {
      formIsValid = false;
      errors.dob = "Date of Birth cannot be empty";
    }
    if (!updatedata.gender) {
      formIsValid = false;
      errors.gender = "Gender  cannot be empty";
    }

    if (!updatedata.grade) {
      formIsValid = false;
      errors.grade = "Grade  cannot be empty";
    }

    setupdateError(errors);
    return formIsValid;
  };


  //--------Pagination-------------------------

  //Search
  let [searchKeyword, setsearchKeyword] = useState("")
  let handleSearch = (e) => {
    setsearchKeyword(e.target.value)
  }
  useEffect(() => {
    let temp = {
      "keyWord": searchKeyword,
      "pageNo": 1,
      "sortBy": "slug",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": 10
    }
    dispatch(IntroductionAction.getPageList(temp))
    let temps = {
      "keyWord": "",
      "pageNo": 1,
      "sortBy": "",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": 10
    }
    dispatch(languageAction.getLanguageList(temps))
  }, [searchKeyword])
  //search



  let { IntroductionReducer } = selector || {}


  let { loading } = IntroductionReducer && IntroductionReducer ? IntroductionReducer : {}


  let { data } = IntroductionReducer && IntroductionReducer.getPageByList ? IntroductionReducer.getPageByList : {}
  let { list, total } = data && data ? data : {}

  let shortvalue = "en"


  return (
    <>
      <Loading loading={loading} />
      <div className=' h-[90vh] w-full overflow-y-scroll  py-3 md:px-5 px-1  '>
        <div className=' w-full h-full  max-w-[100vw] '>
          <div className=' flex justify-between p-5'>
            <div className=' font-semibold text-2xl'>{IntroductionJSON.Heading}</div>
            {/* <Link to={"/app/StaticAudio/add"}>
              <Button data={"Audio Files"} />
            </Link> */}
          </div>
          <div className='md:py-10 md:px-10 '>
            <div className=''>

              <div className=' flex items-center gap-4 text-[#7E84A3] '>
                <div className='flex flex-col items-start gap-4 text-[#7E84A3]'>
                  <div className=' max-w-lg bg-white h-[40px] flex items-center border border-[#D9E1EC] rounded gap-2'>
                    <SearchIcon className='w-6 h-6 text-gray-400 ml-2' />
                    <input
                      className='flex-grow h-full outline-none text-sm'
                      onChange={(e) => handleSearch(e)}
                      value={searchKeyword}
                      type='text'
                      placeholder='Search '
                    />
                  </div>
                </div>
              </div>


              {/*Table  */}
              <div className='  '>
                <div className="relative  overflow-y-scroll  max-h-[70vh] shadow-md sm:rounded-lg mt-5 ">
                  <table className="w-full text-sm text-left rtl:text-right  text-gray-100  divide-y-4">
                    <thead className="text-[14px] text-[#5A607F] w-full    bg-white capitalize  ">
                      <tr className=' w-full'>

                        <th className="px-6 whitespace-nowrap font-bold py-3">
                          Name
                        </th>

                        <th className="px-6 py-3 font-bold">
                          Audio
                        </th>



                        <th className="px-6 py-3 font-bold">
                          Action
                        </th>


                      </tr>
                    </thead>


                    {
                      list && total > 0 && list.map((e, i) => {

                        return (
                          <tbody key={i} className=' text-gray-500'>
                            <tr className='bg-white'>
                              <td className="px-6 py-3 font-normal ">

                                {e.pageNo || "--"}


                              </td>



                              <td className="px-6 py-3 font-normal ">

                                <div className="flex gap-2 items-center space-x-6 whitespace-nowrap">

                                  {e && e.audio && e.audio[shortvalue] ? <audio className=" flex justify-start " controls>
                                    <source src={e && e.audio && e.audio[shortvalue]} type="audio/mpeg" />
                                    Your browser does not support the audio element.
                                  </audio> : <Tooltip title={e && e.msg ? e.msg : "--"} arrow>
                                    <div className=' w-40' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                      {e && e.msg ? e.msg : "--"}
                                    </div>
                                  </Tooltip>}

                                </div>


                              </td>



                              <td className="px-6 whitespace-nowrap py-3 flex flex-wrap items-center justify-center lg:justify-start lg:gap-3 font-normal">
                                <Link title='Edit' to={`/app/StaticAudio/add/${e._id}`}>
                                  <img className="cursor-pointer max-w-full lg:h-10 h-10" src='/icons/create.svg' alt="Edit" />
                                </Link>
                                {/* <img title='Delete' onClick={() => handleDelete(e)} className="cursor-pointer max-w-full lg:h-10 h-10 mt-2 lg:mt-0" src='/icons/delete.svg' alt="Delete" /> */}
                              </td>




                            </tr>
                          </tbody>
                        )



                      })
                    }

                  </table>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Model formData={formData} setFormData={setFormData} seterrorCreate={seterrorCreate} errorCreate={errorCreate} handleChange={handleChange} handleSubmit={handleSubmit} openAddmodel={openAddmodel} setopenAddmodel={setopenAddmodel} />
      <Modelupdate shortvalue={shortvalue} updatedata={updatedata} setupdatedata={setupdatedata} handleSubmitUpdate={handleSubmitUpdate} setupdateError={setupdateError} updateError={updateError} handleChangeUpdate={handleChangeUpdate} openAddmodelUpdate={openAddmodelUpdate} setopenAddmodelUpdate={setopenAddmodelUpdate} />
    </>
  );
};

export default StaticAudio;
