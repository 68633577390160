/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Button from '../../components/Button';
import ServiceJSON from './Service.json'
import Model from './Modle/Model';
import Modelupdate from './Modle/Modelupdate';
import { useDispatch, useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import { languageAction, serviceAction } from '../../_actions';
import parse from 'html-react-parser';
import Tooltip from '@mui/material/Tooltip';
import Loading from "../../components/Loader/Loader"
import { MdDeleteOutline } from "react-icons/md";
import { RiEditLine } from "react-icons/ri";
const Service = () => {
  let dispatch = useDispatch()
  let selector = useSelector(state => state)

  //create
  let [openAddmodel, setopenAddmodel] = useState(false)
  const [formData, setFormData] = useState({});
  let [errorCreate, seterrorCreate] = useState({})

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    seterrorCreate({ ...errorCreate, [e.target.name]: "" })

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleValidationCreate()) {
      let datas = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
      }
      dispatch(serviceAction.getAllService(formData, datas))
      setFormData({})
      seterrorCreate({})
      setopenAddmodel(false)
    }

  };

  const handleValidationCreate = () => {

    let formIsValid = true;
    let errors = {};

    if (!formData.fullName || formData.fullName === "") {
      formIsValid = false;
      errors.fullName = "Name cannot be empty";
    }

    if (!formData.email) {
      formIsValid = false;
      errors.email = "Email cannot be empty";
    }
    if (!formData.mobNo) {
      formIsValid = false;
      errors.mobNo = "Mobile Number cannot be empty";
    }
    if (!formData.dob) {
      formIsValid = false;
      errors.dob = "Date of Birth cannot be empty";
    }
    if (!formData.gender) {
      formIsValid = false;
      errors.gender = "Gender  cannot be empty";
    }

    if (!formData.email) {
      formIsValid = false;
      errors.email = "Email  cannot be empty";
    }

    if (!formData.grade) {
      formIsValid = false;
      errors.grade = "Grade  cannot be empty";
    }
    seterrorCreate(errors);
    return formIsValid;
  };

  // updatemodel
  let [openAddmodelUpdate, setopenAddmodelUpdate] = useState(false)
  let [updatedata, setupdatedata] = useState({})
  let [updateError, setupdateError] = useState({})

  let handleChangeUpdate = (e) => {

    setupdatedata({
      ...updatedata,
      [e.target.name]: e.target.value
    });
    setupdateError({ ...errorCreate, [e.target.name]: "" })

  }

  let handleSubmitUpdate = (e) => {
    e.preventDefault();
    if (handleValidationUpdate()) {


      const studentData = {
        "id": updatedata._id,
        "fullName": updatedata.fullName,
        "email": updatedata.email,
        "mobNo": updatedata.mobNo,
        "dob": updatedata.dob,
        "gender": updatedata.gender,
        "grade": updatedata.grade,
        board: updatedata.board,
        medium: updatedata.medium,
        motherTongue: updatedata.motherTongue,
        parentEducation: updatedata.parentEducation,
        parentIncome: updatedata.parentIncome,
        schoolName: updatedata.schoolName,
      };
      let temp = {
        "keyWord": "",
        "pageNo": 1,
        "sortBy": "slug",
        "sortOrder": "asc",
        "fromDate": "",
        "toDate": "",
        "size": 10
      }
      dispatch(serviceAction.getAllService(studentData, temp))
      setupdatedata({})
      setopenAddmodelUpdate(false)
    }

  }

  const handleValidationUpdate = () => {
    let formIsValid = true;
    let errors = {};

    if (!updatedata.fullName || updatedata.fullName === "") {
      formIsValid = false;
      errors.fullName = "Name cannot be empty";
    }

    if (!updatedata.email) {
      formIsValid = false;
      errors.email = "Email cannot be empty";
    }
    if (!updatedata.mobNo) {
      formIsValid = false;
      errors.mobNo = "Mobile Number cannot be empty";
    }
    if (!updatedata.dob) {
      formIsValid = false;
      errors.dob = "Date of Birth cannot be empty";
    }
    if (!updatedata.gender) {
      formIsValid = false;
      errors.gender = "Gender  cannot be empty";
    }

    if (!updatedata.grade) {
      formIsValid = false;
      errors.grade = "Grade  cannot be empty";
    }

    setupdateError(errors);
    return formIsValid;
  };
  // updatemodel



  //--------Pagination-------------------------


  useEffect(() => {
    let temp = {
      "keyWord": "",
      "pageNo": 1,
      "sortBy": "slug",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": 10
    }
    dispatch(serviceAction.getAllService(temp))
    let temps = {
      "keyWord": "",
      "pageNo": 1,
      "sortBy": "",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": 10
    }
    dispatch(languageAction.getLanguageList(temps))
  }, [])
  //search
  //Delete
  let handleDelete = (data) => {
    let datatemp = {
      "serviceId": data._id,
    }
    let paginationdata = {
      "keyWord": "",
      "pageNo": 1,
      "sortBy": "",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": 10
    }


    confirmAlert({
      title: ' Delete ',
      message: `Are you sure want to Delete ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(serviceAction.deleteService(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }


  let { serviceReducer } = selector || {}
  let { loading } = serviceReducer && serviceReducer ? serviceReducer : {}


  let { data } = serviceReducer && serviceReducer.getServiceLists ? serviceReducer.getServiceLists : {}


  let shortvalue = "en"



  return (
    <>
      <Loading loading={loading} />
      <div className=' h-[90vh] w-full overflow-y-scroll  py-3 md:px-5 px-1  '>
        <div className=' w-full h-full  max-w-[100vw] '>
          <div className='flex justify-between md:flex-row flex-col gap-2 p-5'>
            <div className=' font-semibold text-2xl'>{ServiceJSON.Heading}</div>
            <Link to={"/app/service/add"}>
              <Button data={"Add Services"} />
            </Link>
          </div>
          <div className='md:py-10 md:px-10 '>
            <div className=''>

              <div className=' '>
                <div className="relative overflow-y-scroll   shadow-md sm:rounded-lg mt-5 ">
                  <table className=" w-full text-sm text-left rtl:text-right  text-gray-500 divide-y-4">
                    <thead className="text-[14px] text-[#5A607F] w-full    bg-white capitalize  ">
                      <tr className=' w-full'>

                        <th className="px-6 whitespace-nowrap font-bold  py-3">
                          service
                        </th>

                        <th className="px-6 py-3 font-bold ">
                          Link
                        </th>

                        <th className="px-6 whitespace-nowrap py-3 font-bold ">
                          Image
                        </th>

                        <th className="px-6 py-3 font-bold ">
                          Action
                        </th>


                      </tr>
                    </thead>


                    {
                      data && data.length > 0 && data.map((e, i) => {

                        return (
                          <tbody key={i} className=' text-gray-500'>
                            <tr className='bg-white'>
                              <td className="px-6   w-[25%] py-3 font-normal ">
                                <Tooltip title={e.service?.[shortvalue] || "--"} arrow>
                                  <div className=' w-[80%] overflow-ellipsis truncate' style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                    {e.service?.[shortvalue] || "--"}
                                  </div>
                                </Tooltip>

                              </td>

                              <td className="px-6   w-96 h-5 whitespace-nowrap overflow-ellipsis truncate font-normal py-3">
                                <Tooltip title={parse(e.content?.[shortvalue] || "--")} arrow>
                                  <div className="overflow truncate lg:w-[14rem] w-60 h-5" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                    {parse(e.content?.[shortvalue] || "--")}
                                  </div>
                                </Tooltip>

                              </td>
                              <td className="px-6 py-3  w-[25%] font-normal ">
                                <img className=' h-10 w-10' src={e.image?.[shortvalue] || '/icons8-system-administrator-female-100.png'} onError={(e) => e.target.src = "/icons8-system-administrator-female-100.png"} alt='img' />
                              </td>
                              <td className="flex  w-[25%]  items-center justify-start px-6 py-2 gap-3">
                                < Link title='Edit' to={`/app/service/add/${e._id}`} className='cursor-pointer shadow-md border p-1 '><RiEditLine className=' text-blue-600 text-2xl' /></Link>
                                <div onClick={() => handleDelete(e)} className=' cursor-pointer shadow-md border p-1 '><MdDeleteOutline className=' text-blue-600 text-2xl' /></div>
                              </td>
                            </tr>
                          </tbody>
                        )
                      })
                    }

                  </table>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Model formData={formData} setFormData={setFormData} seterrorCreate={seterrorCreate} errorCreate={errorCreate} handleChange={handleChange} handleSubmit={handleSubmit} openAddmodel={openAddmodel} setopenAddmodel={setopenAddmodel} />
      <Modelupdate shortvalue={shortvalue} updatedata={updatedata} setupdatedata={setupdatedata} handleSubmitUpdate={handleSubmitUpdate} setupdateError={setupdateError} updateError={updateError} handleChangeUpdate={handleChangeUpdate} openAddmodelUpdate={openAddmodelUpdate} setopenAddmodelUpdate={setopenAddmodelUpdate} />
    </>
  );
};

export default Service;
