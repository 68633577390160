import { alertActions} from './alert.actions';
import { APIcallFunction, headerForPrivateAPI, } from '../_helpers';
import { studentManagementConstants } from '../_constants';

export const studentManagementaction = {
    createNewStudent,
    getAllStudent,
    updateStudent,
    deleteStudent,
 
};








function createNewStudent(data,Pagination) {
    const credentials = {
        header : headerForPrivateAPI(),
        method : "POST",
        body : data ?? {},
        endPoint : 'createNewStudent'
    }
    console.log(credentials)
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(studentManagementaction.getAllStudent(Pagination))
                    dispatch(alertActions.success("Student Created Successfully"))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: studentManagementConstants.STUDENT_CREATED_REQUEST, user } }
    function success(user) { return { type: studentManagementConstants.STUDENT_CREATED_SUCCESS, user } }
    function failure(error) { return { type: studentManagementConstants.STUDENT_CREATED_FAILURE, error } }
}

function getAllStudent(data) {
    const credentials = {
        header : headerForPrivateAPI(),
        method : "POST",
        body : data ?? {},
        endPoint : 'getStudentListByAdmin'
    }
  
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: studentManagementConstants.STUDENT_GET_REQUEST, user } }
    function success(user) { return { type: studentManagementConstants.STUDENT_GET_SUCCESS, user } }
    function failure(error) { return { type: studentManagementConstants.STUDENT_GET_FAILURE, error } }
}


function updateStudent(data,Pagination) {
    const credentials = {
        header : headerForPrivateAPI(),
        method : "POST",
        body : data ?? {},
        endPoint : 'updateStudent'
    }
    console.log(credentials)
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(studentManagementaction.getAllStudent(Pagination))
                    dispatch(alertActions.success("Student Updates Successfully"))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: studentManagementConstants.STUDENT_UPDATED_REQUEST, user } }
    function success(user) { return { type: studentManagementConstants.STUDENT_UPDATED_SUCCESS, user } }
    function failure(error) { return { type: studentManagementConstants.STUDENT_UPDATED_FAILURE, error } }
}





function deleteStudent(data,Pagination) {
    const credentials = {
        header : headerForPrivateAPI(),
        method : "POST",
        body : data ?? {},
        endPoint : 'deleteStudent'
    }

    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(studentManagementaction.getAllStudent(Pagination))
                    dispatch(alertActions.success("Deleted Successfully"))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: studentManagementConstants.STUDENT_UPDATED_REQUEST, user } }
    function success(user) { return { type: studentManagementConstants.STUDENT_UPDATED_SUCCESS, user } }
    function failure(error) { return { type: studentManagementConstants.STUDENT_UPDATED_FAILURE, error } }
}