/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Button from '../../components/Button';
import UserManagementJSON from './StudentManagement.json'
import SearchIcon from '@mui/icons-material/Search';
import Model from './Modle/Model';
import Modelupdate from './Modle/Modelupdate';
import { languageAction, studentManagementaction, userManagementAction } from '../../_actions';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { confirmAlert } from 'react-confirm-alert';
import Loading from "../../components/Loader/Loader"
import { MdDeleteOutline } from "react-icons/md";
import { RiEditLine } from "react-icons/ri";


const StudentManagements = () => {
  let dispatch = useDispatch()
  let selector = useSelector(state => state)
  const [currentPage, setCurrentPage] = useState(0);

  //create
  let [openAddmodel, setOpenAddModel] = useState(false)
  const [formData, setFormData] = useState({});
  let [errorCreate, setErrorCreate] = useState({})
  const handleChange = (e) => {
    let {name,value} =e.target
    if (name === 'mobNo' && value.length > 10) { 
      return;
    }else  if (name === 'grade' && value.length > 2) { 
      return;
    }else{
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
      setErrorCreate({ ...errorCreate, [e.target.name]: "" })
    }
   
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleValidationCreate()) {
      let datas = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
      }
      dispatch(studentManagementaction.createNewStudent(formData, datas))
      setFormData({})
      setErrorCreate({})
      setOpenAddModel(false)
    }

  };

  const handleValidationCreate = () => {
    let formIsValid = true;
    let errors = {};


    if (!formData.fullName || formData.fullName === "") {
      formIsValid = false;
      errors.fullName = "Name cannot be empty";
    }

    if (!formData.email) {
      formIsValid = false;
      errors.email = "Email cannot be empty";
    }
    if (!formData.mobNo) {
      formIsValid = false;
      errors.mobNo = "Mobile Number cannot be empty";
    }
    if (!formData.dob) {
      formIsValid = false;
      errors.dob = "Date of Birth cannot be empty";
    }
    if (!formData.gender) {
      formIsValid = false;
      errors.gender = "Gender  cannot be empty";
    }

    if (!formData.email) {
      formIsValid = false;
      errors.email = "Email  cannot be empty";
    }

    if (!formData.grade) {
      formIsValid = false;
      errors.grade = "Grade  cannot be empty";
    }
    if (!formData.userId) {
      formIsValid = false;
      errors.userId = "User  cannot be empty";
    }
    setErrorCreate(errors);
    return formIsValid;
  };

  // updatemodel
  let [openAddmodelUpdate, setOpenAddModelUpdate] = useState(false)
  let [updatedata, setupdatedata] = useState({})
  let [updateError, setupdateError] = useState({})
  let handleUpdatemodel = (data) => {
    setOpenAddModelUpdate(true)
    setupdatedata({ ...data })
  }
  let handleChangeUpdate = (e) => {
    let {name,value} =e.target
    if (name === 'mobNo' && value.length > 10) { 
      return;
    }else  if (name === 'grade' && value.length > 2) { 
      return;
    }
    else{
      setupdatedata({
        ...updatedata,
        [e.target.name]: e.target.value
      });
      setupdateError({ ...errorCreate, [e.target.name]: "" })
    }
   
  }

  let handleSubmitUpdate = (e) => {
    e.preventDefault();
    if (handleValidationUpdate()) {


      const studentData = {
        "id": updatedata._id,
        "fullName": updatedata.fullName,
        "email": updatedata.email,
        "mobNo": updatedata.mobNo,
        "dob": updatedata.dob,
        "gender": updatedata.gender,
        "grade": updatedata.grade,
        board: updatedata.board,
        medium: updatedata.medium,
        motherTongue: updatedata.motherTongue,
        parentEducation: updatedata.parentEducation,
        parentIncome: updatedata.parentIncome,
        schoolName: updatedata.schoolName,
        userId: updatedata.createdBy
      };
      let temp = {
        "keyWord": "",
        "pageNo": currentPage + 1,
        "sortBy": "slug",
        "sortOrder": "desc",
        "fromDate": "",
        "toDate": "",
        "size": 10
      }
      dispatch(studentManagementaction.updateStudent(studentData, temp))
      setupdatedata({})
      setOpenAddModelUpdate(false)
    }

  }

  const handleValidationUpdate = () => {
    let formIsValid = true;
    let errors = {};

    if (!updatedata.fullName || updatedata.fullName === "") {
      formIsValid = false;
      errors.fullName = "Name cannot be empty";
    }

    if (!updatedata.email) {
      formIsValid = false;
      errors.email = "Email cannot be empty";
    }
    if (!updatedata.mobNo) {
      formIsValid = false;
      errors.mobNo = "Mobile Number cannot be empty";
    }
    if (!updatedata.dob) {
      formIsValid = false;
      errors.dob = "Date of Birth cannot be empty";
    }
    if (!updatedata.gender) {
      formIsValid = false;
      errors.gender = "Gender  cannot be empty";
    }

    if (!updatedata.grade) {
      formIsValid = false;
      errors.grade = "Grade  cannot be empty";
    }

    if (!updatedata.createdBy) {
      formIsValid = false;
      errors.createdBy = "User  cannot be empty";
    }

    console.log(errors, updatedata)
    setupdateError(errors);
    return formIsValid;
  };
  // updatemodel

  //--------Pagination-------------------------
  const [size, setSize] = useState(10);
  const [isMobile, setIsMobile] = useState(true);
  //--------Pagination-------------------------
  let [offset, setoffset] = useState(0)
  const handlePageClick = (data) => {
    setCurrentPage(data.selected)
    setoffset(Math.ceil(data.selected * 10))
    let datas = {
      "keyWord": "",
      "pageNo": data.selected + 1,
      "size": 10
    }
    dispatch(studentManagementaction.getAllStudent(datas))
  };
  //--------Pagination-------------------------

  //Search
  let [searchKeyword, setSearchKeyword] = useState("")
  let handleSearch = (e) => {
    setSearchKeyword(e.target.value)
  }
  useEffect(() => {
    let temp = {
      "keyWord": searchKeyword,
      "pageNo": 1,
      "sortBy": "slug",
      "sortOrder": "desc",
      "fromDate": "",
      "toDate": "",
      "size": 10
    }
    dispatch(studentManagementaction.getAllStudent(temp))

    dispatch(userManagementAction.getAllUser())


    let paginationData = {
      "keyWord": "",
      "pageNo": 1,
      "sortBy": "slug",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": size
    }
    dispatch(languageAction.getBoardList(paginationData))
  }, [searchKeyword])


  //search
  //Delete
  let handleDelete = (data) => {
    let datatemp = {
      "studentId": data._id,
    }
    let paginationdata = {
      "keyWord": "",
      "pageNo": currentPage + 1,
      "sortBy": "slug",
      "sortOrder": "desc",
      "fromDate": "",
      "toDate": "",
      "size": 10
    }
    confirmAlert({
      title: ' Delete ',
      message: `Are you sure want to Delete ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(studentManagementaction.deleteStudent(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }


  let { studentManagement, userManagement, language } = selector || {}
  let { list, total } = studentManagement && studentManagement.studentList ? studentManagement.studentList : {}
  let { getUserManagementList } = userManagement && userManagement ? userManagement : {}
  let { loading } = studentManagement
  let { BoardData } = language && language ? language : {}




  return (
    <>

      <Loading loading={loading} />
      <div className=' h-[90vh] w-full overflow-y-scroll  py-3 md:px-5 px-1  '>
        <div className=' w-full h-full  max-w-[100vw] '>
          <div className=' flex justify-between w-[80vw] flex-wrap gap-2 p-5'>
            <div className=' font-semibold text-2xl'>{UserManagementJSON.Heading}</div>
            <div onClick={() => setOpenAddModel(true)}>
              <Button data={"Add Student"} />
            </div>
          </div>
          <div className='md:py-10 md:px-10 '>
            <div className=''>
              {/* search and filter area */}
              <div className='flex flex-col items-start gap-4 text-[#7E84A3]'>
                <div className=' max-w-lg bg-white h-[40px] flex items-center border border-[#D9E1EC] rounded gap-2'>
                  <SearchIcon className='w-6 h-6 text-gray-400 ml-2' />
                  <input
                    className='flex-grow h-full outline-none text-sm'
                    onChange={(e) => handleSearch(e)}
                    value={searchKeyword}
                    type='text'
                    placeholder='Search by Name and Email...'
                  />
                </div>
              </div>

              {/*Table  */}
              <div className='  '>
                <div className="relative  overflow-y-scroll   shadow-md sm:rounded-lg mt-5 ">
                  <table className="w-full text-sm text-left rtl:text-right  text-gray-500  divide-y-4">
                    <thead className="text-[14px] text-[#5A607F] w-full    bg-white capitalize  ">
                      <tr className=' w-full'>

                        <th className="px-6 whitespace-nowrap font-bold  py-3">
                          Student
                        </th>

                        <th className="px-6 py-3 font-bold ">
                          DOB
                        </th>

                        <th className="px-6 whitespace-nowrap py-3 font-bold ">
                          Mobile No
                        </th>

                        <th className="px-6 py-3 font-bold ">
                          E-mail
                        </th>

                        <th className="px-6 py-3 font-bold ">
                          Grade
                        </th>
                        <th className="px-6 py-3 font-bold ">
                          {" "}
                        </th>

                      </tr>
                    </thead>


                    {
                      list && total > 0 && list.map((e, i) => {

                        return (
                          <tbody key={i} className=''>
                            <tr className='bg-white'>

                              <td className="px-6 whitespace-nowrap font-normal   py-3">
                                <div className="flex gap-2 items-center space-x-6 whitespace-nowrap">
                                  <input
                                    id="checkbox-table-search-1"
                                    type="checkbox"
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500  focus:ring-2  "
                                  />
                                  <label htmlFor="checkbox-table-search-1" className="sr-only">
                                    checkbox
                                  </label>
                                  {e?.fullName || "--"}
                                </div>
                              </td>

                              <td className="px-6 py-3 font-normal ">
                                {e && e.dob ? new Date(e.dob).toLocaleDateString() : "--"}
                              </td>

                              <td className="px-6 whitespace-nowrap py-3 font-normal ">
                                {e?.mobNo || "--"}
                              </td>

                              <td className="px-6 py-3 font-normal ">
                                {e?.email || "--"}
                              </td>

                              <td className="px-6 py-3 font-normal ">
                                {e?.grade ? `${e?.grade}` : "--"}
                              </td>


                              <td className="flex items-center justify-end px-6 py-2 gap-3">
                                <div onClick={() => handleUpdatemodel(e)} className='cursor-pointer shadow-md border p-1 '><RiEditLine className=' text-blue-600 text-2xl' /></div>
                                <div onClick={() => handleDelete(e)} className=' cursor-pointer shadow-md border p-1 '><MdDeleteOutline className=' text-blue-600 text-2xl' /></div>
                              </td>
                            </tr>
                          </tbody>
                        )
                      })
                    }
                  </table>

                </div>
              </div>
              {isMobile ? (
                list && total > 10 ? (
                  <ReactPaginate
                    previousLabel={'Prev'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={Math.ceil(total / size)}
                    marginPagesDisplayed={0}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    pageClassName={'page-cls'}
                    activeClassName={'active'}
                    forcePage={currentPage}
                  />
                ) : null
              ) : (
                list && total > 10 ? (
                  <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={Math.ceil(total / size)}
                    marginPagesDisplayed={3}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    pageClassName={'page-cls'}
                    activeClassName={'active'}
                  />
                ) : null
              )}
            </div>
          </div>
        </div>
      </div>

      <Model BoardData={BoardData} getUserManagementList={getUserManagementList} formData={formData} setFormData={setFormData} setErrorCreate={setErrorCreate} errorCreate={errorCreate} handleChange={handleChange} handleSubmit={handleSubmit} openAddmodel={openAddmodel} setopenAddmodel={setOpenAddModel} />
      <Modelupdate BoardData={BoardData} getUserManagementList={getUserManagementList} updatedata={updatedata} setupdatedata={setupdatedata} handleSubmitUpdate={handleSubmitUpdate} setupdateError={setupdateError} updateError={updateError} handleChangeUpdate={handleChangeUpdate} openAddmodelUpdate={openAddmodelUpdate} setopenAddmodelUpdate={setOpenAddModelUpdate} />
    </>
  );
};

export default StudentManagements;
