export const introductionConstants = {


    CREATE_NEW_PAGE_REQUEST:"CREATE_NEW_PAGE_REQUEST",
    CREATE_NEW_PAGE_SUCCESS:"CREATE_NEW_PAGE_SUCCESS",
    CREATE_NEW_PAGE_FAILURE:"CREATE_NEW_PAGE_FAILURE",

    UPDATE_PAGE_REQUEST:"UPDATE_PAGE_REQUEST",
    UPDATE_PAGE_SUCCESS:"UPDATE_PAGE_SUCCESS",
    UPDATE_PAGE_FAILURE:"UPDATE_PAGE_FAILURE",



GET_DELETE_PAGE_REQUEST:"GET_DELETE_PAGE_REQUEST",
GET_DELETE_PAGE_SUCCESS:"GET_DELETE_PAGE_SUCCESS",
GET_DELETE_PAGE_FAILURE:"GET_DELETE_PAGE_FAILURE",



GET_PAGE_LIST_REQUEST:"GET_PAGE_LIST_REQUEST",
GET_PAGE_LIST_SUCCESS:"GET_PAGE_LIST_SUCCESS",
GET_PAGE_LIST_FAILURE:"GET_PAGE_LIST_FAILURE",


GET_INTRO_BY_ID_REQUEST:"GET_INTRO_BY_ID_REQUEST",
GET_INTRO_BY_ID_SUCCESS:"GET_INTRO_BY_ID_SUCCESS",
GET_INTRO_BY_ID_FAILURE:"GET_INTRO_BY_ID_FAILURE",
    
    CREATE_INTOR_REQUEST:"CREATE_INTOR_REQUEST",
    CREATE_INTOR_SUCCESS:"CREATE_INTOR_SUCCESS",
    CREATE_INTOR_FAILURE:"CREATE_INTOR_FAILURE",

    UPDATE_INTOR_REQUEST:"UPDATE_INTOR_REQUEST",
    UPDATE_INTOR_SUCCESS:"UPDATE_INTOR_SUCCESS",
    UPDATE_INTOR_FAILURE:"UPDATE_INTOR_FAILURE",
    
GET_INTRO_LIST_REQUEST:"GET_INTRO_LIST_REQUEST",
GET_INTRO_LIST_SUCCESS:"GET_INTRO_LIST_SUCCESS",
GET_INTRO_LIST_FAILURE:"GET_INTRO_LIST_FAILURE",


GET_PAGE_BY_ID_REQUEST:"GET_PAGE_BY_ID_REQUEST",
GET_PAGE_BY_ID_SUCCESS:"GET_PAGE_BY_ID_SUCCESS",
GET_PAGE_BY_ID_FAILURE:"GET_PAGE_BY_ID_FAILURE",



DELETE_INTRO_REQUEST:"DELETE_INTRO_REQUEST",
DELETE_INTRO_SUCCESS:"DELETE_INTRO_SUCCESS",
DELETE_INTRO_FAILURE:"DELETE_INTRO_FAILURE",
    
};
