import { introductionConstants, serviceConstants } from "../_constants";


export default function IntroductionReducer(state = {}, action) {
  switch (action.type) {


    


   
case  introductionConstants.GET_PAGE_LIST_REQUEST:
  return {
    ...state,
    loading: true
  };
case  introductionConstants.GET_PAGE_LIST_SUCCESS:
  return {
    ...state,
    loading: false,
    getPageByList: action.user
  };
case  introductionConstants.GET_PAGE_LIST_FAILURE:
  return {
    ...state,
    loading: false,
    error: action.error
  }; 
    

    case  introductionConstants.GET_INTRO_BY_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case  introductionConstants.GET_INTRO_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        getIntroById: action.user
      };
    case  introductionConstants.GET_INTRO_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


      
      




case  introductionConstants.UPDATE_PAGE_REQUEST:
  return {
    ...state,
    loading: true
  };
case  introductionConstants.UPDATE_PAGE_SUCCESS:
  return {
    ...state,
    loading: false,
  };
case  introductionConstants.UPDATE_PAGE_FAILURE:
  return {
    ...state,
    loading: false,
    error: action.error
  };








      
      

      case  introductionConstants.GET_DELETE_PAGE_REQUEST:
        return {
          ...state,
          loading: true
        };
      case  introductionConstants.GET_DELETE_PAGE_SUCCESS:
        return {
          ...state,
          loading: false,
        };
      case  introductionConstants.GET_DELETE_PAGE_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error
        };


case  introductionConstants.GET_PAGE_BY_ID_REQUEST:
  return {
    ...state,
    loading: true
  };
case  introductionConstants.GET_PAGE_BY_ID_SUCCESS:
  return {
    ...state,
    loading: false,
    getPageById: action.user
  };
case  introductionConstants.GET_PAGE_BY_ID_FAILURE:
  return {
    ...state,
    loading: false,
    error: action.error
  };

      
      
      


      case  introductionConstants.CREATE_NEW_PAGE_REQUEST:
        return {
          ...state,
          loading: true
        };
      case  introductionConstants.CREATE_NEW_PAGE_SUCCESS:
        return {
          ...state,
          loading: false,

        };
      case  introductionConstants.CREATE_NEW_PAGE_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error
        };
  




    
    case  introductionConstants.CREATE_INTOR_REQUEST:
      return {
        ...state,
        loading: true
      };
    case  introductionConstants.CREATE_INTOR_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case  introductionConstants.CREATE_INTOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

      
      


      

      case  introductionConstants.GET_INTRO_LIST_REQUEST:
        return {
          ...state,
          loading: true
        };
      case  introductionConstants.GET_INTRO_LIST_SUCCESS:
        return {
          ...state,
          getIntroLists:action.user,
          loading: false,
        };
      case  introductionConstants.GET_INTRO_LIST_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error
        };




        
        
        
      case  introductionConstants.UPDATE_INTOR_REQUEST:
        return {
          ...state,
          loading: true
        };
      case  introductionConstants.UPDATE_INTOR_SUCCESS:
        return {
          ...state,
          loading: false,
        };
      case  introductionConstants.UPDATE_INTOR_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error
        };





        



case  serviceConstants.DELETE_SERVICE_REQUEST:
  return {
    ...state,
    loading: true
  };
case  serviceConstants.DELETE_SERVICE_SUCCESS:
  return {
    ...state,
    loading: false,
  };
case  serviceConstants.DELETE_SERVICE_FAILURE:
  return {
    ...state,
    loading: false,
    error: action.error
  };





    default:
      return state
  }
}

