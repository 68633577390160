export const questionConstants = {

    
    QUESTION_GET_REQUEST:"QUESTION_GET_REQUEST",
    QUESTION_GET_SUCCESS:"QUESTION_GET_SUCCESS",
    QUESTION_GET_FAILURE:"QUESTION_GET_FAILURE",
    
    GET_ALL_OPTIONS_REQUEST:"GET_ALL_OPTIONS_REQUEST",
    GET_ALL_OPTIONS_SUCCESS:"GET_ALL_OPTIONS_SUCCESS",
    GET_ALL_OPTIONS_FAILURE:".GET_ALL_OPTIONS_FAILURE",


QUESTION_CREATE_REQUEST:"QUESTION_CREATE_REQUEST",
QUESTION_CREATE_SUCCESS:"QUESTION_CREATE_SUCCESS",
QUESTION_CREATE_FAILURE:"QUESTION_CREATE_FAILURE",

FILE_AUDIO_UPLOADED_REQUEST:"FILE_AUDIO_UPLOADED_REQUEST",
FILE_AUDIO_UPLOADED_SUCCESS:"FILE_AUDIO_UPLOADED_SUCCESS",
FILE_AUDIO_UPLOADED_FAILURE:"FILE_AUDIO_UPLOADED_FAILURE",
FILE_AUDIO_UPLOADED_CLEAR:"FILE_AUDIO_UPLOADED_CLEAR",


GET_SINGLE_QUESTION_REQUEST:"GET_SINGLE_QUESTION_REQUEST",
GET_SINGLE_QUESTION_SUCCESS:"GET_SINGLE_QUESTION_SUCCESS",
GET_SINGLE_QUESTION_FAILURE:"GET_SINGLE_QUESTION_FAILURE",


GET_QUESTION_BY_SETID_REQUEST:"GET_QUESTION_BY_SETID_REQUEST",
GET_QUESTION_BY_SETID_SUCCESS:"GET_QUESTION_BY_SETID_SUCCESS",
GET_QUESTION_BY_SETID_FAILURE:"GET_QUESTION_BY_SETID_FAILURE",

};
