
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useEffect, useRef, useState } from 'react';
import { TiPlus } from "react-icons/ti";
import { FaMinus } from "react-icons/fa";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FaPlusCircle } from "react-icons/fa";
import axios from 'axios'
import { authHeader } from '../../../_helpers';
import CircularProgress from '@mui/material/CircularProgress';

const ServicesComponent = ({ textdata, setTextdata, elem, handleChange, getSingleServiceData, handleTextEditor, valuetoSubmit, setvaluetoSubmit, dataToSubmitImage
  , setDataToSubmitImage }) => {
  const imgRef = useRef(null)
  const quillRef = useRef(null);
  let [loadings, setloadings] = useState(false)

  let [imgLod, setimgLod] = useState(false)

  let handleChangeImage = async (e) => {
    let { name } = e.target;
    const formData = new FormData();
    formData.append('image', e.target.files[0]);
    try {
      setloadings(true)
      const response = await axios.post(`https://orkid.jamsara.com/api/upload`, formData, {
        headers: {
          'Authorization': authHeader().Authorization,
          'Content-Type': 'multipart/form-data'
        }
      });
      const responseData = response.data;
      setloadings(false)

      setDataToSubmitImage({ ...dataToSubmitImage, [name]: responseData?.imageURL });
      return responseData;
    } catch (error) {
      setloadings(false)
      console.error('Error:', error);
      throw error;
    }
  };


  const handleImage = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('image', e.target.files[0]);

    try {
      setimgLod(true);

      const response = await axios.post(`https://orkid.jamsara.com/api/upload`, formData, {
        headers: {
          'Authorization': authHeader().Authorization,
          'Content-Type': 'multipart/form-data'
        }
      });

      const responseData = response.data;


      if (responseData && responseData?.imageURL) {
        setimgLod(false);
        const imageUrl = responseData.imageURL;
        const quill = quillRef.current.editor;
        // Insert the image into the editor
        quill.focus();

        const range = quillRef.current.getEditor().getSelection(true);
        quillRef.current.getEditor().insertEmbed(
          range.index || 0,
          'image',
          imageUrl,
        );

      }

      return responseData?.imageURL;
    } catch (error) {
      setimgLod(false);
      console.error('Error:', error);
      throw error;
    }
  };


  const handleImageUpload = async (value) => {
    if (value) {
      await imgRef.current.click();

    }

  };





  let [toogle, settoogle] = useState(false)


  let handleToogle = () => {
    settoogle(prev => !prev)
  }





  useEffect(() => {
    setvaluetoSubmit(prevDataToSubmit => ({
      ...prevDataToSubmit,
      [elem.shortName]: getSingleServiceData?.service?.[elem.shortName] || ""
    }));

    setTextdata(prevDataToSubmit => ({
      ...prevDataToSubmit,
      [elem.shortName]: getSingleServiceData?.content?.[elem.shortName] || ""
    }));

    setDataToSubmitImage(prevDataToSubmit => ({
      ...prevDataToSubmit,
      [elem.shortName]: getSingleServiceData?.image?.[elem.shortName] || ""
    }));
  }, [getSingleServiceData]);

  const quillOptions = {
    modules: {
      toolbar: [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'align': [] }],
        ['link'],
        ['clean']
      ]
    },
    formats: [
      'header',
      'bold', 'italic', 'underline', 'strike',
      'list', 'indent', 'align',
      'link', "image"
    ]
  };
  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'align': [] }],
        ['link'],
        [{ 'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#fabcbc', '#ffebcc', '#fffaac', '#d4fa8f', '#cbf0f8', '#ffcccc', '#f2f2f2', '#cccccc', '#fabbbb', '#f7c6a0', '#fef6b6', '#d3f08a', '#b7ebf6', '#f0baba', '#e6e6e6', '#4D207A'] }],
        ['clean', "image"]
      ],
      handlers: {
        image: handleImageUpload
      }
    }
  }), []);
  return (
    <>



      <div className='flex flex-col gap-2 '>
        <div className=' w-full p-2 flex justify-between items-center bg-[#C7C7C7] rounded-sm '>
          <div>{elem?.name || ""}</div>
          <div onClick={handleToogle}>
            <TiPlus />
          </div>
        </div>
        {toogle && <>



          {/* 
//================================================= */}

          <div className=' w-full p-2 relative flex flex-col   gap-3  bg-[#EAEAEA] rounded-sm'>
            <div onClick={handleToogle} className='w-40 right-2 absolute justify-end  flex '> <FaMinus /> </div>

            <div className=' flex mt-5  justify-center md:flex-row flex-col gap-6 w-full items-center'>
              <div className=' md:w-40 w-full md:justify-end   flex pt-4 text-lg'> Service </div>
              {/* Service */}
              <div className=' flex w-full flex-col gap-3'>
                <input value={valuetoSubmit[elem.shortName]} modules={modules} name={elem?.shortName} onChange={handleChange} type='text' className=' rounded-md w-full p-3   outline-none' />
              </div>

            </div>







            <div className=' flex mt-5  justify-center md:flex-row flex-col gap-6 w-full items-center'>
              <div className='  md:w-40 w-full md:justify-end   flex pt-4 text-lg'> Link: </div>
              {/* Service */}
              <div className=' flex w-full flex-col gap-3'>

                <ReactQuill ref={quillRef} value={textdata[elem?.shortName]} name={elem?.shortName} formats={quillOptions.formats} modules={modules} onChange={(content) => handleTextEditor(content, elem?.shortName)} type='text' className='h-full rounded-md w-full p-3 outline-none' />
                {imgLod && <div className=' absolute top-1/2 right-1/2'>
                  <svg
                    aria-hidden="true"
                    className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>}


                <input ref={imgRef} className=' hidden' onChange={(e) => handleImage(e)} type='file' />
              </div>

            </div>


            <div className=' flex gap-6 items-center'>
              <div className=' w-40 md:justify-end justify-start items-start flex pt-4 text-lg'> Image: </div>
              {/* Service */}
              <div className=' flex w-full flex-col gap-3'>
                {loadings === false ? <div className=' w-full flex justify-between gap-3 h-20 relative bg-[#EAEAEA]'>
                  <img
                    className='h-16 w-16'

                    onError={(e) => e.target.src = "/icons8-system-administrator-female-100.png"}
                    src={dataToSubmitImage[elem?.shortName] || '/icons8-system-administrator-female-100.png'}
                    alt='img'
                  />

                  <label className=' absolute' htmlFor={elem?.shortName}> <FaPlusCircle className=' absolute  text-2xl cursor-pointer' /></label>
                  <input accept='image/*' type='file' onChange={handleChangeImage} className=' hidden' id={elem?.shortName} name={elem?.shortName}></input>
                  <div className=' w-full  p-2 flex'>
                    <textarea className=' w-full resize-none outline-none ' disabled type='text' />
                  </div>

                </div> : <div className=' flex justify-start items-center'><CircularProgress />Uploading....</div>}
              </div>

            </div>
          </div>
        </>
        }
      </div>
    </>
  );
};

export default ServicesComponent;





