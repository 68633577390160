/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { TiPlus } from "react-icons/ti";
import { FaMinus } from "react-icons/fa";
import CircularProgress from '@mui/material/CircularProgress';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FaPlusCircle } from "react-icons/fa";
import axios from 'axios'
import { authHeader } from '../../../_helpers';


const ServicesComponent = ({ textdata, setTextdata, elem, handleChange, data, handleTextEditor, value, setDataToSubmitAudio, dataToSubmitAudio, valuetoSubmit, setvaluetoSubmit, dataToSubmitImage
  , setDataToSubmitImage, handleImage }) => {
  const fileInputRefAudio = useRef(null);
  let [isloading, setisLoading] = useState(false)
  let handleChangeImage = async (e) => {
    let { name } = e.target;
    const formData = new FormData();
    formData.append('image', e.target.files[0]);
    try {
      setisLoading(true)
      const response = await axios.post(`https://orkid.jamsara.com/api/upload`, formData, {
        headers: {
          'Authorization': authHeader().Authorization,
          'Content-Type': 'multipart/form-data'
        }
      });
      const responseData = response.data;

      console.log(responseData?.imageURL);
      setDataToSubmitImage({ ...dataToSubmitImage, [name]: responseData?.imageURL });
      setisLoading(false)
      return responseData;
    } catch (error) {
      setisLoading(false)
      console.error('Error:', error);
      throw error;
    }
  };




  const quillOptions = {
    modules: {
      toolbar: [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'align': [] }],
        ['link'],
        [{ 'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#fabcbc', '#ffebcc', '#fffaac', '#d4fa8f', '#cbf0f8', '#ffcccc', '#f2f2f2', '#cccccc', '#fabbbb', '#f7c6a0', '#fef6b6', '#d3f08a', '#b7ebf6', '#f0baba', '#e6e6e6', '#4D207A'] }],
        ['clean'],
        [{ 'font': [] }],
      ]
    },
    formats: [
      'header',
      'bold', 'italic', 'underline', 'strike',
      'list', 'indent', 'align',
      'link',
      'color',
      'font'
    ]
  };


  let [toogle, settoogle] = useState(false)


  let handleToogle = () => {
    settoogle(prev => !prev)
  }




  useEffect(() => {
    setvaluetoSubmit(prevDataToSubmit => ({
      ...prevDataToSubmit,
      [elem.shortName]: data?.name?.[elem.shortName] || ""
    }));

    setTextdata(prevDataToSubmit => ({
      ...prevDataToSubmit,
      [elem.shortName]: data?.content?.[elem.shortName] || ""
    }));

    setDataToSubmitImage(prevDataToSubmit => ({
      ...prevDataToSubmit,
      [elem.shortName]: data?.image?.[elem.shortName] || ""
    }));

    setDataToSubmitAudio(prevDataToSubmit => ({
      ...prevDataToSubmit,
      [elem.shortName]: data?.audio?.[elem.shortName] || ""
    }));

  }, [data]);

  const handleLabelClickAudio = () => {
    fileInputRefAudio.current.click();
  };
  let [loaderAudio, setLoaderAudio] = useState(false)
  let handleChangeAudio = async (e) => {
    let { name } = e.target;
    const formData = new FormData();
    formData.append('image', e.target.files[0]);
    try {
      setLoaderAudio(true)
      const response = await axios.post(`https://orkid.jamsara.com/api/upload`, formData, {
        headers: {
          'Authorization': authHeader().Authorization,
          'Content-Type': 'multipart/form-data'
        }
      });
      const responseData = response.data;

      console.log(responseData?.imageURL);
      setDataToSubmitAudio({ ...dataToSubmitAudio, [name]: responseData?.imageURL });
      setLoaderAudio(false)
      return responseData;
    } catch (error) {
      console.error('Error:', error);
      setLoaderAudio(false)
      throw error;
    }
  };



  return (
    <>



      <div className='flex flex-col gap-2 '>
        <div className=' w-full p-2 flex justify-between items-center bg-[#C7C7C7] rounded-sm '>
          <div>{elem?.name || ""}</div>
          <div onClick={handleToogle}>
            <TiPlus />
          </div>
        </div>
        {toogle && <>



          {/* 
//================================================= */}

          <div className=' w-full p-2 relative flex flex-col   gap-3  bg-[#EAEAEA] rounded-sm'>
            <div onClick={handleToogle} className='w-40 right-2 absolute justify-end  flex '> <FaMinus /> </div>

            <div className=' flex mt-5  gap-6 md:flex-row flex-col  w-full items-center'>
              <div className=' w-40 md:justify-end justify-start flex pt-4 text-lg'> Name </div>

              <div className=' flex w-full flex-col gap-3'>
                <input value={valuetoSubmit[elem.shortName]} name={elem?.shortName} onChange={handleChange} type='text' className=' rounded-md w-full p-3   outline-none' />
              </div>

            </div>





            <div className='flex flex-col md:flex-row md:items-start gap-6'>
              <div className='w-full md:w-40 flex md:justify-end justify-start items-start pt-4 text-lg'> Content: </div>
              <div className='flex flex-grow bg-white overflow-auto h-48 mt-5 md:max-w-[70vw] flex-col gap-3'>
                <ReactQuill
                  value={textdata[elem?.shortName]}
                  name={elem?.shortName}
                  modules={quillOptions.modules}
                  formats={quillOptions.formats}
                  onChange={(content) => handleTextEditor(content, elem?.shortName)}
                  type='text'
                  className='h-full rounded-md w-full p-3 outline-none'
                />
              </div>
            </div>



            <div className=' flex gap-6 items-center'>
              <div className=' w-40 justify-end items-start flex pt-4 text-lg'> Image: </div>
              {/* Service */}
              <div className=' flex w-full flex-col gap-3'>
                {!isloading ? <div className=' w-full flex justify-between gap-3 h-20 relative bg-[#EAEAEA]'>
                  <img
                    className='h-16 w-16'

                    onError={(e) => e.target.src = "/icons8-system-administrator-female-100.png"}
                    src={dataToSubmitImage[elem?.shortName] || '/icons8-system-administrator-female-100.png'}
                    alt='img'
                  />

                  <label className=' absolute' htmlFor={elem?.shortName}> <FaPlusCircle className=' absolute  text-2xl cursor-pointer' /></label>
                  <input accept='image/*' type='file' onChange={handleChangeImage} className=' hidden' id={elem?.shortName} name={elem?.shortName}></input>
                  <div className=' w-full  p-2 flex'>
                    <textarea className=' w-full resize-none outline-none ' disabled type='text' />
                  </div>

                </div> : <div className=' flex justify-start items-center'><CircularProgress />Uploading....</div>}
              </div>

            </div>

            <div className='flex gap-6 items-center'>
              <div className=' w-40 justify-end items-start flex pt-4 text-lg'> Audio: </div>
              {!loaderAudio ? <div className='  w-full flex justify-between gap-3 h-20 relative bg-[#EAEAEA]'>
                <label onClick={handleLabelClickAudio} className='w-[9%] absolute top-1' ><FaPlusCircle /></label>

                {dataToSubmitAudio[elem?.shortName] ? <audio className='ml-3' controls>
                  <source src={dataToSubmitAudio[elem?.shortName]} type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio> : ""}



                <input
                  ref={fileInputRefAudio}
                  id={elem.shortName}
                  name={elem.shortName}
                  value={dataToSubmitAudio?.file || ""}
                  onChange={handleChangeAudio}
                  className='w-full px-2 hidden py-1 rounded-md'
                  accept='audio/*'
                  type='file' />

              </div> : <div className=' flex justify-center items-center'><CircularProgress />Uploading....</div>}
            </div>
          </div>
        </>
        }
      </div>



    </>
  );
};

export default ServicesComponent;





