/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { TiPlus } from "react-icons/ti";
import { FaMinus } from "react-icons/fa";
import 'react-quill/dist/quill.snow.css';

const ServicesComponent = ({ textdata, data, setTextdata, elem, handleChange, getSingleServiceData, handleTextEditor, valuetoSubmit, setvaluetoSubmit, dataToSubmitImage
  , setDataToSubmitImage }) => {








  let [toogle, settoogle] = useState(false)


  let handleToogle = () => {
    settoogle(prev => !prev)
  }





  useEffect(() => {
    // setvaluetoSubmit(data?.name||"");

    setvaluetoSubmit(prevDataToSubmit => ({
      ...prevDataToSubmit,
      name: data?.name || ""
    }));


    setTextdata(prevDataToSubmit => ({
      ...prevDataToSubmit,
      [elem.shortName]: data?.value?.[elem.shortName] || ""
    }));

  }, [data]);


  return (
    <>
      <div className='flex flex-col gap-2 '>
        <div className=' w-full p-2 flex justify-between items-center bg-[#C7C7C7] rounded-sm '>
          <div>{elem?.name || ""}</div>
          <div onClick={handleToogle}>
            <TiPlus />
          </div>
        </div>
        {toogle && <>



          {/* 
//================================================= */}

          <div className=' w-full p-2 relative flex flex-col   gap-3  bg-[#EAEAEA] rounded-sm'>
            <div onClick={handleToogle} className='w-40 right-2 absolute justify-end  flex '> <FaMinus /> </div>

            <div className=' flex mt-5  justify-center md:flex-row flex-col gap-6 w-full items-center'>
              <div className=' md:w-40 w-full md:justify-end   flex pt-4 text-lg'> Name </div>
              {/* Service */}
              <div className=' flex w-full flex-col gap-3'>
                <textarea rows={3} style={{ resize: 'none' }} disabled value={valuetoSubmit.name} name='name' onChange={handleChange} type='text' className=' rounded-md w-full p-3   outline-none' />
              </div>

            </div>







            <div className=' flex mt-5  justify-center md:flex-row flex-col gap-6 w-full items-center'>
              <div className='  md:w-40 w-full md:justify-end   flex pt-4 text-lg'> Value: </div>
              {/* Service */}
              <div className=' flex w-full flex-col gap-3'>
                <textarea rows={3} style={{ resize: 'none' }} value={textdata[elem?.shortName]} name={elem?.shortName} onChange={handleTextEditor} type='text' className=' rounded-md w-full p-3   outline-none' />
              </div>

            </div>



          </div>
        </>
        }
      </div>
    </>
  );
};

export default ServicesComponent;





