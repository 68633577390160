import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { reportAction } from '../../../_actions';
import QuestionAddComponent from './QuestionAddComponent';
import axios from "axios";
import { authHeader } from '../../../_helpers';
import { useNavigate, useParams } from 'react-router';

export default function AddQuestionModel({ getSkillbyId }) {
  let dispatch = useDispatch();
  let selector = useSelector(state => state)

  let params = useParams()

  useEffect(() => {
    setskillIndicator(getSkillbyId?.skillIndicatorId ? getSkillbyId?.skillIndicatorId : "")
    setdescription(getSkillbyId?.desc && getSkillbyId?.desc ? getSkillbyId?.desc : "")
    setDataToSubmit(getSkillbyId?.name && getSkillbyId?.name ? getSkillbyId?.name : "")
  }, [getSkillbyId])

  let [dataToSubmit, setDataToSubmit] = useState({
  })

  let [dataToSubmitImage, setDataToSubmitImage] = useState({
  })

  let [dataToSubmitAudio, setDataToSubmitAudio] = useState({
  })

  let [description, setdescription] = useState({
  })

  let handleChanges = (e) => {
    let { name, value } = e.target;

    setdescription({ ...description, [name]: value });

  };




  let handleChange = (e) => {
    let { name, value } = e.target;
    // console.log("e.target  ", e.target);
    // console.log(name, value)
    setDataToSubmit({ ...dataToSubmit, [name]: value });
    console.log(dataToSubmit);
  };



  let handleChangeAudio = async (e) => {
    let { name } = e.target;
    const formData = new FormData();
    formData.append('image', e.target.files[0]);
    try {
      const response = await axios.post(`https://orkid.jamsara.com/api/upload`, formData, {
        headers: {
          'Authorization': authHeader().Authorization,
          'Content-Type': 'multipart/form-data'
        }
      });
      const responseData = response.data;

      console.log(responseData?.imageURL);
      setDataToSubmitAudio({ ...dataToSubmitAudio, [name]: responseData?.imageURL });
      return responseData;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };



  let handleChangeImage = async (e) => {
    let { name } = e.target;
    const formData = new FormData();
    formData.append('image', e.target.files[0]);
    try {
      const response = await axios.post(`https://orkid.jamsara.com/api/upload`, formData, {
        headers: {
          'Authorization': authHeader().Authorization,
          'Content-Type': 'multipart/form-data'
        }
      });
      const responseData = response.data;

      console.log(responseData?.imageURL);
      setDataToSubmitImage({ ...dataToSubmitImage, [name]: responseData?.imageURL });
      return responseData;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  let { language, QuestionsManagement } = selector || {}
  let { getSkillAllIndicatior } = language && language ? language : {}
  let { singleQuestionData } = QuestionsManagement && QuestionsManagement ? QuestionsManagement : {}
  let { list } = language && language?.getlanguageSuccess ? language?.getlanguageSuccess : {}



  let [shortname, setshortname] = useState('')
  let temp = {
    "keyWord": "",
    "pageNo": 1,
    "sortBy": "slug",
    "sortOrder": "asc",
    "fromDate": "",
    "toDate": "",
    "size": 10
  }
  let navigate = useNavigate()

  let updatedata = (obj) => {
    dispatch(reportAction.updateSkillAnalyses(obj, temp))
    navigate('/app/reportManagement')
  }

  let handleUpdateReport = () => {


    if (handleValidation()) {
      let obj = {
        "id": params.id,
        "name": dataToSubmit || "",
        "desc": description || "",
        "skillIndicatorId": skillIndicator
      }
      updatedata(obj)

    }
  }


  let [error, setError] = useState({})

  let handleValidation = () => {
    let valid = true
    if (!skillIndicator) {
      valid = false
      setError({ skillError: "Skill cannot be Empty" })

    }
    return valid;
  }



  let [skillIndicator, setskillIndicator] = useState("")
  const handleSKillChange = (event) => {
    const selectedSkillId = event.target.value;
    setskillIndicator(selectedSkillId);
  };




  return (
    <div className=''>
      <div className='  gap-4 flex flex-col mb-3 '>
        <div className=' w-96 flex flex-col gap-5 p-2'>

          <div className='flex justify-between'>


          </div>



          <div className='flex justify-between'>
            <label htmlFor="set">Skill</label>
            <select id="set" value={skillIndicator} onChange={handleSKillChange} className={`${error && error?.skillError ? 'border-red-500 border-2 bg-white w-[50%] p-1' : 'bg-white w-[50%] p-1'}`}>
              <option value="">Select Skill</option>
              {getSkillAllIndicatior && getSkillAllIndicatior?.data && getSkillAllIndicatior?.data.length > 0 && getSkillAllIndicatior?.data.map((e) => (

                <option value={e?._id}>{e?.name || "--"}</option>
              ))}

            </select>
          </div>

        </div>
      </div>
      {list && list.map((elem, index) => (
        <div key={index}>
          <QuestionAddComponent
            skillIndicator={skillIndicator}
            setskillIndicator={setskillIndicator}
            handleChanges={handleChanges}
            singleQuestionData={singleQuestionData}
            setDataToSubmit={setDataToSubmit}
            shortname={shortname}
            setshortname={setshortname}
            dataToSubmitImage={dataToSubmitImage}
            dataToSubmitAudio={dataToSubmitAudio}
            setDataToSubmitImage={setDataToSubmitImage}
            setDataToSubmitAudio={setDataToSubmitAudio}
            elem={elem}
            handleChange={handleChange}
            handleChangeImage={handleChangeImage}
            id={elem.indi}
            handleChangeAudio={handleChangeAudio}
            dataToSubmit={dataToSubmit}
            description={description}
            setdescription={setdescription} />

        </div>
      ))}

      <div className=' w-full flex' >
        <div onClick={handleUpdateReport}>

          <button className=' w-[147px] h-[40px] bg-[#1E5EFF] flex justify-center items-center text-white gap-1 rounded-md mt-4'>Update</button>
        </div>

      </div>

    </div>


  )
}
