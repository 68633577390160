import { alertActions } from './alert.actions';
import { APIcallFunction, APIcallFunctionForFile, headerForPrivateAPI, headerForPrivateMediaAPI, } from '../_helpers';
import { questionConstants } from '../_constants';

export const questionaction = {

    getAllQuestion,
    getAllOption,
    createNewQuestion,
    updateQuestion,
    deleteQuestion,
    upload,
    getQuestionById,
    getQuestionBySet,

};


function getQuestionBySet(data) {
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'getQuestionBySet'
    }

    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: questionConstants.GET_QUESTION_BY_SETID_REQUEST, user } }
    function success(user) { return { type: questionConstants.GET_QUESTION_BY_SETID_SUCCESS, user } }
    function failure(error) { return { type: questionConstants.GET_QUESTION_BY_SETID_FAILURE, error } }
}













function upload(data) {


    const credentials = {
        header: headerForPrivateMediaAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'upload'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunctionForFile(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success("File Uploaded"))

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: questionConstants.FILE_AUDIO_UPLOADED_REQUEST, user } }
    function success(user) { return { type: questionConstants.FILE_AUDIO_UPLOADED_SUCCESS, user } }
    function failure(error) { return { type: questionConstants.FILE_AUDIO_UPLOADED_FAILURE, error } }
}


function updateQuestion(data) {
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'updateQuestion'
    }

    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success("Question Updated"));
                    dispatch(questionaction.getQuestionBySet({ "setId": 1 }))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: questionConstants.QUESTION_CREATE_REQUEST, user } }
    function success(user) { return { type: questionConstants.QUESTION_CREATE_SUCCESS, user } }
    function failure(error) { return { type: questionConstants.QUESTION_CREATE_FAILURE, error } }
}


function deleteQuestion(data, paginationdata) {
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'deleteQuestion'
    }

    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success("Question Deleted"));
                    dispatch(questionaction.getQuestionBySet(paginationdata))

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: questionConstants.QUESTION_CREATE_REQUEST, user } }
    function success(user) { return { type: questionConstants.QUESTION_CREATE_SUCCESS, user } }
    function failure(error) { return { type: questionConstants.QUESTION_CREATE_FAILURE, error } }
}


function createNewQuestion(data) {
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'createNewQuestion'
    }

    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success("Question Created"));
                    dispatch(questionaction.getQuestionBySet({ "setId": 1 }))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: questionConstants.QUESTION_CREATE_REQUEST, user } }
    function success(user) { return { type: questionConstants.QUESTION_CREATE_SUCCESS, user } }
    function failure(error) { return { type: questionConstants.QUESTION_CREATE_FAILURE, error } }
}





function getAllQuestion(data) {
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'getAllQuestion'
    }

    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: questionConstants.QUESTION_GET_REQUEST, user } }
    function success(user) { return { type: questionConstants.QUESTION_GET_SUCCESS, user } }
    function failure(error) { return { type: questionConstants.QUESTION_GET_FAILURE, error } }
}



function getAllOption(data) {
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'getAllOption'
    }

    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: questionConstants.GET_ALL_OPTIONS_REQUEST, user } }
    function success(user) { return { type: questionConstants.GET_ALL_OPTIONS_SUCCESS, user } }
    function failure(error) { return { type: questionConstants.GET_ALL_OPTIONS_FAILURE, error } }
}



function getQuestionById(data) {
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'getQuestionById'
    }

    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: questionConstants.GET_SINGLE_QUESTION_REQUEST, user } }
    function success(user) { return { type: questionConstants.GET_SINGLE_QUESTION_SUCCESS, user } }
    function failure(error) { return { type: questionConstants.GET_SINGLE_QUESTION_FAILURE, error } }
}


