import axios from 'axios';

export function authHeader() {
    let user = JSON.parse(window.sessionStorage.getItem('adminUser'));
    if (user && user.token) {
        return { 'Authorization': 'Bearer ' + user.token }; // returning an object
    } else {
        return {}; // returning an empty object if no user or token
    }
}


export function logoutFunction() {
    window.sessionStorage.removeItem('adminUser');
    window.location.replace('/login');
    window.location.reload()
}

export const headerForPublicAPI = () => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return headers;
};



export const headerForPrivateAPI = () => {
    const token = authHeader()['Authorization']; // Accessing Authorization property of the object returned by authHeader()
    const headers = new Headers();
    if (token) {
        headers.append('Authorization', token); // Adding Authorization header if token exists
    }
    headers.append('Content-Type', 'application/json'); // Always add Content-Type
    return headers;
}



export const headerForPrivateMediaAPI = () => {
    const token = authHeader()['Authorization']; // Accessing Authorization property of the object returned by authHeader()
    const headers = new Headers();
    if (token) {
        headers.append('Authorization', token); // Adding Authorization header if token exists
    }
    headers.append('Content-Type', 'multipart/form-data'); // Always add Content-Type
    return headers;
}



export const APIcallFunction = async (credentials) => {
    const requestOptions = {
        method: credentials.method,
        headers: credentials.header,
        body: JSON.stringify(credentials.body)
    };
    try {
        const response = await fetch(`https://orkid.jamsara.com/api/v1/${credentials.endPoint}`, requestOptions);
        // const response = await fetch(`http://localhost:8204/api/v1/${credentials.endPoint}`, requestOptions);

        const responseData = await handleResponse(response);
        return {
            data: responseData.data
        };
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const APIcallFunctionForFile = async (credentials) => {
    console.log(credentials);
    try {
        const response = await axios.post(`https://orkid.jamsara.com/${credentials.endPoint}`, credentials.body, {
            headers: {
                'Authorization': authHeader().Authorization,
                'Content-Type': 'multipart/form-data'
            }
        });
        const responseData = response.data; 
        return {
            data: responseData
        };
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};







function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // logoutFunction();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            if (data.code === 3) {
                // logoutFunction();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
