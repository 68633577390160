import { serviceConstants } from "../_constants";


export default function serviceReducer(state = {}, action) {
  switch (action.type) {
    case  serviceConstants.CREATE_SERVICE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case  serviceConstants.CREATE_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case  serviceConstants.CREATE_SERVICE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

      
      
      

      case  serviceConstants.GET_SERVICE_LIST_REQUEST:
        return {
          ...state,
          loading: true
        };
      case  serviceConstants.GET_SERVICE_LIST_SUCCESS:
        return {
          ...state,
          getServiceLists:action.user,
          loading: false,
        };
      case  serviceConstants.GET_SERVICE_LIST_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error
        };





      case  serviceConstants.GET_SERVICE_REQUEST:
        return {
          ...state,
          loading: true
        };
      case  serviceConstants.GET_SERVICE_SUCCESS:
        return {
          ...state,
          getServiceList:action.user,
          loading: false,
        };
      case  serviceConstants.GET_SERVICE_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error
        };





        



case  serviceConstants.DELETE_SERVICE_REQUEST:
  return {
    ...state,
    loading: true
  };
case  serviceConstants.DELETE_SERVICE_SUCCESS:
  return {
    ...state,
    loading: false,
  };
case  serviceConstants.DELETE_SERVICE_FAILURE:
  return {
    ...state,
    loading: false,
    error: action.error
  };





    default:
      return state
  }
}

