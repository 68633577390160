/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Button from '../../components/Button';
import BoardManagementJSON from './BoardManagement.json'
import Model from "./Modle/Model"
import { useDispatch, useSelector } from 'react-redux';
import { languageAction } from '../../_actions/language.action';
import Modelupdate from './Modle/Modelupdate';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactPaginate from 'react-paginate';
import Tooltip from '@mui/material/Tooltip';
import Loading from "../../components/Loader/Loader"
import { MdDeleteOutline } from "react-icons/md";
import { RiEditLine } from "react-icons/ri";


const BoardManagement = () => {
  let selector = useSelector(state => state)
  let dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(0);
  let [openAddmodel, setopenAddmodel] = useState(false)
  const [formData, setFormData] = useState({});
  let [errorCreate, seterrorCreate] = useState({})
  const handleChange = (e) => {

    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    seterrorCreate({ ...errorCreate, [e.target.name]: "" })

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleValidationCreate()) {

      let temp = {
        "keyWord": "",
        "pageNo": 1,
        "sortBy": "slug",
        "sortOrder": "asc",
        "fromDate": "",
        "toDate": "",
        "size": 10
      }

      dispatch(languageAction.createBoard(formData, temp))
      setopenAddmodel(false)
      setFormData({})
    }

  };

  const handleValidationCreate = () => {
    let formIsValid = true;
    let errors = {};

    if (!formData.name) {
      formIsValid = false;
      errors.name = "Board Name cannot be empty";
    }
    if (!formData.shortName) {
      formIsValid = false;
      errors.shortName = "Short Name cannot be empty";
    }




    seterrorCreate(errors);
    return formIsValid;
  };

  // updatemodel
  let [openAddmodelUpdate, setopenAddmodelUpdate] = useState(false)
  let [updatedata, setupdatedata] = useState({})
  let [updateError, setupdateError] = useState({})
  let handleUpdatemodel = (data) => {
    setopenAddmodelUpdate(true)
    setupdatedata({ ...data })
  }
  let handleChangeUpdate = (e) => {
    setupdatedata({
      ...updatedata,
      [e.target.name]: e.target.value
    });
    setupdateError({ ...errorCreate, [e.target.name]: "" })
  }

  let handleSubmitUpdate = (e) => {

    e.preventDefault();
    if (handleValidationUpdate()) {
      let obj = {
        "id": updatedata.id,

        "name": updatedata.name,
        "shortName": updatedata.shortName,

      }
      let temp = {
        "keyWord": "",
        "pageNo": currentPage + 1,
        "sortBy": "slug",
        "sortOrder": "asc",
        "fromDate": "",
        "toDate": "",
        "size": 10
      }

      dispatch(languageAction.updateBoard(obj, temp))
      setopenAddmodelUpdate(false)
    }

  }

  const handleValidationUpdate = () => {
    let formIsValid = true;
    let errors = {};


    if (!updatedata.name) {
      formIsValid = false;
      errors.name = "Name cannot be empty";
    }
    if (!updatedata.shortName) {
      formIsValid = false;
      errors.shortName = "ShortName cannot be empty";
    }



    console.log(errors)
    setupdateError(errors);
    return formIsValid;
  };
  //Delete
  let handleDelete = (data) => {

    let datatemp = {
      "id": data.id,
    }

    let paginationdata = {
      "keyWord": "",
      "pageNo": currentPage + 1,
      "sortBy": "slug",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": size
    }
    confirmAlert({
      title: ' Delete ',
      message: `Are you sure want to delete ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(languageAction.deleteBoard(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  //Search

  useEffect(() => {
    let paginationdata = {
      "keyWord": "",
      "pageNo": 1,
      "sortBy": "slug",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": size
    }
    dispatch(languageAction.getBoardList(paginationdata))
  }, [])



  //--------Pagination-------------------------
  const [size, setSize] = useState(10);
  const [isMobile, setIsMobile] = useState(true);
  let [page, setPage] = useState(10)
  //--------Pagination-------------------------
  let [offset, setoffset] = useState(0)
  const handlePageClick = (data) => {
    setoffset(Math.ceil(data.selected * 10))
    setPage(data.selected + 1)
    let datas = {
      "keyWord": "",
      "pageNo": page,
      "size": 10,
      "sortBy": "slug",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
    }
    dispatch(languageAction.getBoardList(datas))
  };
  //--------Pagination-------------------------


  let { language } = selector || {}
  let { loading } = language && language ? language : {}
  let response = selector.language && language?.BoardData && selector.language?.BoardData?.data


  return (

    <>
      <Loading loading={loading} />
      <div className=' h-[90vh] w-full overflow-y-scroll  py-3 md:px-5 px-1  '>
        <div className=' w-full h-full  max-w-[100vw] '>
          <div className='flex justify-between md:flex-row flex-col gap-2 p-5'>
            <div className=' font-semibold text-2xl'>{BoardManagementJSON.Heading}</div>
            <div onClick={() => setopenAddmodel(true)}>
              <Button data={"Add Board"} />
            </div>
          </div>
          <div className='md:py-10 md:px-10 '>
            <div className=''>
              {/* search and filter area */}
              <div className=' '>
                <div className="relative overflow-y-scroll   shadow-md sm:rounded-lg mt-5 ">
                  <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 divide-y-4">

                    <thead className="text-[14px] text-[#5A607F]    bg-white capitalize  ">
                      <tr>

                        <th className="px-6 whitespace-nowrap font-bold  py-3">
                          Board Name
                        </th>

                        <th className="px-6 whitespace-nowrap font-bold  py-3">
                          Board Short Name
                        </th>


                        <th className="px-6 py-3 font-bold ">

                        </th>

                      </tr>
                    </thead>
                    <tbody className=' divide-y-4'>
                      {response && response.list && Array.isArray(response.list) && response.list.length > 0 && response.list.map((e, i) => {
                        return (<tr className="bg-white border-b text-[#121212]  hover:bg-gray-50  " key={i}>
                          <td className="  w-[55%]  p-4 ">
                            <div className="flex gap-2 items-center space-x-6 whitespace-nowrap">
                              <input
                                id="checkbox-table-search-1"
                                type="checkbox"
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2 "
                              />
                              <label htmlFor="checkbox-table-search-1" className="sr-only">
                                checkbox
                              </label>
                              {e.name || "--"}
                            </div>
                          </td>
                          <td className=" w-1/3  p-4 ">
                            <div className="flex ml-2 gap-2 items-start whitespace-nowrap">
                              <Tooltip title={e?.shortName || "--"} arrow>
                                <div className='' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                  {e?.shortName || "--"}
                                </div>
                              </Tooltip>
                            </div>
                          </td>
                          <td className="flex    items-center justify-end  px-6 py-4 gap-3 ">
                            <div onClick={() => handleUpdatemodel(e)} className=' cursor-pointer shadow-md border p-1 '><RiEditLine className=' text-blue-600 text-2xl' /></div>
                            <div onClick={() => handleDelete(e)} className=' cursor-pointer shadow-md border p-1 '><MdDeleteOutline className=' text-blue-600 text-2xl' /></div>
                          </td>

                        </tr>
                        )
                      })}

                    </tbody>
                  </table>

                </div>
              </div>
              {isMobile ? (
                response && response.list && Array.isArray(response.list) && response.list.length > 0 && response.total > 10 ? (
                  <ReactPaginate
                    previousLabel={'Prev'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={Math.ceil(response.total / size)}
                    marginPagesDisplayed={0}
                    pageRangeDisplayed={10}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    pageClassName={'page-cls'}
                    activeClassName={'active'}
                    forcePage={currentPage}
                  />
                ) : null
              ) : (
                response && response.list && Array.isArray(response.list) && response.list.length > 0 && response.total > 10 ? (
                  <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={Math.ceil(response.total / size)}
                    marginPagesDisplayed={10}
                    pageRangeDisplayed={10}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    pageClassName={'page-cls'}
                    activeClassName={'active'}
                  />
                ) : null
              )}
            </div>
          </div>
        </div>
      </div>
      <Model formData={formData} setFormData={setFormData} errorCreate={errorCreate} handleChange={handleChange} handleSubmit={handleSubmit} openAddmodel={openAddmodel} setopenAddmodel={setopenAddmodel} />
      <Modelupdate updatedata={updatedata} handleSubmitUpdate={handleSubmitUpdate} updateError={updateError} handleChangeUpdate={handleChangeUpdate} openAddmodelUpdate={openAddmodelUpdate} setopenAddmodelUpdate={setopenAddmodelUpdate} />

    </>
  );
};


export default BoardManagement;
