/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import loginHistoryJSON from './loginHistory.json'
import SearchIcon from '@mui/icons-material/Search';
import Model from "./Modle/Model"
import { useDispatch, useSelector } from 'react-redux';
import { languageAction } from '../../_actions/language.action';
import Modelupdate from './Modle/Modelupdate';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactPaginate from 'react-paginate';
import Tooltip from '@mui/material/Tooltip';
import Loading from "../../components/Loader/Loader"

const LoginHistory = () => {
  let selector = useSelector(state => state)
  let dispatch = useDispatch()

  let [openAddmodel, setopenAddmodel] = useState(false)
  const [formData, setFormData] = useState({});
  let [errorCreate, seterrorCreate] = useState({})
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    seterrorCreate({ ...errorCreate, [e.target.name]: "" })

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleValidationCreate()) {
      dispatch(languageAction.createLanguage(formData))
      setopenAddmodel(false)
    }

  };

  const handleValidationCreate = () => {
    let formIsValid = true;
    let errors = {};

    if (!formData.name) {
      formIsValid = false;
      errors.name = "Language cannot be empty";
    }

    if (!formData.scriptName) {
      formIsValid = false;
      errors.scriptName = "ScriptName canot be empty";
    }
    if (!formData.shortName) {
      formIsValid = false;
      errors.shortName = "ShortName canot be empty";
    }

    seterrorCreate(errors);
    return formIsValid;
  };

  // updatemodel
  let [openAddmodelUpdate, setopenAddmodelUpdate] = useState(false)
  let [updatedata, setupdatedata] = useState({})
  let [updateError, setupdateError] = useState({})

  let handleChangeUpdate = (e) => {
    setupdatedata({
      ...updatedata,
      [e.target.name]: e.target.value
    });
    setupdateError({ ...errorCreate, [e.target.name]: "" })
  }

  let handleSubmitUpdate = (e) => {
    console.log(updatedata)
    e.preventDefault();
    if (handleValidationUpdate()) {
      let obj = {
        "id": updatedata._id,
        "name": updatedata.name,
        "shortName": updatedata.shortName,
        "scriptName": updatedata.scriptName
      }
      let temp = {
        "keyWord": "",
        "pageNo": 1,
        "sortBy": "slug",
        "sortOrder": "desc",
        "fromDate": "",
        "toDate": "",
        "size": 10
      }
      dispatch(languageAction.updateLanguage(obj, temp))
      setopenAddmodelUpdate(false)
    }

  }

  const handleValidationUpdate = () => {
    let formIsValid = true;
    let errors = {};

    if (!updatedata.name) {
      formIsValid = false;
      errors.name = "Language cannot be empty";
    }

    if (!updatedata.scriptName) {
      formIsValid = false;
      errors.scriptName = "ScriptName canot be empty";
    }
    if (!updatedata.shortName) {
      formIsValid = false;
      errors.shortName = "ShortName canot be empty";
    }

    setupdateError(errors);
    return formIsValid;
  };
  //Delete
  //Search
  let [searchKeyword, setsearchKeyword] = useState("")


  let handleSearch = (e) => {
    setsearchKeyword(e.target.value)
  }
  useEffect(() => {
    let temp = {
      "keyWord": searchKeyword,
      "pageNo": 1,
      "sortBy": "slug",
      "sortOrder": "desc",
      "fromDate": "",
      "toDate": "",
      "size": 10
    }
    dispatch(languageAction.getLoginHistoryList(temp))
  }, [searchKeyword])

  const isMobile = true
  //--------Pagination-------------------------
  const size = 10;
  //--------Pagination--------------------
  const handlePageClick = (data) => {
    let datas = {
      "keyWord": "",
      "pageNo": data.selected + 1,
      "size": size,
      "sortBy": "slug",
      "sortOrder": "desc",
      "fromDate": "",
      "toDate": "",
    }
    dispatch(languageAction.getLoginHistoryList(datas))
  };
  //--------Pagination-------------------------


  let { language } = selector || {}
  let { loading } = language && language ? language : {}
  let { list, total } = language && language?.loginHistoryData ? language?.loginHistoryData : {}

  return (

    <>


      <Loading loading={loading} />

      <div className=' h-[90vh] w-full overflow-y-scroll  py-2 md:px-5 px-1  '>
        <div className=' w-full h-full  max-w-[100vw] '>
          <div className=' flex justify-between p-5'>
            <div className=' font-semibold text-2xl whitespace-nowrap'>{loginHistoryJSON.Heading}</div>

          </div>
          <div className='md:py-10 md:px-10 '>
            <div className=''>
              {/* search and filter area */}
              <div className='flex flex-col items-start gap-4 text-[#7E84A3]'>
                <div className=' max-w-lg bg-white h-[40px] flex items-center border border-[#D9E1EC] rounded gap-2'>
                  <SearchIcon className='w-6 h-6 text-gray-400 ml-2' />
                  <input
                    className='flex-grow h-full outline-none text-sm'
                    onChange={(e) => handleSearch(e)}
                    value={searchKeyword}
                    type='text'
                    placeholder='Search '
                  />
                </div>
              </div>

              <div className=' '>
                <div className="relative overflow-y-scroll   shadow-md sm:rounded-lg mt-5 ">
                  <table className=" w-full text-sm text-left rtl:text-right  text-gray-500 divide-y-4">

                    <thead className="text-[14px] text-[#5A607F]    bg-white capitalize  ">
                      <tr>

                        <th className="px-6 whitespace-nowrap font-bold  py-2">
                          User Name
                        </th>

                        <th className="px-6 whitespace-nowrap font-bold  py-2">
                          Email
                        </th>

                        <th className="px-6 whitespace-nowrap font-bold  py-2">
                          Mobile Number
                        </th>

                        <th className="px-6 whitespace-nowrap font-bold  py-2">
                          Device
                        </th>

                        <th className="px-6 whitespace-nowrap font-bold  py-2">
                          Ip_Address
                        </th>


                        <th className="px-6 whitespace-nowrap font-bold  py-2">
                          Type
                        </th>

                        <th className="px-6 whitespace-nowrap font-bold  py-2">
                          Created At
                        </th>


                      </tr>
                    </thead>
                    {list && total > 0 && list.map((e, i) => (


                      <tbody key={i} className=' divide-y-4'>
                        <tr className="bg-white border-b   hover:bg-gray-50  ">
                          <td className="w-4   px-6 py-2 whitespace-nowrap ">



                            <Tooltip title={e?.userId?.fullName || "--"} arrow>
                              <div className='  w-36 overflow-hidden text-ellipsis'>

                                {e?.userId?.fullName || "--"}
                              </div>
                            </Tooltip>
                          </td>

                          <td className="w-4   px-6 py-2 whitespace-nowrap ">
                            <Tooltip title={e?.userId?.email || "--"} arrow>
                              <div className='  w-36 overflow-hidden text-ellipsis'>

                                {e?.userId?.email || "--"}
                              </div>
                            </Tooltip>
                          </td>
                          <td className="w-4   px-6 py-2 whitespace-nowrap ">
                            {e?.userId?.mobNo || "--"}
                          </td>

                          <td className="w-4   px-6 py-2 whitespace-nowrap ">
                            {e?.userId?.device || "--"}
                          </td>
                          <td className="w-4   px-6 py-2 whitespace-nowrap ">


                            <Tooltip title={e?.userId?.ipAddress || "--"} arrow>
                              <div className=' w-20 overflow-hidden text-ellipsis'>

                                {e?.userId?.ipAddress || "--"}
                              </div>
                            </Tooltip>


                          </td>

                          <td className="w-4   px-6 py-2 whitespace-nowrap ">



                            <Tooltip title={e?.userId?.type || "--"} arrow>
                              <div className=' w-20 overflow-hidden text-ellipsis'>

                                {e?.userId?.type || "--"}
                              </div>
                            </Tooltip>


                          </td>

                          <td className="w-4 px-6 py-2 whitespace-nowrap">
                            {e?.createdAt ? new Date(e.createdAt).toLocaleString() : "--"}
                          </td>


                        </tr>
                      </tbody>
                    ))}
                  </table>

                </div>
                {/* table--------------------------------------------------------------------------------------- */}
              </div>
              {isMobile ? (
                list && total > 10 ? (
                  <ReactPaginate
                    previousLabel={'Prev'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={Math.ceil(total / size)}
                    marginPagesDisplayed={0}
                    pageRangeDisplayed={10}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    pageClassName={'page-cls'}
                    activeClassName={'active'}
                  />
                ) : null
              ) : (
                list && total > 10 ? (
                  <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={Math.ceil(total / size)}
                    marginPagesDisplayed={10}
                    pageRangeDisplayed={10}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    pageClassName={'page-cls'}
                    activeClassName={'active'}
                  />
                ) : null
              )}
            </div>
          </div>
        </div>
      </div>
      <Model formData={formData} setFormData={setFormData} errorCreate={errorCreate} handleChange={handleChange} handleSubmit={handleSubmit} openAddmodel={openAddmodel} setopenAddmodel={setopenAddmodel} />
      <Modelupdate updatedata={updatedata} handleSubmitUpdate={handleSubmitUpdate} updateError={updateError} handleChangeUpdate={handleChangeUpdate} openAddmodelUpdate={openAddmodelUpdate} setopenAddmodelUpdate={setopenAddmodelUpdate} />

    </>
  );
};


export default LoginHistory;
