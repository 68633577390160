import React, { useRef, useState } from 'react';
import { TiPlus } from "react-icons/ti";
import { FaMinus } from "react-icons/fa";
import 'react-quill/dist/quill.snow.css';
import { FaPlusCircle } from "react-icons/fa";
import { authHeader } from '../_helpers';
import axios from 'axios'
import CircularProgress from '@mui/material/CircularProgress';
const StaticAudioComponent = ({ elem, handleTextEditor, value, setDataToSubmitAudio, valuetoSubmit, dataToSubmitAudio, handleChange, imageChange, setimageChange }) => {





  const fileInputRefAudio = useRef(null);
  const handleLabelClickAudio = () => {
    fileInputRefAudio.current.click();
  };
  let [loaderAudio, setLoaderAudio] = useState(false)
  let handleChangeAudio = async (e) => {
    let { name } = e.target;
    const formData = new FormData();
    formData.append('image', e.target.files[0]);
    try {
      setLoaderAudio(true)
      const response = await axios.post(`https://orkid.jamsara.com/api/upload`, formData, {
        headers: {
          'Authorization': authHeader().Authorization,
          'Content-Type': 'multipart/form-data'
        }
      });
      const responseData = response.data;

      console.log(responseData?.imageURL);
      setDataToSubmitAudio({ ...dataToSubmitAudio, [name]: responseData?.imageURL });
      setLoaderAudio(false)
      return responseData;
    } catch (error) {
      console.error('Error:', error);
      setLoaderAudio(false)
      throw error;
    }
  };



  let [toogle, settoogle] = useState(false)


  let handleToogle = () => {
    settoogle(prev => !prev)
  }






  return (
    <>



      <div className='flex flex-col gap-2 '>
        <div className=' w-full p-2 flex justify-between items-center bg-[#C7C7C7] rounded-sm '>
          <div>{elem?.name || ""}</div>
          <div onClick={handleToogle}>
            <TiPlus />
          </div>
        </div>
        {toogle && <>


          <div className=' w-full p-2 relative flex flex-col   gap-3  bg-[#EAEAEA] rounded-sm'>
            <div onClick={handleToogle} className='w-40 right-2 absolute justify-end  flex '> <FaMinus /> </div>


            <div className='flex flex-col md:flex-row md:items-center gap-6'>
              <div className='w-full md:w-40 flex md:justify-end justify-start items-start pt-4 text-lg'> Name: </div>
              {/* Service */}
              <input
                name="pageNo"
                value={valuetoSubmit?.pageNo}
                onChange={handleChange}
                type='text'
                className='rounded-md w-full p-3 outline-none'
              />
            </div>



            <div className='flex gap-6 items-center'>
              <div className=' w-40 justify-end items-start flex pt-4 text-lg'> Audio: </div>
              {!loaderAudio ? <div className='  w-full flex justify-between gap-3 h-20 relative bg-[#EAEAEA]'>
                <label onClick={handleLabelClickAudio} className='w-[9%] absolute top-1' ><FaPlusCircle /></label>

                {dataToSubmitAudio[elem?.shortName] ? <audio className='ml-3' controls>
                  <source src={dataToSubmitAudio[elem?.shortName]} type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio> : ""}



                <input
                  ref={fileInputRefAudio}
                  id={elem.shortName}
                  name={elem.shortName}
                  value={dataToSubmitAudio?.file || ""}
                  onChange={handleChangeAudio}
                  className='w-full px-2 hidden py-1 rounded-md'
                  accept='audio/*'
                  type='file' />

              </div> : <div className=' flex justify-center items-center'><CircularProgress />Uploading....</div>}
            </div>
          </div>

        </>
        }
      </div>



    </>
  );
};

export default StaticAudioComponent;
