/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { languageAction } from '../../_actions/language.action';
import Button from '../../components/Button';
import { alertActions } from '../../_actions';
import ServiceJOSN from './Service.json'
import Loader from '../../components/Loader/Loader'
import { useNavigate } from 'react-router';
import NewLanguageComponent from '../../components/NewLanguageComponent ';



const LanguageSettingNewAdd = () => {

  let dispatch = useDispatch()
  let selector = useSelector(state => state)
  let temp = {
    "keyWord": "",
    "pageNo": 1,
    "sortBy": "",
    "sortOrder": "asc",
    "fromDate": "",
    "toDate": "",
    "size": 150
  }
  useEffect(() => {
    dispatch(languageAction.getLanguageList(temp))
  }, [])
  let { language } = selector;
  let { getlanguageSuccess, loading } = language && language ? language : {}
  let { list, total } = getlanguageSuccess && getlanguageSuccess ? getlanguageSuccess : {}

  let handleChange = (e) => {
    let { name, value } = e.target
    setvaluetoSubmit({ ...valuetoSubmit, [name]: value })

  }
  const [value, setValue] = useState({});

  const [textdata, setTextdata] = useState({})


  const handleTextEditor = (e) => {
    setTextdata({ ...textdata, [e.target.name]: e.target.value })
    // setTextdata({...textdata,[delta]:content})
  };

  //Service
  let [valuetoSubmit, setvaluetoSubmit] = useState({})
  //textEditor
  let [imageChange, setimageChange] = useState({})



  let navigate = useNavigate()
  let create = (obj) => {
    dispatch(languageAction.createLanguageSetting2(obj, temp))
    navigate("/app/languageSetting")
  }


  let handleSubmit = () => {

    let obj = {
      "name": valuetoSubmit.name,
      "key": valuetoSubmit.key,
      "value": textdata || ""
    }

    console.log(obj)

    Object.keys(obj.name).length !== 0 ? create(obj) : alertActions.error("Fields are Empty")

  }
  return (
    <>
      <Loader loading={loading} />
      <div className=' h-[90vh] overflow-y-scroll w-full ovewr  py-3 px-5 flex flex-col '>
        <div className=' flex justify-between p-5'>
          <div className=' font-semibold text-2xl whitespace-nowrap'>{ServiceJOSN.Heading}</div>
        </div>
        <div className=' p-10 w-full h-full flex flex-col gap-4'>
          <div className=' flex mt-5  justify-center md:flex-row flex-col gap-6 w-full items-center'>
            <div className='md:w-40 w-full md:justify-end   flex pt-4 text-lg'> Name </div>
            {/* Service */}
            <div className=' flex w-full flex-col gap-3'>
              <input value={valuetoSubmit.name} name="name" onChange={handleChange} type='text' className=' rounded-md w-full p-3   outline-none' />
            </div>

          </div>


          <div className=' flex mt-5  justify-center md:flex-row flex-col gap-6 w-full items-center'>
            <div className='md:w-40 w-full md:justify-end   flex pt-4 text-lg'> Key </div>
            {/* Service */}
            <div className=' flex w-full flex-col gap-3'>
              <input value={valuetoSubmit.key} name="key" onChange={handleChange} type='text' className=' rounded-md w-full p-3   outline-none' />
            </div>

          </div>
          {list && total > 0 && list.map((elem, index) => (
            <div key={index}>
              <NewLanguageComponent value={value} setValue={setValue} elem={elem} handleChange={handleChange} valuetoSubmit={valuetoSubmit} imageChange={imageChange} setimageChange={setimageChange} handleTextEditor={handleTextEditor} setvaluetoSubmit={setvaluetoSubmit} />
            </div>
          ))}
          <div onClick={handleSubmit} >

            <Button data={"Add"} />
          </div>

        </div>
      </div>

    </>
  );
};

export default LanguageSettingNewAdd;
