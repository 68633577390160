/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/scope */
import React, { useEffect, useState } from 'react';
import DonateJSON from './Donate.json'
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch, useSelector } from 'react-redux';
import { languageAction } from '../../_actions/language.action';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactPaginate from 'react-paginate';
import Loading from "../../components/Loader/Loader"

const Donate = () => {
  let selector = useSelector(state => state)
  let dispatch = useDispatch()

  let [searchKeyword, setsearchKeyword] = useState("")
  let handleSearch = (e) => {
    setsearchKeyword(e.target.value)
  }
  useEffect(() => {
    let temp = {
      "keyWord": searchKeyword,
      "pageNo": page,
      "sortBy": "slug",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": size
    }
    dispatch(languageAction.getAllDoner(temp))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword])


  //--------Pagination-------------------------
  const [size, setSize] = useState(10);
  const [isMobile, setIsMobile] = useState(true);
  let [page, setPage] = useState(1)
  //--------Pagination-------------------------
  let [offset, setoffset] = useState(0)
  const handlePageClick = (data) => {
    setoffset(Math.ceil(data.selected * 10))
    let datas = {
      "keyWord": "",
      "pageNo": data.selected + 1,
      "size": 10,
      "sortBy": "slug",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
    }
    dispatch(languageAction.getAllDoner(datas))
  };
  //--------Pagination-------------------------


  let { language } = selector
  let { loading } = language
  let response = selector && selector.language && selector.language.getAllDoner && selector.language.getAllDoner.data


  return (
    <>
      <Loading loading={loading} />
      <div className=' h-[90vh] w-full overflow-y-scroll  py-3 md:px-5 px-1  '>
        <div className=' w-full h-full  max-w-[100vw] '>
          <div className='flex justify-between md:flex-row flex-col gap-2 p-5'>
            <div className=' font-semibold text-2xl whitespace-nowrap'>{DonateJSON.Heading}</div>
          </div>




          <div className='md:py-10 md:px-10 '>
            <div className=''>
              <div className='flex flex-col items-start gap-4 text-[#7E84A3]'>
                <div className=' max-w-lg bg-white h-[40px] flex items-center border border-[#D9E1EC] rounded gap-2'>
                  <SearchIcon className='w-6 h-6 text-gray-400 ml-2' />
                  <input
                    className='flex-grow h-full outline-none text-sm'
                    onChange={(e) => handleSearch(e)}
                    value={searchKeyword}
                    type='text'
                    placeholder='Search '
                  />
                </div>
              </div>


              {/*Table  */}
              <div className=' '>
                <div className="relative overflow-y-scroll   shadow-md sm:rounded-lg mt-5 ">
                  <table className=" w-full text-sm text-left rtl:text-right  text-gray-500 divide-y-4">
                    <thead className="text-[14px] text-[#5A607F] w-full    bg-white capitalize  ">
                      <tr className=' w-full'>

                        <th scope="col" className="px-6 whitespace-nowrap font-bold  py-3">
                          Full Name
                        </th>

                        <th scope="col" className="px-6 py-1 font-bold ">
                          Email
                        </th>

                        <th scope="col" className="px-6 whitespace-nowrap py-3 font-bold ">
                          Mobile Number
                        </th>

                        <th scope="col" className="px-6 py-1 font-bold ">
                          Date
                        </th>

                        <th scope="col" className="px-6 py-1 font-bold ">
                          Amount
                        </th>
                        <th scope="col" className="px-6 py-1 font-bold ">
                          Order No.
                        </th>
                        <th scope="col" className="px-6 py-1 font-bold ">
                          Receipt
                        </th>

                      </tr>
                    </thead>
                    <tbody className=' divide-y-4 '>
                      {response && response.list && Array.isArray(response.list) && response.list.length > 0 && response.list.map((e, i) => {
                        return (
                          <tr key={i} className='bg-white '>

                            <td scope="col" className="px-6 whitespace-nowrap font-normal   py-1">
                              {e?.userId?.fullName || "--"}
                            </td>

                            <td scope="col" className="px-6 py-4 font-normal ">
                              {e?.userId?.email || "--"}
                            </td>

                            <td scope="col" className="px-6 whitespace-nowrap py-1 font-normal ">
                              {e?.userId?.mobNo || "--"}
                            </td>

                            <td scope="col" className="px-6 py-1 font-normal ">
                              {e?.createdAt ? new Date(e.createdAt).toLocaleString() : "--"}
                            </td>

                            <td scope="col" className="px-6 py-1 font-normal ">
                              {e?.amount || "--"}
                            </td>


                            <td scope="col" className="px-6 py-1 font-normal ">
                              {e?.orderId || "--"}
                            </td>

                            <td scope="col" className="px-6 py-1 font-normal ">
                              {e?.receiptId || "--"}
                            </td>


                          </tr>
                        )
                      })}
                    </tbody>


                  </table>
                </div>
              </div>
              {isMobile ? (
                response && response.list && Array.isArray(response.list) && response.list.length > 0 && response.total > 10 ? (
                  <ReactPaginate
                    previousLabel={'Prev'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={Math.ceil(response.total / size)}
                    marginPagesDisplayed={0}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    pageClassName={'page-cls'}
                    activeClassName={'active'}
                  />
                ) : null
              ) : (
                response && response.list && Array.isArray(response.list) && response.list.length > 0 && response.total > 10 ? (
                  <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={Math.ceil(response.total / size)}
                    marginPagesDisplayed={3}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    pageClassName={'page-cls'}
                    activeClassName={'active'}
                  />
                ) : null
              )}


            </div>
          </div>
        </div>
      </div>


    </>
  );
};


export default Donate;
