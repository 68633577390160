import {
  dashboardConstants
} from '../_constants';

export default function dashboardReducer(state = {}, action) {
  switch (action.type) {
    case dashboardConstants.GET_DATA_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_DATA_SUCCESS:
      return {
        ...state,
        dashboardData: action.user,
        loading: false,
      };
    case dashboardConstants.GET_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state
  }
}
