export const serviceConstants = {

    CREATE_SERVICE_REQUEST:"CREATE_SERVICE_REQUEST",
    CREATE_SERVICE_SUCCESS:"CREATE_SERVICE_SUCCESS",
    CREATE_SERVICE_FAILURE:"CREATE_SERVICE_FAILURE",

GET_SERVICE_REQUEST:"GET_SERVICE_REQUEST",
GET_SERVICE_SUCCESS:"GET_SERVICE_SUCCESS",
GET_SERVICE_FAILURE:"GET_SERVICE_FAILURE",

GET_SERVICE_LIST_REQUEST:"GET_SERVICE_LIST_REQUEST",
GET_SERVICE_LIST_SUCCESS:"GET_SERVICE_LIST_SUCCESS",
GET_SERVICE_LIST_FAILURE:"GET_SERVICE_LIST_FAILURE",



DELETE_SERVICE_REQUEST:"DELETE_SERVICE_REQUEST",
DELETE_SERVICE_SUCCESS:"DELETE_SERVICE_SUCCESS",
DELETE_SERVICE_FAILURE:"DELETE_SERVICE_FAILURE",
    
};
