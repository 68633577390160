/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { TiPlus } from "react-icons/ti";
import { FaMinus } from "react-icons/fa";
import CircularProgress from '@mui/material/CircularProgress';
import 'react-quill/dist/quill.snow.css';
import { FaPlusCircle } from "react-icons/fa";
import axios from 'axios'
import { authHeader } from '../../../_helpers';


const ServicesComponent = ({ textdata, setTextdata, elem, handleChange, data, handleTextEditor, value, setDataToSubmitAudio, dataToSubmitAudio, valuetoSubmit, setvaluetoSubmit, dataToSubmitImage
  , setDataToSubmitImage, handleImage }) => {
  const fileInputRefAudio = useRef(null);








  let [toogle, settoogle] = useState(false)


  let handleToogle = () => {
    settoogle(prev => !prev)
  }





  useEffect(() => {
    setvaluetoSubmit(data?.pageNo || "");

    setDataToSubmitAudio(prevDataToSubmit => ({
      ...prevDataToSubmit,
      [elem.shortName]: data?.audio?.[elem.shortName] || ""
    }));

  }, [data]);

  const handleLabelClickAudio = () => {
    fileInputRefAudio.current.click();
  };
  let [loaderAudio, setLoaderAudio] = useState(false)
  let handleChangeAudio = async (e) => {
    let { name } = e.target;
    const formData = new FormData();
    formData.append('image', e.target.files[0]);
    try {
      setLoaderAudio(true)
      const response = await axios.post(`https://orkid.jamsara.com/api/upload`, formData, {
        headers: {
          'Authorization': authHeader().Authorization,
          'Content-Type': 'multipart/form-data'
        }
      });
      const responseData = response.data;

      console.log(responseData?.imageURL);
      setDataToSubmitAudio({ ...dataToSubmitAudio, [name]: responseData?.imageURL });
      setLoaderAudio(false)
      return responseData;
    } catch (error) {
      console.error('Error:', error);
      setLoaderAudio(false)
      throw error;
    }
  };

  console.log(dataToSubmitAudio)


  return (
    <>



      <div className='flex flex-col gap-2 '>
        <div className=' w-full p-2 flex justify-between items-center bg-[#C7C7C7] rounded-sm '>
          <div>{elem?.name || ""}</div>
          <div onClick={handleToogle}>
            <TiPlus />
          </div>
        </div>
        {toogle && <>
          <div className=' w-full p-2 relative flex flex-col   gap-3  bg-[#EAEAEA] rounded-sm'>
            <div onClick={handleToogle} className='w-40 right-2 absolute justify-end  flex '> <FaMinus /> </div>

            <div className=' flex mt-5  gap-6 md:flex-row flex-col  w-full items-center'>
              <div className=' w-40 md:justify-end justify-start flex pt-4 text-lg'> Name </div>

              <div className=' flex w-full flex-col gap-3'>
                <input disabled value={valuetoSubmit} name="pageNo" onChange={handleChange} type='text' className=' rounded-md w-full p-3 border-2   outline-none' />
              </div>

            </div>
            <div className='flex gap-6 items-center'>
              <div className=' w-40 justify-end items-start flex pt-4 text-lg'> Audio: </div>
              {!loaderAudio ? <div className='  w-full flex justify-between gap-3 h-20 relative bg-[#EAEAEA]'>
                <label onClick={handleLabelClickAudio} className='w-[9%] absolute top-1' ><FaPlusCircle /></label>

                {dataToSubmitAudio[elem?.shortName] ? <audio className='ml-3' controls>
                  <source src={dataToSubmitAudio[elem?.shortName]} type="audio/mpeg" />
                </audio> : ""}



                <input
                  ref={fileInputRefAudio}
                  id={elem.shortName}
                  name={elem.shortName}
                  value={dataToSubmitAudio?.file || ""}
                  onChange={handleChangeAudio}
                  className='w-full px-2 hidden py-1 rounded-md'
                  accept='audio/*'
                  type='file' />

              </div> : <div className=' flex justify-center items-center'><CircularProgress />Uploading....</div>}
            </div>
          </div>
        </>
        }
      </div>



    </>
  );
};

export default ServicesComponent;





