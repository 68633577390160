export const languageConstants = {

    CREATE_NEW_LANGUAGE_REQUEST:"CREATE_NEW_LANGUAGE_REQUEST",
    CREATE_NEW_LANGUAGE_SUCCESS:"CREATE_NEW_LANGUAGE_SUCCESS",
    CREATE_NEW_LANGUAGE_FAILURE:"CREATE_NEW_LANGUAGE_FAILURE",


    GET_LANGUAGE_BY_ID_REQUEST:"GET_LANGUAGE_BY_ID_REQUEST",
    GET_LANGUAGE_BY_ID_SUCCESS:"GET_LANGUAGE_BY_ID_SUCCESS",
    GET_LANGUAGE_BY_ID_FAILURE:"GET_LANGUAGE_BY_ID_FAILURE",


    UPDATE_NEW_LANGUAGE_SETTING_REQUEST:"UPDATE_NEW_LANGUAGE_SETTING_REQUEST",
    UPDATE_NEW_LANGUAGE_SETTING_SUCCESS:"UPDATE_NEW_LANGUAGE_SETTING_SUCCESS",
    UPDATE_NEW_LANGUAGE_SETTING_FAILURE:"UPDATE_NEW_LANGUAGE_SETTING_FAILURE",

    GET_LANGUAGE_SETTING_2_REQUEST:"GET_LANGUAGE_SETTING_2_REQUEST",
    GET_LANGUAGE_SETTING_2_SUCCESS:"GET_LANGUAGE_SETTING_2_SUCCESS",
    GET_LANGUAGE_SETTING_2_FAILURE:"GET_LANGUAGE_SETTING_2_FAILURE",


    GET_NOTIFICATION_REQUEST:"GET_NOTIFICATION_REQUEST",
    GET_NOTIFICATION_SUCCESS:"GET_NOTIFICATION_SUCCESS",
    GET_NOTIFICATION_FAILURE:"GET_NOTIFICATION_FAILURE",

    NOTIFICATION_SEND_REQUEST:"NOTIFICATION_SEND_REQUEST",
    NOTIFICATION_SEND_SUCCESS:"NOTIFICATION_SEND_SUCCESS",
    NOTIFICATION_SEND_FAILURE:"NOTIFICATION_SEND_FAILURE",



UPDATE_FIRST_SCREEN_REQUEST:"UPDATE_FIRST_SCREEN_REQUEST",
UPDATE_FIRST_SCREEN_SUCCESS:"UPDATE_FIRST_SCREEN_SUCCESS",
UPDATE_FIRST_SCREEN_FAILURE:"UPDATE_FIRST_SCREEN_FAILURE",


    GET_FIRST_SCREEN_REQUEST:"GET_FIRST_SCREEN_REQUEST",
    GET_FIRST_SCREEN_SUCCESS:"GET_FIRST_SCREEN_SUCCESS",
    GET_FIRST_SCREEN_FAILURE:"GET_FIRST_SCREEN_FAILURE",



    TICKET_RESOLVE_REQUEST:"TICKET_RESOLVE_REQUEST",
    TICKET_RESOLVE_SUCCESS:"TICKET_RESOLVE_SUCCESS",
    TICKET_RESOLVE_FAILURE:"TICKET_RESOLVE_FAILURE",

    REPLY_BY_ADMIN_FOR_TICKET_REQUEST:"REPLY_BY_ADMIN_FOR_TICKET_REQUEST",
    REPLY_BY_ADMIN_FOR_TICKET_SUCCESS:"REPLY_BY_ADMIN_FOR_TICKET_SUCCESS",
    REPLY_BY_ADMIN_FOR_TICKET_FAILURE:"REPLY_BY_ADMIN_FOR_TICKET_FAILURE",



    GET_ALL_TICKET_REQUEST:"GET_ALL_TICKET_REQUEST",
    GET_ALL_TICKET_SUCCESS:"GET_ALL_TICKET_SUCCESS",
    GET_ALL_TICKET_FAILURE:"GET_ALL_TICKET_FAILURE",

    GET_CHAT_BY_ID_REQUEST:"GET_CHAT_BY_ID_REQUEST",
    GET_CHAT_BY_ID_SUCCESS:"GET_CHAT_BY_ID_SUCCESS",
    GET_CHAT_BY_ID_FAILURE:"GET_CHAT_BY_ID_FAILURE",


    GET_ALL_DONER_REQUEST:"GET_ALL_DONER_REQUEST",
    GET_ALL_DONER_SUCCESS:"GET_ALL_DONER_SUCCESS",
    GET_ALL_DONER_FAILURE:"GET_ALL_DONER_FAILURE",


    GET_SKILL_ALL_INDICATOR_REQUEST:"GET_SKILL_ALL_INDICATOR_REQUEST",
    GET_SKILL_ALL_INDICATOR_SUCCESS:"GET_SKILL_ALL_INDICATOR_SUCCESS",
    GET_SKILL_ALL_INDICATOR_FAILURE:"GET_SKILL_ALL_INDICATOR_FAILURE",


    DELETE_SKILL_REQUEST:"DELETE_SKILL_REQUEST",
    DELETE_SKILL_SUCCESS:"DELETE_SKILL_SUCCESS",
    DELETE_SKILL_FAILURE:"DELETE_SKILL_FAILURE",

UPDATE_Skill_REQUEST:"UPDATE_Skill_REQUEST",
UPDATE_Skill_SUCCESS:"UPDATE_Skill_SUCCESS",
UPDATE_Skill_FAILURE:"UPDATE_Skill_FAILURE",

    SKILL_ADD_REQUEST:"SKILL_ADD_REQUEST",
    SKILL_ADD_SUCCESS:"SKILL_ADD_SUCCESS",
    SKILL_ADD_FAILURE:"SKILL_ADD_FAILURE",


    GET_SKILL_INDICATOR_REQUEST:"GET_SKILL_INDICATOR_REQUEST",
    GET_SKILL_INDICATOR_SUCCESS:"GET_SKILL_INDICATOR_SUCCESS",
    GET_SKILL_INDICATOR_FAILURE:"GET_SKILL_INDICATOR_FAILURE",

    DELETE_BOARD_REQUEST:"DELETE_BOARD_REQUEST",
    DELETE_BOARD_SUCCESS:"DELETE_BOARD_SUCCESS",
    DELETE_BOARD_FAILURE:"DELETE_BOARD_FAILURE",

UPDATE_BOARD_REQUEST:"UPDATE_BOARD_REQUEST",
UPDATE_BOARD_SUCCESS:"UPDATE_BOARD_SUCCESS",
UPDATE_BOARD_FAILURE:"UPDATE_BOARD_FAILURE",

    BOARD_ADD_REQUEST:"BOARD_ADD_REQUEST",
    BOARD_ADD_SUCCESS:"BOARD_ADD_SUCCESS",
    BOARD_ADD_FAILURE:"BOARD_ADD_FAILURE",


    ADD_LANGUAGE_REQUEST: 'ADD_LANGUAGE_REQUEST',
    ADD_LANGUAGE_SUCCESS: 'ADD_LANGUAGE_SUCCESS',
    ADD_LANGUAGE_FAILURE: 'ADD_LANGUAGE_FAILURE',
    
    GET_LANGUAGE_REQUEST: 'GET_LANGUAGE_REQUEST',
    GET_LANGUAGE_SUCCESS: 'GET_LANGUAGE_SUCCESS',
    GET_LANGUAGE_FAILURE: 'GET_LANGUAGE_FAILURE',
    

    UPDATE_LANGUAGE_REQUEST: 'UPDATE_LANGUAGE_REQUEST',
    UPDATE_LANGUAGE_SUCCESS: 'UPDATE_LANGUAGE_SUCCESS',
    UPDATE_LANGUAGE_FAILURE: 'UPDATE_LANGUAGE_FAILURE',


    DELETE_LANGUAGE_REQUEST: 'DELETE_LANGUAGE_REQUEST',
    DELETE_LANGUAGE_SUCCESS: 'DELETE_LANGUAGE_SUCCESS',
    DELETE_LANGUAGE_FAILURE: 'DELETE_LANGUAGE_FAILURE',

LANGUAGE_SETTING_ADD_REQUEST:"LANGUAGE_SETTING_ADD_REQUEST",
LANGUAGE_SETTING_ADD_SUCCESS:"LANGUAGE_SETTING_ADD_SUCCESS",
LANGUAGE_SETTING_ADD_FAILURE:"LANGUAGE_SETTING_ADD_FAILURE",

GET_LANGUAGE_SETTING_REQUEST:"GET_LANGUAGE_SETTING_REQUEST",
GET_LANGUAGE_SETTING_SUCCESS:"GET_LANGUAGE_SETTING_SUCCESS",
GET_LANGUAGE_SETTING_FAILURE:"GET_LANGUAGE_SETTING_FAILURE",


UPDATE_LANGUAGE_SETTING_REQUEST:"UPDATE_LANGUAGE_SETTING_REQUEST",
UPDATE_LANGUAGE_SETTING_SUCCESS:"UPDATE_LANGUAGE_SETTING_SUCCESS",
UPDATE_LANGUAGE_SETTING_FAILURE:"UPDATE_LANGUAGE_SETTING_FAILURE",
DELETE_LANGUAGE_SETTING_REQUEST:"DELETE_LANGUAGE_SETTING_REQUEST",
DELETE_LANGUAGE_SETTING_SUCCESS:"DELETE_LANGUAGE_SETTING_SUCCESS",
DELETE_LANGUAGE_SETTING_FAILURE:"DELETE_LANGUAGE_SETTING_FAILURE",



FILE_UPLOADED_REQUEST:"FILE_UPLOADED_REQUEST",
FILE_UPLOADED_SUCCESS:"FILE_UPLOADED_SUCCESS",
FILE_UPLOADED_FAILURE:"FILE_UPLOADED_FAILURE",


GET_SINGLE_SERVICE_REQUEST:"GET_SINGLE_SERVICE_REQUEST",
GET_SINGLE_SERVICE_SUCCESS:"GET_SINGLE_SERVICE_SUCCESS",
GET_SINGLE_SERVICE_FAILURE:"GET_SINGLE_SERVICE_FAILURE",

GET_LOGIN_HISTORY_REQUEST:"GET_LOGIN_HISTORY_REQUEST",
GET_LOGIN_HISTORY_SUCCESS:"GET_LOGIN_HISTORY_SUCCESS",
GET_LOGIN_HISTORY_FAILURE:"GET_LOGIN_HISTORY_FAILURE",


FILE_UPLOADED_CLEAR:"FILE_UPLOADED_CLEAR"

};
