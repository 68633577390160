/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { alertActions, languageAction, reportAction } from '../../../_actions';
import QuestionAddComponent from './QuestionAddComponent';
import axios from "axios"
import { authHeader } from '../../../_helpers';
import Loader from '../../../components/Loader/Loader'
import { useNavigate } from 'react-router';

export default function AddQuestionModel() {
  let navigate = useNavigate()
  let dispatch = useDispatch();
  let selector = useSelector(state => state)

  let [dataToSubmit, setDataToSubmit] = useState({
  })

  let [dataToSubmitImage, setDataToSubmitImage] = useState({
  })

  let [dataToSubmitAudio, setDataToSubmitAudio] = useState({
  })

  let [description, setdescription] = useState({})


  let handleChange = (e) => {
    let { name, value } = e.target;
    setDataToSubmit({ ...dataToSubmit, [name]: value });

  };
  let handleChanges = (e) => {
    let { name, value } = e.target;
    setdescription({ ...description, [name]: value });

  };





  let handleChangeAudio = async (e) => {
    let { name } = e.target;
    const formData = new FormData();
    formData.append('image', e.target.files[0]);
    try {
      const response = await axios.post(`https://orkid.jamsara.com/api/upload`, formData, {
        headers: {
          'Authorization': authHeader().Authorization,
          'Content-Type': 'multipart/form-data'
        }
      });
      const responseData = response.data;

      console.log(responseData?.imageURL);
      setDataToSubmitAudio({ ...dataToSubmitAudio, [name]: responseData?.imageURL });
      return responseData;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };






  let handleChangeImage = async (e) => {
    let { name } = e.target;
    const formData = new FormData();
    formData.append('image', e.target.files[0]);
    try {
      const response = await axios.post(`https://orkid.jamsara.com/api/upload`, formData, {
        headers: {
          'Authorization': authHeader().Authorization,
          'Content-Type': 'multipart/form-data'
        }
      });
      const responseData = response.data;


      setDataToSubmitImage({ ...dataToSubmitImage, [name]: responseData?.imageURL });
      return responseData;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };


  useEffect(() => {
    let temp = {
      "keyWord": "",
      "pageNo": 1,
      "sortBy": "",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": 150
    }
    dispatch(languageAction.getLanguageList(temp))
    dispatch(languageAction.getAllSkillIndicator())
  }, [])

  let [shortname, setshortname] = useState('')

  let handleSubmitQuestion = () => {

    let temp = {
      "keyWord": "",
      "pageNo": 1,
      "sortBy": "slug",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": 10
    }

    let create = (obj) => {
      dispatch(reportAction.createSkillAnalyses(obj, temp))
      navigate("/app/reportManagement")
    }



    if (handleValidation()) {

      let obj = {
        "name": dataToSubmit,
        "desc": description,
        "skillIndicatorId": skillIndicator
      }

      Object.keys(obj.name).length !== 0 ? create(obj) : alertActions.error("Fields are Empty")

    }
  }
  let [error, setError] = useState({})

  let handleValidation = () => {
    let valid = true
    if (!skillIndicator) {
      valid = false
      setError({ skillError: "Skill cannot be Empty" })

    }
    return valid;
  }





  let [skillIndicator, setskillIndicator] = useState("")

  let { language } = selector || {}
  let { getSkillAllIndicatior, loading } = language && language ? language : {}


  let { list } = language && language?.getlanguageSuccess ? language?.getlanguageSuccess : {}

  const handleSKillChange = (event) => {
    const selectedSkillId = event.target.value;
    setskillIndicator(selectedSkillId);
    setError({})
  };





  console.log()
  return (
    <div className=''>
      <Loader loading={loading} />
      <div className='  gap-4 flex flex-col mb-3 '>
        <div className=' w-96 flex flex-col gap-5 p-2'>



          <div className='flex justify-between'>
            <label htmlFor="set">Skill</label>
            <select id="set" value={skillIndicator?.name} onChange={handleSKillChange} className={`${error && error?.skillError ? 'border-red-500 border-2 bg-white w-[50%] p-1' : 'bg-white w-[50%] p-1'}`}>

              <option value="">Select Skill</option>
              {getSkillAllIndicatior && getSkillAllIndicatior?.data && getSkillAllIndicatior?.data.length > 0 && getSkillAllIndicatior?.data.map((e) => (

                <option value={e?._id}>{e?.name || "--"}</option>
              ))}
            </select>

          </div>


        </div>
      </div>
      {list && list.map((elem, index) => (
        <div key={index}>
          <QuestionAddComponent
            setDataToSubmit={setDataToSubmit}
            shortname={shortname}
            setshortname={setshortname}
            elem={elem}
            handleChange={handleChange}
            handleChangeImage={handleChangeImage}
            id={elem.indi}
            handleChangeAudio={handleChangeAudio}
            dataToSubmitAudio={dataToSubmitAudio}
            dataToSubmitImage={dataToSubmitImage}
            dataToSubmit={dataToSubmit}
            description={description}
            handleChanges={handleChanges} />
        </div>
      ))}

      <div className=' w-full flex' >
        <div onClick={handleSubmitQuestion}>

          <button className=' w-[147px] h-[40px] bg-[#1E5EFF] flex justify-center items-center text-white gap-1 rounded-md mt-4'>Add</button>
        </div>

      </div>

    </div>


  )
}
