/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { languageAction } from '../../_actions/language.action';
import Button from '../../components/Button';
import { IntroductionAction, alertActions } from '../../_actions';
import axios from "axios"
import { authHeader } from '../../_helpers';
import StaticAudioComponent from '../../components/StaticAudioComponent';





const StaticAudioAdd = () => {

  let dispatch = useDispatch()
  let selector = useSelector(state => state)
  useEffect(() => {
    let temp = {
      "keyWord": "",
      "pageNo": 1,
      "sortBy": "",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": 10
    }
    dispatch(languageAction.getLanguageList(temp))
  }, [])
  let { language } = selector;
  let { getlanguageSuccess } = language ? language : {}
  let { list, total } = getlanguageSuccess && getlanguageSuccess ? getlanguageSuccess : {}

  let handleChange = (e) => {
    let { name, value } = e.target
    setvaluetoSubmit({ ...valuetoSubmit, [name]: value })

  }
  const [value, setValue] = useState({});

  const [textdata, setTextdata] = useState({})



  const handleTextEditor = (content, delta, source, editor) => {

    console.log(content)
    setTextdata({ ...textdata, [delta]: content })
  };



  //Service
  let [valuetoSubmit, setvaluetoSubmit] = useState({})
  //textEditor
  let [imageChange, setimageChange] = useState({})
  let [dataToSubmitAudio, setDataToSubmitAudio] = useState({})

  let handleImage = async (e) => {

    let { name } = e.target;
    const formData = new FormData();
    formData.append('image', e.target.files[0]);
    try {
      const response = await axios.post(`https://orkid.jamsara.com/api/upload`, formData, {
        headers: {
          'Authorization': authHeader().Authorization,
          'Content-Type': 'multipart/form-data'
        }
      });
      const responseData = response.data;


      setimageChange({ ...imageChange, [name]: responseData?.imageURL });
      return responseData;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }


  let handleSubmit = () => {
    console.log(valuetoSubmit?.pageNo)
    let obj = {
      // "name": valuetoSubmit,
      // "image": imageChange,
      "pageNo": valuetoSubmit && valuetoSubmit.pageNo ? valuetoSubmit.pageNo : "",
      // "content": textdata || "",
      "audio": dataToSubmitAudio
    }

    obj.pageNo !== "" ? dispatch(IntroductionAction.createNewPage(obj)) : alertActions.error("Fields are Empty")

  }
  return (
    <>
      <div className=' h-[90vh] overflow-y-scroll w-full ovewr  py-3 px-5 flex '>
        <div className=' p-10 w-full h-full flex flex-col gap-4'>
          {list && total > 0 && list.map((elem, index) => (
            <div key={index}>
              <StaticAudioComponent
                setDataToSubmitAudio={setDataToSubmitAudio}
                dataToSubmitAudio={dataToSubmitAudio} value={value} setValue={setValue} elem={elem} handleChange={handleChange} valuetoSubmit={valuetoSubmit} imageChange={imageChange} setimageChange={setimageChange} handleTextEditor={handleTextEditor} setvaluetoSubmit={setvaluetoSubmit} handleImage={handleImage} />
            </div>
          ))}
          <div onClick={handleSubmit} >

            <Button data={"Add"} />
          </div>

        </div>
      </div>

    </>
  );
};

export default StaticAudioAdd;
