/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import Button from '../../components/Button';
import ReportJSON from './Report.json'
import Model from './Modle/Model';
import Modelupdate from './Modle/Modelupdate';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import { reportAction } from '../../_actions';
import Loading from "../../components/Loader/Loader"
import Tooltip from '@mui/material/Tooltip';
import { MdDeleteOutline } from "react-icons/md";
import { RiEditLine } from "react-icons/ri";



const Report = () => {
  let dispatch = useDispatch()
  let selector = useSelector(state => state)

  //create
  let [openAddmodel, setopenAddmodel] = useState(false)
  const [formData, setFormData] = useState({});
  let [errorCreate, seterrorCreate] = useState({})
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    seterrorCreate({ ...errorCreate, [e.target.name]: "" })

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleValidationCreate()) {
      let datas = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
      }
      dispatch(reportAction.getAllSkillAnalyses(formData, datas))
      setFormData({})
      seterrorCreate({})
      setopenAddmodel(false)
    }

  };

  const handleValidationCreate = () => {

    let formIsValid = true;
    let errors = {};

    if (!formData.fullName || formData.fullName === "") {
      formIsValid = false;
      errors.fullName = "Name cannot be empty";
    }

    if (!formData.email) {
      formIsValid = false;
      errors.email = "Email cannot be empty";
    }
    if (!formData.mobNo) {
      formIsValid = false;
      errors.mobNo = "Mobile Number cannot be empty";
    }
    if (!formData.dob) {
      formIsValid = false;
      errors.dob = "Date of Birth cannot be empty";
    }
    if (!formData.gender) {
      formIsValid = false;
      errors.gender = "Gender  cannot be empty";
    }

    if (!formData.email) {
      formIsValid = false;
      errors.email = "Email  cannot be empty";
    }

    if (!formData.grade) {
      formIsValid = false;
      errors.grade = "Grade  cannot be empty";
    }
    seterrorCreate(errors);
    return formIsValid;
  };

  // updatemodel
  let [openAddmodelUpdate, setopenAddmodelUpdate] = useState(false)
  let [updatedata, setupdatedata] = useState({})
  let [updateError, setupdateError] = useState({})
  let handleUpdatemodel = (data) => {
    setopenAddmodelUpdate(true)
    setupdatedata({ ...data })
  }
  let handleChangeUpdate = (e) => {
    setupdatedata({
      ...updatedata,
      [e.target.name]: e.target.value
    });
    setupdateError({ ...errorCreate, [e.target.name]: "" })
  }

  let handleSubmitUpdate = (e) => {
    e.preventDefault();
    if (handleValidationUpdate()) {


      const studentData = {
        "id": updatedata._id,
        "fullName": updatedata.fullName,
        "email": updatedata.email,
        "mobNo": updatedata.mobNo,
        "dob": updatedata.dob,
        "gender": updatedata.gender,
        "grade": updatedata.grade,
        board: updatedata.board,
        medium: updatedata.medium,
        motherTongue: updatedata.motherTongue,
        parentEducation: updatedata.parentEducation,
        parentIncome: updatedata.parentIncome,
        schoolName: updatedata.schoolName,
      };
      let temp = {
        "keyWord": "",
        "pageNo": 1,
        "sortBy": "slug",
        "sortOrder": "asc",
        "fromDate": "",
        "toDate": "",
        "size": 10
      }
      dispatch(reportAction.getAllSkillAnalyses(studentData, temp))
      setupdatedata({})
      setopenAddmodelUpdate(false)
    }

  }

  const handleValidationUpdate = () => {
    let formIsValid = true;
    let errors = {};

    if (!updatedata.fullName || updatedata.fullName === "") {
      formIsValid = false;
      errors.fullName = "Name cannot be empty";
    }

    if (!updatedata.email) {
      formIsValid = false;
      errors.email = "Email cannot be empty";
    }
    if (!updatedata.mobNo) {
      formIsValid = false;
      errors.mobNo = "Mobile Number cannot be empty";
    }
    if (!updatedata.dob) {
      formIsValid = false;
      errors.dob = "Date of Birth cannot be empty";
    }
    if (!updatedata.gender) {
      formIsValid = false;
      errors.gender = "Gender  cannot be empty";
    }

    if (!updatedata.grade) {
      formIsValid = false;
      errors.grade = "Grade  cannot be empty";
    }

    setupdateError(errors);
    return formIsValid;
  };
  // updatemodel

  //--------Pagination-------------------------
  const [size, setSize] = useState(10);
  const [isMobile, setIsMobile] = useState(true);
  //--------Pagination-------------------------
  let [offset, setoffset] = useState(0)
  const handlePageClick = (data) => {
    setoffset(Math.ceil(data.selected * 10))
    let datas = {
      "keyWord": "",
      "pageNo": data.selected + 1,
      "size": 10
    }
    dispatch(reportAction.getAllSkillAnalyses(datas))
  };
  //--------Pagination-------------------------

  //Search

  useEffect(() => {
    let temp = {
      "keyWord": "",
      "pageNo": 1,
      "sortBy": "slug",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": 10
    }
    dispatch(reportAction.getAllSkillAnalyses(temp))
  }, [])
  //search
  //Delete
  let handleDelete = (data) => {
    let datatemp = {
      "serviceId": data._id,
    }
    let paginationdata = {
      "keyWord": "",
      "pageNo": 1,
      "size": 10
    }
    confirmAlert({
      title: ' Delete ',
      message: `Are you sure want to Delete ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(reportAction.deleteSkillAnalyses(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }


  let { ReportManagementReducer } = selector || {}
  let { loading, getallSkillsAnalysis } = ReportManagementReducer && ReportManagementReducer ? ReportManagementReducer : {}

  let { data } = ReportManagementReducer && ReportManagementReducer.questionList ? ReportManagementReducer.questionList : {}


  let total = getallSkillsAnalysis && getallSkillsAnalysis.length > 0 ? getallSkillsAnalysis.length : 0




  return (
    <>
      <Loading loading={loading} />
      <div className=' h-[90vh] w-full overflow-y-scroll  py-3 md:px-5 px-1  '>
        <div className=' w-full h-full  max-w-[100vw] '>
          <div className='flex justify-between md:flex-row flex-col gap-2 p-5'>
            <div className=' font-semibold text-2xl'>{ReportJSON.Heading}</div>
            <Link to={"/app/report/add"}>
              <Button data={"Add Report"} />
            </Link>
          </div>
          <div className='md:py-10 md:px-10 '>
            <div className=''>
              {/* search and filter area */}
              {/* <div className='flex flex-col items-start gap-4 text-[#7E84A3]'>
      <div className=' max-w-lg bg-white h-[40px] flex items-center border border-[#D9E1EC] rounded gap-2'>
        <SearchIcon className='w-6 h-6 text-gray-400 ml-2' />
        <input
          className='flex-grow h-full outline-none text-sm'
          onChange={(e) => handleSearch(e)}
          value={searchKeyword}
          type='text'
          placeholder='Search '
        />
      </div>
    </div> */}

              {/*Table  */}
              <div className='  '>
                <div className="relative  overflow-y-scroll  max-h-[70vh] shadow-md sm:rounded-lg mt-5 ">
                  <table className="w-full text-sm text-left rtl:text-right  text-gray-500  divide-y-4">
                    <thead className="text-[14px] text-[#5A607F] w-full    bg-white capitalize  ">
                      <tr className=' w-full'>

                        <th className="px-6 whitespace-nowrap font-bold  py-3">
                          Q.No
                        </th>

                        <th className="px-6 py-3 font-bold ">
                          Name
                        </th>



                        <th className="px-6 py-3 font-bold ">
                          Description
                        </th>



                        <th className="px-6 py-3 font-bold ">
                          Action
                        </th>




                      </tr>
                    </thead>


                    {
                      getallSkillsAnalysis && total > 0 && getallSkillsAnalysis.map((e, i) => {

                        return (
                          <tbody key={i} className=''>
                            <tr className='bg-white'>
                              <td className="px-6 py-3 font-normal ">
                                {i + 1 || "0"}
                              </td>

                              <td className="px-6 whitespace-nowrap font-normal   py-3">
                                <div className="flex gap-2 items-center w-32 space-x-6 whitespace-nowrap">
                                  <Tooltip title={e?.name?.en || "--"} arrow>


                                    <div className=' w-32 h-10  overflow-hidden text-nowrap text-ellipsis flex justify-start items-center'>

                                      {e?.name?.en || "--"}
                                    </div>
                                  </Tooltip>
                                </div>
                              </td>








                              <td className="px-6 whitespace-nowrap overflow-ellipsis font-normal py-3">
                                <Tooltip title={e?.desc?.en || "--"} arrow>
                                  <div className=' overflow-hidden  w-96'>
                                    {e?.desc?.en || "--"}
                                  </div>
                                </Tooltip>
                              </td>



                              {/* <td className="px-6 whitespace-nowrap py-3 flex flex-wrap items-center justify-center lg:justify-start lg:gap-3 font-normal">
  <Link title='Edit' to={`/app/report/${e._id}`}>
    <img className="cursor-pointer max-w-full lg:h-10 h-10" src='/icons/create.svg' alt="Edit" />
  </Link>
  <img title='Delete' onClick={() => handleDelete(e)} className="cursor-pointer max-w-full lg:h-10 h-10 mt-2 lg:mt-0" src='/icons/delete.svg' alt="Delete" />
</td> */}




                              <td className="flex items-center justify-start px-6 py-2 gap-3">
                                < Link title='Edit' to={`/app/report/${e._id}`} className='cursor-pointer shadow-md border p-1 '><RiEditLine className=' text-blue-600 text-2xl' /></Link>
                                <div onClick={() => handleDelete(e)} className=' cursor-pointer shadow-md border p-1 '><MdDeleteOutline className=' text-blue-600 text-2xl' /></div>
                              </td>





                            </tr>
                          </tbody>
                        )
                      })
                    }

                  </table>


                </div>
              </div>
              {isMobile ? (
                data && total > 10 ? (
                  <ReactPaginate
                    previousLabel={'Prev'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={Math.ceil(total / size)}
                    marginPagesDisplayed={0}
                    pageRangeDisplayed={10}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    pageClassName={'page-cls'}
                    activeClassName={'active'}
                  />
                ) : null
              ) : (
                data && total > 10 ? (
                  <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={Math.ceil(total / size)}
                    marginPagesDisplayed={10}
                    pageRangeDisplayed={10}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    pageClassName={'page-cls'}
                    activeClassName={'active'}
                  />
                ) : null
              )}
            </div>
          </div>
        </div>
      </div>
      <Model formData={formData} setFormData={setFormData} seterrorCreate={seterrorCreate} errorCreate={errorCreate} handleChange={handleChange} handleSubmit={handleSubmit} openAddmodel={openAddmodel} setopenAddmodel={setopenAddmodel} />
      <Modelupdate updatedata={updatedata} setupdatedata={setupdatedata} handleSubmitUpdate={handleSubmitUpdate} setupdateError={setupdateError} updateError={updateError} handleChangeUpdate={handleChangeUpdate} openAddmodelUpdate={openAddmodelUpdate} setopenAddmodelUpdate={setopenAddmodelUpdate} />
    </>
  );
};

export default Report;
