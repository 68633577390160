import { alertActions} from './alert.actions';
import { APIcallFunction, headerForPrivateAPI, } from '../_helpers';
import {  introductionConstants } from '../_constants';



export const IntroductionAction = {

    createNewIntroduction,
    getServiceList,
    getIntroductionList,
    deleteIntroduction,
    updateIntroduction,
    getIntroductionById,








    createNewPage,
    getPageList,
    getPageById,
    deletePage,
    updatePage
};

function updatePage(data,Pagination) {
    const credentials = {
        header : headerForPrivateAPI(),
        method : "POST",
        body : data ?? {},
        endPoint : 'updatePage'
    }

    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success("Updated"));
                    // dispatch(IntroductionAction.getPageList(Pagination))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: introductionConstants.UPDATE_PAGE_REQUEST, user } }
    function success(user) { return { type: introductionConstants.UPDATE_PAGE_SUCCESS, user } }
    function failure(error) { return { type: introductionConstants.UPDATE_PAGE_FAILURE, error } }
}


function deletePage(data,Pagination) {
    const credentials = {
        header : headerForPrivateAPI(),
        method : "POST",
        body : data ?? {},
        endPoint : 'deletePage'
    }

    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success("Deleted"));
                    dispatch(IntroductionAction.getPageList(Pagination))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: introductionConstants.GET_DELETE_PAGE_REQUEST, user } }
    function success(user) { return { type: introductionConstants.GET_DELETE_PAGE_SUCCESS, user } }
    function failure(error) { return { type: introductionConstants.GET_DELETE_PAGE_FAILURE, error } }
}













function getPageById(data,Pagination) {
    const credentials = {
        header : headerForPrivateAPI(),
        method : "POST",
        body : data ?? {},
        endPoint : 'getPageById'
    }

    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: introductionConstants.GET_PAGE_BY_ID_REQUEST, user } }
    function success(user) { return { type: introductionConstants.GET_PAGE_BY_ID_SUCCESS, user } }
    function failure(error) { return { type: introductionConstants.GET_PAGE_BY_ID_FAILURE, error } }
}







function getPageList(data) {
    const credentials = {
        header : headerForPrivateAPI(),
        method : "POST",
        body : data ?? {},
        endPoint : 'getPageList'
    }

    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: introductionConstants.GET_PAGE_LIST_REQUEST, user } }
    function success(user) { return { type: introductionConstants.GET_PAGE_LIST_SUCCESS, user } }
    function failure(error) { return { type: introductionConstants.GET_PAGE_LIST_FAILURE, error } }
}

function createNewPage(data) {
    const credentials = {
        header : headerForPrivateAPI(),
        method : "POST",
        body : data ?? {},
        endPoint : 'createNewPage'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success("Created "))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: introductionConstants.CREATE_NEW_PAGE_REQUEST, user } }
    function success(user) { return { type: introductionConstants.CREATE_NEW_PAGE_SUCCESS, user } }
    function failure(error) { return { type: introductionConstants.CREATE_NEW_PAGE_FAILURE, error } }
}








function getIntroductionById(data,Pagination) {
    const credentials = {
        header : headerForPrivateAPI(),
        method : "POST",
        body : data ?? {},
        endPoint : 'getIntroductionById'
    }

    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: introductionConstants.GET_INTRO_BY_ID_REQUEST, user } }
    function success(user) { return { type: introductionConstants.GET_INTRO_BY_ID_SUCCESS, user } }
    function failure(error) { return { type: introductionConstants.GET_INTRO_BY_ID_FAILURE, error } }
}







function getIntroductionList(data,Pagination) {
    const credentials = {
        header : headerForPrivateAPI(),
        method : "POST",
        body : data ?? {},
        endPoint : 'getIntroductionList'
    }

    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: introductionConstants.GET_INTRO_LIST_REQUEST, user } }
    function success(user) { return { type: introductionConstants.GET_INTRO_LIST_SUCCESS, user } }
    function failure(error) { return { type: introductionConstants.GET_INTRO_LIST_FAILURE, error } }
}



function updateIntroduction(data,Pagination) {
    const credentials = {
        header : headerForPrivateAPI(),
        method : "POST",
        body : data ?? {},
        endPoint : 'updateIntroduction'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success("Introduction Updated "))
                    dispatch(IntroductionAction.getIntroductionList(Pagination))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: introductionConstants.UPDATE_INTOR_REQUEST, user } }
    function success(user) { return { type: introductionConstants.UPDATE_INTOR_SUCCESS, user } }
    function failure(error) { return { type: introductionConstants.UPDATE_INTOR_FAILURE, error } }
}
function deleteIntroduction(data,Pagination) {
    const credentials = {
        header : headerForPrivateAPI(),
        method : "POST",
        body : data ?? {},
        endPoint : 'deleteIntroduction'
    }

    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success("Deleted"));
                    dispatch(IntroductionAction.getIntroductionList(Pagination))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: introductionConstants.DELETE_INTRO_REQUEST, user } }
    function success(user) { return { type: introductionConstants.DELETE_INTRO_SUCCESS, user } }
    function failure(error) { return { type: introductionConstants.DELETE_INTRO_FAILURE, error } }
}


function createNewIntroduction(data,Pagination) {
    const credentials = {
        header : headerForPrivateAPI(),
        method : "POST",
        body : data ?? {},
        endPoint : 'createNewIntroduction'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success("Introduction Created "))
                    dispatch(IntroductionAction.getIntroductionList(Pagination))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: introductionConstants.CREATE_INTOR_REQUEST, user } }
    function success(user) { return { type: introductionConstants.CREATE_INTOR_SUCCESS, user } }
    function failure(error) { return { type: introductionConstants.CREATE_INTOR_FAILURE, error } }
}



function getServiceList(data,Pagination) {
    const credentials = {
        header : headerForPrivateAPI(),
        method : "POST",
        body : data ?? {},
        endPoint : 'getServiceList'
    }

    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: introductionConstants.GET_SERVICE_REQUEST, user } }
    function success(user) { return { type: introductionConstants.GET_SERVICE_SUCCESS, user } }
    function failure(error) { return { type: introductionConstants.GET_SERVICE_FAILURE, error } }
}

