import React from "react";
import { Route, Routes } from 'react-router-dom';
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import Dashboard from "../../pages/dashboard/Dashboard";
import layoutJSON from './layout.json'
import UserManagement from "../../pages/userManagement/UserManagement";
import LanguageManagement from "../../pages/languageManagement/LanguageManagement";
import StudentManagements from "../../pages/studentManagement/StudentManagements";
import Question from "../../pages/Question/Question";
import QuestionAdd from '../../pages/QuestionAdd/QuestionAdd';
import ServiceAdd from "../../pages/ServiceAdd/ServiceAdd";
import Service from "../../pages/Service/Service";
import QuestionSingle from "../../pages/QuestionSingle/QuestionSingle";
import ServiceById from "../../pages/ServiceById/ServiceById";
import LoginHistory from "../../pages/loginHistory/LoginHistory";
import Introduction from "../../pages/Introduction/Introduction";
import IntroductionAdd from "../../pages/IntroductionAdd/IntroductionAdd";
import IntroductionById from "../../pages/IntroductionById/IntroductionById";
import BoardManagement from "../../pages/BoardManagement/BoardManagement";
import SkillManagement from "../../pages/SkillManagement/SkillManagement";
import Report from "../../pages/Report/Report";
import ReportAdd from "../../pages/ReportAdd/ReportAdd";
import ReportSingle from "../../pages/ReportSingle/ReportSingle";
import Donate from "../../pages/Donate/Donate";
import Help from "../../pages/Help/Help";
import FirstScreen from "../../pages/FirstScreen/FirstScreen";
import Notification from "../../pages/Notification/Notification";
import StaticAudio from "../../pages/StaticAudio/StaticAudio";
import StaticAudioById from "../../pages/StaticAudioById/StaticAudioById";
import StaticAudioAdd from "../../pages/StaticAudioAdd/StaticAudioAdd";
import LanguageSettingNew from "../../pages/LanguageSettingNew/LanguageSettingNew";
import LanguageSettingNewAddById from "../../pages/LanguageSettingNewAddById/LanguageSettingNewAddById";
import LanguageSettingNewAdd from "../../pages/LanguageSettingNewAdd/LanguageSettingNewAdd";


function Layout() {
  return (
    <div>
      <div className="overflow-hidden h-[100vh] bg-gray-100">
        <div className=" flex flex-col justify-center">
      <Header HeaderJSON={layoutJSON.headerJSON}/>
          <div className=" flex overflow-hidden ">
            <Sidebar SidebarJSON = {layoutJSON.sidebarJSON}/>
            <div className="flex flex-col flex-1 ">
              <Routes>
                <Route path="/dashboard"  element={<Dashboard/>} />
                <Route path="/userManagement"  element={<UserManagement/>} />
                <Route path="/studentManagement"  element={<StudentManagements/>} />
                <Route path="/lanugageManagement"  element={<LanguageManagement/>} />
                <Route path="/question"  element={<Question/>} />
                <Route path="/question/add"  element={<QuestionAdd/>} />
                <Route path="/question/add/:id"  element={<QuestionSingle/>} />
                {/* <Route path="/languageSetting"  element={<LanguageSetting/>} /> */}



                <Route path="/languageSetting"  element={<LanguageSettingNew/>} />
                <Route path="/languageSetting/add"  element={<LanguageSettingNewAdd/>} />
                <Route path="/languageSetting/add/:id"  element={<LanguageSettingNewAddById/>} />


                <Route path="/service"  element={<Service/>} />
                <Route path="/service/add"  element={<ServiceAdd/>} />
                <Route path="/service/add/:id"  element={<ServiceById/>} />

                <Route path="/StaticAudio"  element={<StaticAudio/>} />
                <Route path="/StaticAudio/add"  element={<StaticAudioAdd/>} />
                <Route path="/StaticAudio/add/:id"  element={<StaticAudioById/>} />


                
                <Route path="/FirstScreen"  element={<FirstScreen/>} />


                <Route path="/loginHistory" element={<LoginHistory/>} />
                <Route path="/introduction" element={<Introduction/>} />
                <Route path="/introduction/add"  element={<IntroductionAdd/>} />
                <Route path="/introduction/:id"  element={<IntroductionById/>} />
                <Route path="/boardManagement"  element={<BoardManagement/>} />
                <Route path="/skillindicator"  element={<SkillManagement/>} />
                <Route path="/reportManagement"  element={<Report/>} />
                <Route path="/report/add"  element={<ReportAdd/>} />
                <Route path="/report/:id"  element={<ReportSingle/>} />
                
                <Route path="/notification"  element={<Notification/>} />

                <Route path="/donate"  element={<Donate/>} />
                <Route path="/help"  element={<Help/>} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Layout;
