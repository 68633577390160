import { alertActions} from './alert.actions';
import { APIcallFunction, headerForPrivateAPI } from '../_helpers';
import {  reportConstants } from '../_constants';

export const reportAction = {


    getAllSkillAnalyses,
    createSkillAnalyses,
    getSkillAnalysesById,
    updateSkillAnalyses,
    deleteSkillAnalyses



};
function deleteSkillAnalyses(data,temp) {
    const credentials = {
        header : headerForPrivateAPI(),
        method : "POST",
        body : data ?? {},
        endPoint : 'deleteSkillAnalyses'
    }
  
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(reportAction.getAllSkillAnalyses(temp))
                    dispatch(alertActions.success("Deleted"));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: reportConstants.DELETE_SKILL_ANALYSIS_REQUEST, user } }
    function success(user) { return { type: reportConstants.DELETE_SKILL_ANALYSIS_SUCCESS, user } }
    function failure(error) { return { type: reportConstants.DELETE_SKILL_ANALYSIS_FAILURE, error } }
}




function updateSkillAnalyses(data,temp) {
    const credentials = {
        header : headerForPrivateAPI(),
        method : "POST",
        body : data ?? {},
        endPoint : 'updateSkillAnalyses'
    }
  
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success("Updated"));
                    dispatch( reportAction.getAllSkillAnalyses(temp))
                    
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: reportConstants.UPDATE_REPORT_REQUEST, user } }
    function success(user) { return { type: reportConstants.UPDATE_REPORT_SUCCESS, user } }
    function failure(error) { return { type: reportConstants.UPDATE_REPORT_FAILURE, error } }
}





function createSkillAnalyses(data,temp) {
    const credentials = {
        header : headerForPrivateAPI(),
        method : "POST",
        body : data ?? {},
        endPoint : 'createSkillAnalyses'
    }
  
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success("Report Created"));
                    dispatch( reportAction.getAllSkillAnalyses(temp))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: reportConstants.CREATE_SKILL_ANALYSIS_REQUEST, user } }
    function success(user) { return { type: reportConstants.CREATE_SKILL_ANALYSIS_SUCCESS, user } }
    function failure(error) { return { type: reportConstants.CREATE_SKILL_ANALYSIS_FAILURE, error } }
}

function getAllSkillAnalyses(data) {
    const credentials = {
        header : headerForPrivateAPI(),
        method : "POST",
        body : data ?? {},
        endPoint : 'getAllSkillAnalyses'
    }
  
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: reportConstants.GET_ALL_SKILL_ANALYSIS_REQUEST, user } }
    function success(user) { return { type: reportConstants.GET_ALL_SKILL_ANALYSIS_SUCCESS, user } }
    function failure(error) { return { type: reportConstants.GET_ALL_SKILL_ANALYSIS_FAILURE, error } }
}


function getSkillAnalysesById(data) {
    const credentials = {
        header : headerForPrivateAPI(),
        method : "POST",
        body : data ?? {},
        endPoint : 'getSkillAnalysesById'
    }
  
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: reportConstants.GET_SKILL_BY_ID_REQUEST, user } }
    function success(user) { return { type: reportConstants.GET_SKILL_BY_ID_SUCCESS, user } }
    function failure(error) { return { type: reportConstants.GET_SKILL_BY_ID_FAILURE, error } }
}




