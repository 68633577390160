/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Button from '../../components/Button';
import QuestionJSON from './Question.json'
import Model from './Modle/Model';
import Modelupdate from './Modle/Modelupdate';
import { useDispatch, useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import { questionaction } from '../../_actions';
import Loading from "../../components/Loader/Loader"
import Tooltip from '@mui/material/Tooltip';
const Question = () => {
  let dispatch = useDispatch()
  let selector = useSelector(state => state)

  //create
  let [openAddmodel, setopenAddmodel] = useState(false)
  const [formData, setFormData] = useState({});
  let [errorCreate, seterrorCreate] = useState({})
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    seterrorCreate({ ...errorCreate, [e.target.name]: "" })

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleValidationCreate()) {
      let data = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
      }
      dispatch(questionaction.getAllQuestion(formData, data))
      setFormData({})
      seterrorCreate({})
      setopenAddmodel(false)
    }

  };

  const handleValidationCreate = () => {

    let formIsValid = true;
    let errors = {};

    if (!formData.fullName || formData.fullName === "") {
      formIsValid = false;
      errors.fullName = "Name cannot be empty";
    }

    if (!formData.email) {
      formIsValid = false;
      errors.email = "Email cannot be empty";
    }
    if (!formData.mobNo) {
      formIsValid = false;
      errors.mobNo = "Mobile Number cannot be empty";
    }
    if (!formData.dob) {
      formIsValid = false;
      errors.dob = "Date of Birth cannot be empty";
    }
    if (!formData.gender) {
      formIsValid = false;
      errors.gender = "Gender  cannot be empty";
    }

    if (!formData.email) {
      formIsValid = false;
      errors.email = "Email  cannot be empty";
    }

    if (!formData.grade) {
      formIsValid = false;
      errors.grade = "Grade  cannot be empty";
    }
    seterrorCreate(errors);
    return formIsValid;
  };

  // updatemodel
  let [openAddmodelUpdate, setopenAddmodelUpdate] = useState(false)
  let [updatedata, setupdatedata] = useState({})
  let [updateError, setupdateError] = useState({})
  let handleChangeUpdate = (e) => {
    setupdatedata({
      ...updatedata,
      [e.target.name]: e.target.value
    });
    setupdateError({ ...errorCreate, [e.target.name]: "" })
  }

  let handleSubmitUpdate = (e) => {
    e.preventDefault();
    if (handleValidationUpdate()) {


      const studentData = {
        "id": updatedata._id,
        "fullName": updatedata.fullName,
        "email": updatedata.email,
        "mobNo": updatedata.mobNo,
        "dob": updatedata.dob,
        "gender": updatedata.gender,
        "grade": updatedata.grade,
        board: updatedata.board,
        medium: updatedata.medium,
        motherTongue: updatedata.motherTongue,
        parentEducation: updatedata.parentEducation,
        parentIncome: updatedata.parentIncome,
        schoolName: updatedata.schoolName,
      };
      let temp = {
        "keyWord": "",
        "pageNo": 1,
        "sortBy": "slug",
        "sortOrder": "asc",
        "fromDate": "",
        "toDate": "",
        "size": 10
      }
      dispatch(questionaction.getAllQuestion(studentData, temp))
      setupdatedata({})
      setopenAddmodelUpdate(false)
    }

  }

  const handleValidationUpdate = () => {
    let formIsValid = true;
    let errors = {};

    if (!updatedata.fullName || updatedata.fullName === "") {
      formIsValid = false;
      errors.fullName = "Name cannot be empty";
    }

    if (!updatedata.email) {
      formIsValid = false;
      errors.email = "Email cannot be empty";
    }
    if (!updatedata.mobNo) {
      formIsValid = false;
      errors.mobNo = "Mobile Number cannot be empty";
    }
    if (!updatedata.dob) {
      formIsValid = false;
      errors.dob = "Date of Birth cannot be empty";
    }
    if (!updatedata.gender) {
      formIsValid = false;
      errors.gender = "Gender  cannot be empty";
    }

    if (!updatedata.grade) {
      formIsValid = false;
      errors.grade = "Grade  cannot be empty";
    }

    setupdateError(errors);
    return formIsValid;
  };
  // updatemodel


  //--------Pagination-------------------------
  
  //--------Pagination-------------------------



  let [filter, setFilter] = useState("1")
  let [specialFilter, setspecialFilter] = useState(true)

  let handleSpecialChange = (e) => {
    const value = e.target.value === 'true';
    setspecialFilter(value)
  }

  let handleQuestionChange = (e) => {
    if (e.target.value === "-1") {
      setspecialFilter(true)
      setFilter(e.target.value)
    } else {

      setFilter(e.target.value)
    }
  }
  useEffect(() => {
    dispatch(questionaction.getQuestionBySet({ "setId": filter }))
  }, [filter, specialFilter])
  //search
  //Delete
  let handleDelete = (data) => {
    let datatemp = {
      "questionId": data._id,
    }
    let paginationdata = {
      "setId": filter
    }
    confirmAlert({
      title: ' Delete ',
      message: `Are you sure want to Delete ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(questionaction.deleteQuestion(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }


  let { QuestionsManagement } = selector || {}
  let { loading } = QuestionsManagement && QuestionsManagement ? QuestionsManagement : {}

  let { data } = QuestionsManagement && QuestionsManagement.questionList ? QuestionsManagement.questionList : {}

  let { special, nonSpecial } = data && data ? data : {}
  return (
    <>
      <Loading loading={loading} />
      <div className=' h-[90vh] w-full overflow-y-scroll  py-3 md:px-5 px-1  '>
        <div className=' w-full h-full  max-w-[100vw] '>
          <div className='flex justify-between md:flex-row flex-col gap-2 p-5'>
            <div className=' font-semibold text-2xl'>{QuestionJSON.Heading}</div>
            <Link to={"/app/question/add"}>
              <Button data={"Add Question"} />
            </Link>
          </div>
          <div className='md:py-10 md:px-10 '>
            <div className=''>
              {/* search and filter area */}
              <div className='flex  items-start gap-4 text-[#7E84A3]'>



                <div className=' max-w-lg bg-white h-[40px] flex items-center px-2 border border-[#D9E1EC] rounded gap-2'>
                  <label htmlFor="set"></label>
                  <select id="set" value={filter} onChange={handleQuestionChange} className='bg-white p-1 outline-none'>

                    <option value="-1">Splash</option>
                    <option value="1">Set-1</option>
                    <option value="2">Set-2</option>
                    <option value="3">Set-3</option>
                  </select>
                </div>

                <div className=' max-w-lg bg-white h-[40px] flex items-center px-2 border border-[#D9E1EC] rounded gap-2'>
                  <label htmlFor="set"></label>
                  <select id="set" value={specialFilter} onChange={handleSpecialChange} className='bg-white p-1 outline-none'>

                    {filter !== "-1" && <option value="false">Special</option>}
                    <option value="true">Normal</option>

                  </select>
                </div>



              </div>

              {/*Table  */}
              <div className='  '>
                <div className="relative overflow-y-scroll   shadow-md sm:rounded-lg mt-5 ">
                  <table className=" w-full text-sm text-left rtl:text-right  text-gray-500 divide-y-4">
                    <thead className="text-[14px] text-[#5A607F] w-full    bg-white capitalize  ">
                      <tr className=' w-full'>

                        <th className="px-6 whitespace-nowrap font-bold  py-3">
                          Q.No
                        </th>

                        <th className="px-6 py-3 font-bold ">
                          Question
                        </th>

                        <th className="px-6 whitespace-nowrap py-3 font-bold ">
                          Image
                        </th>

                        <th className="px-6 whitespace-nowrap py-3 font-bold ">
                          Answer Option
                        </th>

                        <th className=" whitespace-nowrap px-6 py-3 font-bold ">
                          Multi Language
                        </th>
                        <th className="px-6 py-3 font-bold ">
                          Set
                        </th>
                        <th className="px-6 py-3 font-bold ">
                          Audio
                        </th>
                        <th className="px-6 py-3 font-bold ">
                          Action
                        </th>
                      </tr>
                    </thead>


                    {specialFilter ?
                      nonSpecial && nonSpecial.length > 0 && nonSpecial.map((e, i) => {

                        return (
                          <tbody key={i} className=''>
                            <tr className='bg-white'>
                              <td className="px-6 py-3 font-normal ">
                                {i + 1 || "0"}
                              </td>

                              <td className="px-6 whitespace-nowrap font-normal   py-3">
                                <div className="flex gap-2 items-center w-32 space-x-6 whitespace-nowrap">
                                  <Tooltip title={e?.question?.en || "--"} arrow>


                                    <div className=' w-32 h-10  overflow-hidden text-nowrap text-ellipsis flex justify-start items-center'>

                                      {e?.question?.en || "--"}
                                    </div>
                                  </Tooltip>
                                </div>
                              </td>

                              <td className="px-6 py-3 font-normal ">
                                <img className=' h-10 w-10' src={e?.image?.en || '/icons8-system-administrator-female-100.png'} onError={(e) => e.target.src = "/icons8-system-administrator-female-100.png"} alt="img" />
                              </td>

                              <td className="px-6 whitespace-nowrap py-3 font-normal ">
                                YES | NO | Sometime
                              </td>

                              <td className="px-6 py-3 font-normal ">
                                {e?.isMultiLanguage || "--"}
                              </td>

                              <td className="px-6 py-3 font-normal ">
                                {e?.setId}
                              </td>
                              <td className="px-6 whitespace-nowrap py-3 font-normal ">
                                YES
                              </td>

                              <td className="px-6 py-3 flex flex-wrap items-center justify-center lg:justify-start lg:gap-3 font-normal">
                                <Link title='Edit' to={`/app/question/add/${e._id}`}>
                                  <img className="cursor-pointer max-w-full lg:h-10 h-10" src='/icons/create.svg' alt="Edit" />
                                </Link>
                                <img title='Delete' onClick={() => handleDelete(e)} className="cursor-pointer max-w-full lg:h-10 h-10 mt-2 lg:mt-0" src='/icons/delete.svg' alt="Delete" />
                              </td>




                            </tr>
                          </tbody>
                        )
                      })
                      : special && special.length > 0 && special.map((e, i) => {

                        return (
                          <tbody key={i} className=''>
                            <tr className='bg-white'>
                              <td className="px-6 py-3 font-normal ">
                                {i + 1 || "0"}
                              </td>

                              <td className="px-6 whitespace-nowrap font-normal   py-3">
                                <div className="flex gap-2 items-center w-32 space-x-6 whitespace-nowrap">
                                  <Tooltip title={e?.question?.en || "--"} arrow>


                                    <div className=' w-32 h-10  overflow-hidden text-nowrap text-ellipsis flex justify-start items-center'>

                                      {e?.question?.en || "--"}
                                    </div>
                                  </Tooltip>
                                </div>
                              </td>

                              <td className="px-6 py-3 font-normal ">
                                <img className=' h-10 w-10' src={e?.image?.en || '/icons8-system-administrator-female-100.png'} onError={(e) => e.target.src = "/icons8-system-administrator-female-100.png"} alt="img" />
                              </td>

                              <td className="px-6 whitespace-nowrap py-3 font-normal ">
                                YES | NO | Sometime
                              </td>

                              <td className="px-6 py-3 font-normal ">
                                {e?.isMultiLanguage || "--"}
                              </td>

                              <td className="px-6 py-3 font-normal ">
                                {e?.setId}
                              </td>
                              <td className="px-6 whitespace-nowrap py-3 font-normal ">
                                YES
                              </td>

                              <td className="px-6 py-3 flex flex-wrap items-center justify-center lg:justify-start lg:gap-3 font-normal">
                                <Link title='Edit' to={`/app/question/add/${e._id}`}>
                                  <img className="cursor-pointer max-w-full lg:h-10 h-10" src='/icons/create.svg' alt="Edit" />
                                </Link>
                                <img title='Delete' onClick={() => handleDelete(e)} className="cursor-pointer max-w-full lg:h-10 h-10 mt-2 lg:mt-0" src='/icons/delete.svg' alt="Delete" />
                              </td>






                            </tr>
                          </tbody>
                        )
                      })
                    }
                    {

                    }

                  </table>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Model formData={formData} setFormData={setFormData} seterrorCreate={seterrorCreate} errorCreate={errorCreate} handleChange={handleChange} handleSubmit={handleSubmit} openAddmodel={openAddmodel} setopenAddmodel={setopenAddmodel} />
      <Modelupdate updatedata={updatedata} setupdatedata={setupdatedata} handleSubmitUpdate={handleSubmitUpdate} setupdateError={setupdateError} updateError={updateError} handleChangeUpdate={handleChangeUpdate} openAddmodelUpdate={openAddmodelUpdate} setopenAddmodelUpdate={setopenAddmodelUpdate} />
    </>
  );
};

export default Question;
