import { alertActions} from './alert.actions';
import { APIcallFunction, headerForPrivateAPI, } from '../_helpers';
import { serviceConstants } from '../_constants';



export const serviceAction = {

    createNewService,
    getServiceList,
    getAllService,
    deleteService,
    updateService
};
function getAllService(data,Pagination) {
    const credentials = {
        header : headerForPrivateAPI(),
        method : "POST",
        body : data ?? {},
        endPoint : 'getAllService'
    }

    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: serviceConstants.GET_SERVICE_LIST_REQUEST, user } }
    function success(user) { return { type: serviceConstants.GET_SERVICE_LIST_SUCCESS, user } }
    function failure(error) { return { type: serviceConstants.GET_SERVICE_LIST_FAILURE, error } }
}




function updateService(data,Pagination) {
    const credentials = {
        header : headerForPrivateAPI(),
        method : "POST",
        body : data ?? {},
        endPoint : 'updateService'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success("Service Updated "))
                    dispatch(serviceAction.getAllService(Pagination))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: serviceConstants.CREATE_SERVICE_REQUEST, user } }
    function success(user) { return { type: serviceConstants.CREATE_SERVICE_SUCCESS, user } }
    function failure(error) { return { type: serviceConstants.CREATE_SERVICE_FAILURE, error } }
}




function deleteService(data,Pagination) {
    const credentials = {
        header : headerForPrivateAPI(),
        method : "POST",
        body : data ?? {},
        endPoint : 'deleteService'
    }

    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success("Service Deleted"));
                    dispatch(serviceAction.getAllService(Pagination))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: serviceConstants.DELETE_SERVICE_REQUEST, user } }
    function success(user) { return { type: serviceConstants.DELETE_SERVICE_SUCCESS, user } }
    function failure(error) { return { type: serviceConstants.DELETE_SERVICE_FAILURE, error } }
}




function createNewService(data,Pagination) {
    const credentials = {
        header : headerForPrivateAPI(),
        method : "POST",
        body : data ?? {},
        endPoint : 'createNewService'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success("Service Created "))
                    dispatch(serviceAction.getAllService(Pagination))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: serviceConstants.CREATE_SERVICE_REQUEST, user } }
    function success(user) { return { type: serviceConstants.CREATE_SERVICE_SUCCESS, user } }
    function failure(error) { return { type: serviceConstants.CREATE_SERVICE_FAILURE, error } }
}



function getServiceList(data,Pagination) {
    const credentials = {
        header : headerForPrivateAPI(),
        method : "POST",
        body : data ?? {},
        endPoint : 'getServiceList'
    }

    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: serviceConstants.GET_SERVICE_REQUEST, user } }
    function success(user) { return { type: serviceConstants.GET_SERVICE_SUCCESS, user } }
    function failure(error) { return { type: serviceConstants.GET_SERVICE_FAILURE, error } }
}

