/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Button from '../../components/Button';
import LanguageManagementJSON from './LanguageManagement.json'
import SearchIcon from '@mui/icons-material/Search';
import Model from "./Modle/Model"
import { useDispatch, useSelector } from 'react-redux';
import { languageAction } from '../../_actions/language.action';
import Modelupdate from './Modle/Modelupdate';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactPaginate from 'react-paginate';
import { RiEditLine } from "react-icons/ri";
import Loading from "../../components/Loader/Loader"

const LanguageManagement = () => {
  let selector = useSelector(state => state)
  let dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(0);

  let [openAddmodel, setopenAddmodel] = useState(false)
  const [formData, setFormData] = useState({});
  let [errorCreate, seterrorCreate] = useState({})
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    seterrorCreate({ ...errorCreate, [e.target.name]: "" })

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleValidationCreate()) {
      dispatch(languageAction.createLanguage(formData))
      setFormData({})
      setopenAddmodel(false)
      seterrorCreate()
    }

  };

  const handleValidationCreate = () => {
    let formIsValid = true;
    let errors = {};

    if (!formData.name) {
      formIsValid = false;
      errors.name = "Language can't be empty";
    }

    if (!formData.scriptName) {
      formIsValid = false;
      errors.scriptName = "ScriptName can't be empty";
    }
    if (!formData.shortName) {
      formIsValid = false;
      errors.shortName = "ShortName can't be empty";
    }

    seterrorCreate(errors);
    return formIsValid;
  };

  // updatemodel
  let [openAddmodelUpdate, setopenAddmodelUpdate] = useState(false)
  let [updatedata, setupdatedata] = useState({})
  let [updateError, setupdateError] = useState({})
  let handleUpdatemodel = (data) => {
    setopenAddmodelUpdate(true)
    setupdatedata({ ...data })
  }
  let handleChangeUpdate = (e) => {
    setupdatedata({
      ...updatedata,
      [e.target.name]: e.target.value
    });
    setupdateError({ ...errorCreate, [e.target.name]: "" })
  }

  let handleSubmitUpdate = (e) => {
    console.log(updatedata)
    e.preventDefault();
    if (handleValidationUpdate()) {
      let obj = {
        "id": updatedata._id,
        "name": updatedata.name,
        "shortName": updatedata.shortName,
        "scriptName": updatedata.scriptName
      }
      let temp = {
        "keyWord": "",
        "pageNo": currentPage + 1,
        "sortBy": "slug",
        "sortOrder": "asc",
        "fromDate": "",
        "toDate": "",
        "size": 10
      }
      dispatch(languageAction.updateLanguage(obj, temp))
      setopenAddmodelUpdate(false)
    }

  }

  let handleCloseAddModel=()=>{
    setFormData({})
    setopenAddmodel(false)
    seterrorCreate({})
  }

  const handleValidationUpdate = () => {
    let formIsValid = true;
    let errors = {};

    if (!updatedata.name) {
      formIsValid = false;
      errors.name = "Language can't be empty";
    }

    if (!updatedata.scriptName) {
      formIsValid = false;
      errors.scriptName = "ScriptName can't be empty";
    }
    if (!updatedata.shortName) {
      formIsValid = false;
      errors.shortName = "ShortName can't be empty";
    }

    setupdateError(errors);
    return formIsValid;
  };
  //Delete

  //Search
  let [searchKeyword, setsearchKeyword] = useState("")
  let handleSearch = (e) => {
    setsearchKeyword(e.target.value)
  }
  useEffect(() => {
    let temp = {
      "keyWord": searchKeyword,
      "pageNo": 1,
      "sortBy": "slug",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": 10
    }
    dispatch(languageAction.getLanguageList(temp))
  }, [searchKeyword])


  //--------Pagination-------------------------
  const size = 10
  const isMobile = true
  //--------Pagination-----
  const handlePageClick = (data) => {

    setCurrentPage(data.selected)

    let datas = {
      "keyWord": "",
      "pageNo": data.selected + 1,
      "size": 10,
      "sortBy": "slug",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
    }
    dispatch(languageAction.getLanguageList(datas))
  };
  //--------Pagination-------------------------

  let handleDisabled = (e) => {

    let temp = {
      "keyWord": "",
      "pageNo": currentPage + 1,
      "sortBy": "slug",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": 10
    }

    let obj = {
      "id": e._id,
    }

    dispatch(languageAction.deleteLanguage(obj, temp))

  }


  let { language } = selector || {}
  let { loading } = language && language ? language : {}
  let { list, total } = language && language?.getlanguageSuccess ? language && language?.getlanguageSuccess : {}

  return (

    <>


      <Loading loading={loading} />

      <div className=' h-[90vh] w-full overflow-y-scroll  py-3 md:px-5 px-1  '>
        <div className=' w-full h-full  max-w-[100vw] '>
          <div className='flex justify-between md:flex-row flex-col gap-2 p-5'>
            <div className=' font-semibold text-2xl'>{LanguageManagementJSON.Heading}</div>
            <div onClick={() => setopenAddmodel(true)}>
              <Button data={"Add Language"} />
            </div>
          </div>
          <div className='md:py-10 md:px-10 '>
            <div className=''>
              {/* search and filter area */}
              <div className='flex flex-col items-start gap-4 text-[#7E84A3]'>
                <div className=' max-w-lg bg-white h-[40px] flex items-center border border-[#D9E1EC] rounded gap-2'>
                  <SearchIcon className='w-6 h-6 text-gray-400 ml-2' />
                  <input
                    className='flex-grow h-full outline-none text-sm'
                    onChange={(e) => handleSearch(e)}
                    value={searchKeyword}
                    type='text'
                    placeholder='Search '
                  />
                </div>
              </div>

              <div className='  '>
                {/* table--------------------------------------------------------------------------------------- */}
                <div className="relative overflow-x-scroll w-full  shadow-md sm:rounded-lg mt-5 ">
                  <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 divide-y-4">

                    <thead className="text-[14px] text-[#5A607F]    bg-white capitalize  ">
                      <tr>

                        <th className="px-6 whitespace-nowrap  font-bold  py-2">
                          Language
                        </th>

                        <th className="px-6 py-2  font-bold ">

                        </th>

                      </tr>
                    </thead>
                    {list && total > 0 && list.map((e, i) => (


                      <tbody key={i} className=' divide-y-4'>
                        <tr className="bg-white border-b   hover:bg-gray-50  ">
                          <td className="w-4  px-4   py-2 ">
                            <div className="flex gap-2 items-center space-x-6 whitespace-nowrap">
                              <input

                                id="checkbox-table-search-1"
                                type="checkbox"
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500   focus:ring-2 "
                              />
                              <label htmlFor="checkbox-table-search-1" className="sr-only">
                                checkbox
                              </label>
                              {e?.name || ""} <div> ( {e?.scriptName || ""} ) </div>
                            </div>
                          </td>

                          <td className="flex items-center justify-end px-3 py-2  gap-3 ">
                            {e && e?.isDisabled ? <label onClick={() => handleDisabled(e)} class="flex items-center cursor-pointer targetablepx-4 tooltip">
                              <div class="relative" title='Disabled' >
                                <div class="red  shadow-md border border-gray-300  w-10 h-6 rounded-full"></div>
                                <div class="dot absolute right-1 top-1 bg-red-400 w-4 h-4 rounded-full transition"></div>
                              </div>
                            </label> :

                              <label onClick={() => handleDisabled(e)} class="flex items-center cursor-pointer targetablepx-4 tooltip">
                                <div class="relative" title='Enabled' >
                                  <div class="red  shadow-md border border-gray-300  w-10 h-6 rounded-full"></div>
                                  <div class="dot absolute left-1 top-1 bg-blue-400 w-4 h-4 rounded-full transition"></div>
                                </div>
                              </label>
                            }



                            <div onClick={() => handleUpdatemodel(e)} className='cursor-pointer shadow-md border p-1 '><RiEditLine className=' text-blue-600 text-2xl' /></div>

                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </table>

                </div>
                {/* table--------------------------------------------------------------------------------------- */}
              </div>
              {isMobile ? (
                list && total > 10 ? (
                  <ReactPaginate
                    previousLabel={'Prev'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={Math.ceil(total / size)}
                    marginPagesDisplayed={0}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    pageClassName={'page-cls'}
                    activeClassName={'active'}
                    forcePage={currentPage}
                  />
                ) : null
              ) : (
                list && total > 10 ? (
                  <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={Math.ceil(total / size)}
                    marginPagesDisplayed={3}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    pageClassName={'page-cls'}
                    activeClassName={'active'}
                  />
                ) : null
              )}
            </div>
          </div>
        </div>
      </div>
      <Model formData={formData} setFormData={setFormData} errorCreate={errorCreate} handleChange={handleChange} handleSubmit={handleSubmit} openAddmodel={openAddmodel} setopenAddmodel={setopenAddmodel} handleClose={handleCloseAddModel} />
      <Modelupdate updatedata={updatedata} handleSubmitUpdate={handleSubmitUpdate} updateError={updateError} handleChangeUpdate={handleChangeUpdate} openAddmodelUpdate={openAddmodelUpdate} setopenAddmodelUpdate={setopenAddmodelUpdate} />

    </>
  );
};


export default LanguageManagement;
