/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import ReportSingleJSON from './ReportSingle.json'
import AddQuestionModel from './Modle/AddQuestionModel'
import { languageAction, reportAction } from '../../_actions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Loader from '../../components/Loader/Loader';



const ReportSingle = () => {

  let dispatch = useDispatch()
  let selector = useSelector(state => state)
  let params = useParams()


  useEffect(() => {
    let temp = {
      "keyWord": "",
      "pageNo": 1,
      "sortBy": "slug",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": 150
    }
    params.id && dispatch(reportAction.getSkillAnalysesById({ id: params.id }))
    dispatch(languageAction.getLanguageList(temp))
  }, [params])


  let { ReportManagementReducer } = selector && selector ? selector : {}
  let { getSkillbyId, loading } = ReportManagementReducer && ReportManagementReducer ? ReportManagementReducer : {}

  return (
    <>
      <Loader loading={loading} />
      <div className=' h-[90vh] w-full  overflow-y-scroll  py-3 px-5  '>
        <div className=' w-full h-full max-w-[90vw] '>
          <div className=' flex justify-between p-5'>
            <div className=' font-semibold text-2xl'>{ReportSingleJSON.Heading}</div>
          </div>
          <div className='p-10'>
            <div className=''>
              <div className="">
                <AddQuestionModel getSkillbyId={getSkillbyId} />
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default ReportSingle;
