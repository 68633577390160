import React from 'react'
import AddIcon from '@mui/icons-material/Add';

export default function Button({data}) {
  return (

    <button className=' w-[147px] h-[40px] bg-[#1E5EFF] flex justify-center items-center text-white gap-1 rounded-md'><AddIcon className=''/>{data}</button>
    
  )
}
