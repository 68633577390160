/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import HelpJSON from './Help.json'
import SearchIcon from '@mui/icons-material/Search';
import Model from "./Modle/Model"
import { useDispatch, useSelector } from 'react-redux';
import { languageAction } from '../../_actions/language.action';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactPaginate from 'react-paginate';
import { TbMessage } from "react-icons/tb";
import Tooltip from '@mui/material/Tooltip';

const Help = () => {
  let selector = useSelector(state => state)
  let dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(0);
  let [isLoading, setIsLoading] = useState(false)
  let [openAddmodel, setopenAddmodel] = useState(false)
  const [formData, setFormData] = useState({});
  let [errorCreate, seterrorCreate] = useState({})
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    seterrorCreate({ ...errorCreate, [e.target.name]: "" })

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let obj = { "ticketId": formData && formData?.ticketId }
    if (handleValidationCreate()) {

      dispatch(languageAction.replyByAdminForTicket(formData, obj))
      setFormData({ ...formData, msg: '' })

    }

  };

  const handleValidationCreate = () => {
    let formIsValid = true;
    let errors = {};

    if (!formData.msg) {
      formIsValid = false;
      errors.msg = "Cannot be empty";
    }

    seterrorCreate(errors);
    return formIsValid;
  };




  //Search
  let [searchKeyword, setsearchKeyword] = useState("")
  let handleSearch = (e) => {
    setsearchKeyword(e.target.value)
  }
  useEffect(() => {
    let temp = {
      "keyWord": searchKeyword,
      "pageNo": 1,
      "sortBy": "slug",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": 10
    }
    dispatch(languageAction.getTicketList(temp))
  }, [])


  //--------Pagination-------------------------
  const [size, setSize] = useState(10);
  const [isMobile, setIsMobile] = useState(true);
  //--------Pagination-------------------------
  let [offset, setoffset] = useState(0)
  const handlePageClick = (data) => {
    setoffset(Math.ceil(data.selected * 10))
    setCurrentPage(data.selected)
    let datas = {
      "keyWord": "",
      "pageNo": data.selected + 1,
      "size": 10,
      "sortBy": "slug",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
    }
    dispatch(languageAction.getTicketList(datas))
  };
  //--------Pagination-------------------------


  let openChatModel = (e) => {
    let obj = {
      ticketId: e?.id || "",

    }

    setFormData(obj)

    setopenAddmodel(true)
  }

  let ChangeStatus = (e) => {
    let obj = {
      "id": e.id
    }
    let temp = {
      "keyWord": "",
      "pageNo": currentPage + 1,
      "sortBy": "",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": 10
    }
    console.log(obj)
    confirmAlert({
      title: 'Resolve Ticket ',
      message: `Are you sure want to resolve this ticket ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(languageAction.resolvedTicket(obj, temp))
        },
        {
          label: 'No'
        }
      ]
    });



  }

 



  let response = selector && selector.language && selector.language.getAllTicket && selector.language.getAllTicket.data

  useEffect(() => {
    audioRefs.current = audioRefs.current.slice(0, response?.list?.length || 0);
  }, [response]);
  const audioRefs = useRef([]);
  const handleAudioPlay = (index) => {
    audioRefs.current.forEach((audio, idx) => {
      if (audio && idx !== index) {
        audio.pause();
        audio.currentTime = 0;
      }
    });
  };

  return (

    <>


      {/* <Loading loading={loading}/>    */}

      <div className=' h-[90vh] w-full overflow-y-scroll  py-3 md:px-5 px-1  '>
        <div className=' w-full h-full max-w-[100vw]'>
          <div className=' flex justify-between p-5'>
            <div className=' font-semibold text-2xl'>{HelpJSON.Heading}</div>

          </div>
          <div className='md:py-0 md:px-10 '>
            <div className=''>
              {/* search and filter area */}
              <div className='flex flex-col items-start gap-4 text-[#7E84A3]'>
                <div className=' max-w-lg bg-white h-[40px] flex items-center border border-[#D9E1EC] rounded gap-2'>
                  <SearchIcon className='w-6 h-6 text-gray-400 ml-2' />
                  <input
                    className='flex-grow h-full outline-none text-sm'
                    onChange={(e) => handleSearch(e)}
                    value={searchKeyword}
                    type='text'
                    placeholder='Search By Ticket Number'
                  />
                </div>
              </div>



              <div className=' '>
                <div className="relative overflow-y-scroll   shadow-md sm:rounded-lg mt-5 ">
                  <table className=" w-full text-sm text-left rtl:text-right  text-gray-500 divide-y-4">

                    <thead className="text-[14px] text-[#5A607F] w-full    bg-white capitalize  ">
                      <tr>
                        <th className="px-6 whitespace-nowrap font-bold py-3 w-1/6 ">
                          Name & Mobile Number
                        </th>
                        <th className="px-6 py-1  w-1/6 font-bold">
                          Title
                        </th>
                        <th className="px-6 py-1  w-1/6 font-bold">
                          Reply
                        </th>
                        <th className="px-6 py-1  w-1/6 whitespace-nowrap font-bold ">
                          Ticket Number
                        </th>
                        <th className="px-6 py-1  w-1/6 whitespace-nowrap font-bold ">
                          Date/Time
                        </th>

                        <th className="px-6 py-1  w-1/6 font-bold">
                          Action
                        </th>
                      </tr>
                    </thead>


                    {response && response.list && response.total > 0 && Array.isArray(response.list) && response.list.length > 0 && response.list.map((e, i) => (
                      <tbody key={i} className=' divide-y-4'>
                        <tr className="bg-white w-full  border-b  hover:bg-gray-50  ">
                          <td className="w-4  px-4  ">

                            <div className="flex flex-col items-start justify-start ">
                              <div>

                                <Tooltip title={e?.userId?.name || ""} arrow>
                                  <div className=' w-40' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                    {e?.userId?.fullName || ""}

                                  </div>
                                </Tooltip>
                              </div>
                              <div>


                                <Tooltip title={e?.userId?.mobNo || ""} arrow>
                                  <div className=' w-40' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                    {e?.userId?.mobNo || ""}

                                  </div>
                                </Tooltip>

                              </div>
                            </div>
                          </td>


                          <td className="w-4  px-4  ">

                            <div className="flex gap-2 items-center space-x-6 whitespace-nowrap">

                              {e && e.audio ? <audio className=" flex justify-start " 

                              ref={el => audioRefs.current[i] = el}
                              onPlay={() => handleAudioPlay(i)}

                              controls>
                                <source src={e && e.audio} type="audio/mpeg" />
                                Your browser does not support the audio element.
                              </audio> : <Tooltip title={e && e.msg ? e.msg : "--"} arrow>
                                <div className=' w-40' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                  {e && e.msg ? e.msg : "--"}
                                </div>
                              </Tooltip>}

                            </div>

                          </td>

                          <td className="w-4  px-4  ">
                            <div className="flex gap-2 items-center text-4xl cursor-pointer space-x-6 whitespace-nowrap">
                              <div title='Reply' onClick={() => openChatModel(e)}>
                                <TbMessage />
                              </div>
                            </div>
                          </td>


                          <td className="w-4  px-4  ">
                            <div className="flex gap-2 items-center space-x-6 whitespace-nowrap">
                              {e?.ticketNo || "--"}
                            </div>
                          </td>
                          <td className="w-4  px-4  ">
                            <div className="flex gap-2 items-center space-x-6 whitespace-nowrap">

                              <Tooltip title={e?.createdAt ? new Date(e.createdAt).toLocaleString() : "--"} arrow>
                                <div className=' w-40' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                  {e?.createdAt ? new Date(e.createdAt).toLocaleString() : "--"}

                                </div>
                              </Tooltip>
                            </div>
                          </td>


                          <td className="flex   px-6 py-2 gap-3 ">
                            {e?.isResolve ?
                              <div className=' bg-green-500 p-3 rounded-full cursor-pointer font-bold text-white'>Resolved</div> :
                              <div onClick={() => ChangeStatus(e)} className=' bg-yellow-500 p-3 rounded-full cursor-pointer font-bold text-white'>Pending</div>}
                            {/* <div className=' bg-red-500 p-3 rounded-full font-bold text-white'>Rejected</div> */}

                          </td>

                        </tr>
                      </tbody>
                    ))}
                  </table>

                </div>
                {/* table--------------------------------------------------------------------------------------- */}
              </div>
            </div>
            {isMobile ? (
              response && response.list && Array.isArray(response.list) && response.list.length > 0 && response.total > 10 ? (
                <ReactPaginate
                  previousLabel={'Prev'}
                  nextLabel={'Next'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={Math.ceil(response.total / size)}
                  marginPagesDisplayed={0}
                  pageRangeDisplayed={2}
                  onPageChange={handlePageClick}
                  containerClassName={'pagination'}
                  pageClassName={'page-cls'}
                  activeClassName={'active'}
                  forcePage={currentPage}
                />
              ) : null
            ) : (
              response && response.list && Array.isArray(response.list) && response.list.length > 0 && response.total > 10 ? (
                <ReactPaginate
                  previousLabel={'Previous'}
                  nextLabel={'Next'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={Math.ceil(response.total / size)}
                  marginPagesDisplayed={3}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={'pagination'}
                  pageClassName={'page-cls'}
                  activeClassName={'active'}
                />
              ) : null
            )}
          </div>
        </div>
      </div>
      <Model isLoading={isLoading} setIsLoading={setIsLoading} formData={formData} setFormData={setFormData} errorCreate={errorCreate} handleChange={handleChange} handleSubmit={handleSubmit} openAddmodel={openAddmodel} setopenAddmodel={setopenAddmodel} />


    </>
  );
};


export default Help;
