/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import { FaPlusCircle } from "react-icons/fa";
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios'
import { authHeader } from '../../../_helpers';
export default function QuestionAddComponent({ setDataToSubmitImage, dataToSubmitAudio, setDataToSubmitAudio, singleQuestionData, dataToSubmitImage, dataToSubmit, id, setDataToSubmit, elem, setshortname, handleChange }) {
    let [open, setOpen] = useState(false)
    let [loaderImage, setLoaderImage] = useState(false)
    let [loaderAudio, setLoaderAudio] = useState(false)



    let handleChangeImage = async (e) => {
        let { name } = e.target;
        const formData = new FormData();
        formData.append('image', e.target.files[0]);
        try {
            setLoaderImage(true)
            const response = await axios.post(`https://orkid.jamsara.com/api/upload`, formData, {
                headers: {
                    'Authorization': authHeader().Authorization,
                    'Content-Type': 'multipart/form-data'
                }
            });
            const responseData = response.data;


            setLoaderImage(false)
            setDataToSubmitImage({ ...dataToSubmitImage, [name]: responseData?.imageURL });
            return responseData;
        } catch (error) {
            console.error('Error:', error);
            setLoaderImage(false)
            throw error;
        }
    };



    let handleChangeAudio = async (e) => {
        let { name } = e.target;
        const formData = new FormData();
        formData.append('image', e.target.files[0]);
        try {
            setLoaderAudio(true)
            const response = await axios.post(`https://orkid.jamsara.com/api/upload`, formData, {
                headers: {
                    'Authorization': authHeader().Authorization,
                    'Content-Type': 'multipart/form-data'
                }
            });
            const responseData = response.data;

            console.log(responseData?.imageURL);
            setDataToSubmitAudio({ ...dataToSubmitAudio, [name]: responseData?.imageURL });
            setLoaderAudio(false)
            return responseData;
        } catch (error) {
            console.error('Error:', error);
            setLoaderAudio(false)
            throw error;
        }
    };




    useEffect(() => {
        setshortname({ shortName: elem?.shortName })
    }, [open])


    let handleOpen = () => {
        setOpen(true);
    }
    const fileInputRef = useRef(null);

    const fileInputRefAudio = useRef(null);
    const handleLabelClickAudio = () => {
        fileInputRefAudio.current.click();
    };

    const handleLabelClick = () => {
        fileInputRef.current.click();
    };




    useEffect(() => {
        setDataToSubmit(prevDataToSubmit => ({
            ...prevDataToSubmit,
            [elem.shortName]: singleQuestionData?.data?.question?.[elem.shortName] || ""
        }));
        setDataToSubmitImage(prevDataToSubmit => ({
            ...prevDataToSubmit,
            [elem.shortName]: singleQuestionData?.data?.image?.[elem.shortName] || ""
        }));
        setDataToSubmitAudio(prevDataToSubmit => ({
            ...prevDataToSubmit,
            [elem.shortName]: singleQuestionData?.data?.audio?.[elem.shortName] || ""
        }));
    }, [singleQuestionData]);



    console.log(singleQuestionData)
    return (
        <div className=' mb-2'>
            <div  >
                {!open && <div className=' bg-[#C7C7C7] px-2 py-1 items-center mb-1 flex justify-between'>
                    <div>{elem && elem?.name ? elem?.name : ""}</div>
                    <FaPlus onClick={handleOpen} />
                </div>}


                {open && <div className='flex  flex-col items-center justify-center gap-2 relative  p-2 bg-[#EAEAEA]'>
                    <div className='flex gap-2 w-[90%] '>
                        <label className='w-[9%] hidden md:block' >Language</label>
                        <input disabled name='language' className='w-full px-2 py-1 rounded-md outline-none ' type='text' value={elem && elem?.name ? elem?.name : ""} />

                    </div>
                    <div className=' top-0 p-2 absolute right-0 cursor-pointer'> <FaMinus onClick={() => setOpen(false)} /></div>
                    <div className='flex flex-col md:flex-row gap-2 w-[90%]'>
                        <label className='w-[9%]' >Question</label>
                        <textarea
                            id={elem.shortName}
                            value={dataToSubmit && dataToSubmit[elem.shortName] ? dataToSubmit[elem.shortName] : ""}
                            name={elem.shortName}
                            onChange={handleChange}
                            rows={5}
                            className='resize-none w-full px-2 py-1 rounded-md'
                        />


                    </div>
                    <div className='flex flex-col md:flex-row gap-2 w-[90%]'>
                        <label className='w-[9%]' >Audio</label>
                        {!loaderAudio ? <div className=' relative'>
                            <label onClick={handleLabelClickAudio} className='w-[9%] absolute top-1' ><FaPlusCircle /></label>

                            {elem && elem.shortName && dataToSubmitAudio[elem.shortName] ? (
                                dataToSubmitAudio[elem.shortName].endsWith(".mp3") ? (
                                    <div className="w-full md:w-auto  overflow-x-scroll">
                                        <audio className="ml-3" controls>
                                            <source src={dataToSubmitAudio[elem.shortName]} type="audio/mpeg" />
                                            Your browser does not support the audio element.
                                        </audio>
                                    </div>
                                ) : (
                                    // Otherwise, assume it's an image and render an <img> tag
                                    <img className='h-16 w-16' onError={(e) => e.target.src = "/icons8-system-administrator-female-100.png"} src={dataToSubmitAudio[elem.shortName] || "/icons8-system-administrator-female-100.png"} alt="img" />
                                )
                            ) : null}


                            <input
                                ref={fileInputRefAudio}
                                id={elem.shortName}
                                name={elem.shortName}
                                value={dataToSubmit?.file || ""}
                                onChange={handleChangeAudio}
                                accept="audio/*"
                                className='w-full px-2 hidden py-1 rounded-md'
                                type='file' />
                        </div> : <div className=' flex justify-center items-center'><CircularProgress />Uploading....</div>}
                    </div>
                    <div className='flex flex-col md:flex-row gap-2 w-[90%]'>
                        <label className='w-[9%]' >Image</label>
                        {!loaderImage ? <div className=' relative'>
                            <label onClick={handleLabelClick} className='w-[9%] absolute top-1' ><FaPlusCircle /></label>
                            <img
                                className='h-16 w-16'
                                onError={(e) => e.target.src = "/icons8-system-administrator-female-100.png"}
                                src={dataToSubmitImage[elem?.shortName] || '/icons8-system-administrator-female-100.png'}
                                alt="img"
                            />
                            <input
                                ref={fileInputRef}
                                id={elem.shortName}
                                accept='image/*'
                                name={elem.shortName}
                                value={dataToSubmit?.file || ""} onChange={handleChangeImage} className=' w-full px-2  hidden py-1 rounded-md' type='file' />
                        </div> : <div className=' flex justify-center items-center'><CircularProgress />Uploading....</div>}
                    </div>
                </div>}

            </div>
        </div>
    )
}
