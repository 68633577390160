import { dashboardConstants } from '../_constants';
import { alertActions } from './alert.actions';
import { APIcallFunction, headerForPrivateAPI } from '../_helpers';

export const dashboardAction = {
    getDashboardData
};
function getDashboardData(data) {
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'getDashboardData'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: dashboardConstants.GET_DATA_REQUEST, user } }
    function success(user) { return { type: dashboardConstants.GET_DATA_SUCCESS, user } }
    function failure(error) { return { type: dashboardConstants.GET_DATA_FAILURE, error } }
}


