/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Button from '../../components/Button';
import IntroductionJSON from './Introduction.json'
import Model from './Modle/Model';
import Modelupdate from './Modle/Modelupdate';
import { useDispatch, useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import { IntroductionAction, languageAction } from '../../_actions';
import parse from 'html-react-parser';
import Tooltip from '@mui/material/Tooltip';
import { MdDeleteOutline } from "react-icons/md";
import { RiEditLine } from "react-icons/ri";

import Loading from "../../components/Loader/Loader"
const Introduction = () => {
  let dispatch = useDispatch()
  let selector = useSelector(state => state)

  //create
  let [openAddmodel, setopenAddmodel] = useState(false)
  const [formData, setFormData] = useState({});
  let [errorCreate, seterrorCreate] = useState({})

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    seterrorCreate({ ...errorCreate, [e.target.name]: "" })

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleValidationCreate()) {
      let datas = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
      }
      dispatch(IntroductionAction.getIntroductionList(formData, datas))
      setFormData({})
      seterrorCreate({})
      setopenAddmodel(false)
    }

  };

  const handleValidationCreate = () => {

    let formIsValid = true;
    let errors = {};

    if (!formData.fullName || formData.fullName === "") {
      formIsValid = false;
      errors.fullName = "Name cannot be empty";
    }

    if (!formData.email) {
      formIsValid = false;
      errors.email = "Email cannot be empty";
    }
    if (!formData.mobNo) {
      formIsValid = false;
      errors.mobNo = "Mobile Number cannot be empty";
    }
    if (!formData.dob) {
      formIsValid = false;
      errors.dob = "Date of Birth cannot be empty";
    }
    if (!formData.gender) {
      formIsValid = false;
      errors.gender = "Gender  cannot be empty";
    }

    if (!formData.email) {
      formIsValid = false;
      errors.email = "Email  cannot be empty";
    }

    if (!formData.grade) {
      formIsValid = false;
      errors.grade = "Grade  cannot be empty";
    }
    seterrorCreate(errors);
    return formIsValid;
  };


  // updatemodel
  let [openAddmodelUpdate, setopenAddmodelUpdate] = useState(false)
  let [updatedata, setupdatedata] = useState({})
  let [updateError, setupdateError] = useState({})

  let handleChangeUpdate = (e) => {

    setupdatedata({
      ...updatedata,
      [e.target.name]: e.target.value
    });
    setupdateError({ ...errorCreate, [e.target.name]: "" })

  }

  let handleSubmitUpdate = (e) => {
    e.preventDefault();
    if (handleValidationUpdate()) {


      const studentData = {
        "id": updatedata._id,
        "fullName": updatedata.fullName,
        "email": updatedata.email,
        "mobNo": updatedata.mobNo,
        "dob": updatedata.dob,
        "gender": updatedata.gender,
        "grade": updatedata.grade,
        board: updatedata.board,
        medium: updatedata.medium,
        motherTongue: updatedata.motherTongue,
        parentEducation: updatedata.parentEducation,
        parentIncome: updatedata.parentIncome,
        schoolName: updatedata.schoolName,
      };
      let temp = {
        "keyWord": "",
        "pageNo": 1,
        "sortBy": "slug",
        "sortOrder": "asc",
        "fromDate": "",
        "toDate": "",
        "size": 10
      }
      dispatch(IntroductionAction.getIntroductionList(studentData, temp))
      setupdatedata({})
      setopenAddmodelUpdate(false)
    }

  }

  const handleValidationUpdate = () => {
    let formIsValid = true;
    let errors = {};

    if (!updatedata.fullName || updatedata.fullName === "") {
      formIsValid = false;
      errors.fullName = "Name cannot be empty";
    }

    if (!updatedata.email) {
      formIsValid = false;
      errors.email = "Email cannot be empty";
    }
    if (!updatedata.mobNo) {
      formIsValid = false;
      errors.mobNo = "Mobile Number cannot be empty";
    }
    if (!updatedata.dob) {
      formIsValid = false;
      errors.dob = "Date of Birth cannot be empty";
    }
    if (!updatedata.gender) {
      formIsValid = false;
      errors.gender = "Gender  cannot be empty";
    }

    if (!updatedata.grade) {
      formIsValid = false;
      errors.grade = "Grade  cannot be empty";
    }

    setupdateError(errors);
    return formIsValid;
  };
  // updatemodel



  useEffect(() => {
    let temp = {
      "keyWord": "",
      "pageNo": 1,
      "sortBy": "slug",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": 10
    }
    dispatch(IntroductionAction.getIntroductionList(temp))
    let temps = {
      "keyWord": "",
      "pageNo": 1,
      "sortBy": "",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": 150
    }
    dispatch(languageAction.getLanguageList(temps))
  }, [])
  //search
  //Delete
  let handleDelete = (data) => {
    let datatemp = {
      "introductionId": data._id,
    }
    let paginationdata = {
      "keyWord": "",
      "pageNo": 1,
      "sortBy": "",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": 10
    }


    // console.log(datatemp)
    confirmAlert({
      title: ' Delete ',
      message: `Are you sure want to Delete ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(IntroductionAction.deleteIntroduction(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }


  let { IntroductionReducer } = selector || {}
  let { loading } = IntroductionReducer && IntroductionReducer ? IntroductionReducer : {}
  let { data } = IntroductionReducer && IntroductionReducer.getIntroLists ? IntroductionReducer.getIntroLists : {}
  let { list, total } = data && data ? data : {}

  let shortvalue = "en"


  return (
    <>
      <Loading loading={loading} />
      <div className=' h-[90vh] w-full overflow-y-scroll  py-3 md:px-5 px-1  '>
        <div className=' w-full h-full  max-w-[100vw] '>
          <div className='flex justify-between md:flex-row flex-col gap-2 p-5'>
            <div className=' font-semibold text-2xl'>{IntroductionJSON.Heading}</div>
            <Link to={"/app/introduction/add"}>
              <Button data={"Introduction"} />
            </Link>
          </div>
          <div className='md:py-10 md:px-10 '>
            <div className=''>
              {/* search and filter area */}
              <div className=' flex items-center gap-4 text-[#7E84A3] '>
              </div>


              {/*Table  */}
              <div className='  '>
                <div className="relative  overflow-y-scroll  max-h-[70vh] shadow-md sm:rounded-lg mt-5 ">
                  <table className="w-full text-sm text-left rtl:text-right  text-gray-100  divide-y-4">
                    <thead className="text-[14px] text-[#5A607F] w-full    bg-white capitalize  ">
                      <tr className=' w-full'>

                        <th className="px-6 whitespace-nowrap font-bold  py-3">
                          Introduction
                        </th>

                        <th className="px-6 py-3 font-bold ">
                          Content
                        </th>

                        <th className="px-6 whitespace-nowrap py-3 font-bold ">
                          Image
                        </th>

                        <th className="px-6 py-3 font-bold ">
                          Action
                        </th>


                      </tr>
                    </thead>


                    {
                      list && total > 0 && list.map((e, i) => {

                        return (
                          <tbody key={i} className=' text-gray-500'>
                            <tr className='bg-white'>
                              <td className="px-6 py-3 font-normal ">
                                {e.name?.[shortvalue] || "--"}
                                {/* <Tooltip title={e.name?.[shortvalue] || "--"} arrow>
                                  <div className=' w-36' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                  </div>
                                </Tooltip> */}

                              </td>

                              <td className="px-6 w-[50%] whitespace-nowrap overflow-ellipsis font-normal py-3">
                                <Tooltip title={parse(e.content?.[shortvalue] || "--")} arrow>
                                  <div className=' overflow-hidden  w-[60rem] h-10'>
                                    {parse(e.content?.[shortvalue] || "--")}
                                  </div>
                                </Tooltip>
                              </td>

                              <td className="px-6 py-3 font-normal ">
                                <img className=' h-10 w-10' src={e.image?.[shortvalue] || '/icons8-system-administrator-female-100.png'} onError={(e) => e.target.src = "/icons8-system-administrator-female-100.png"}
                                  alt='img'
                                />
                              </td>

                              <td className="flex items-center justify-start px-6 py-2 gap-3">
                                < Link title='Edit' to={`/app/introduction/${e._id}`} className='cursor-pointer shadow-md border p-1 '><RiEditLine className=' text-blue-600 text-2xl' /></Link>
                                <div onClick={() => handleDelete(e)} className=' cursor-pointer shadow-md border p-1 '><MdDeleteOutline className=' text-blue-600 text-2xl' /></div>
                              </td>



                            </tr>
                          </tbody>
                        )



                      })
                    }

                  </table>

                  {/* {isMobile ? (
                    data && total > 10 ? (
                      <ReactPaginate
                        previousLabel={'Prev'}
                        nextLabel={'Next'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={Math.ceil(total / size)}
                        marginPagesDisplayed={0}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination'}
                        pageClassName={'page-cls'}
                        activeClassName={'active'}
                      />
                    ) : null
                  ) : (
                    list && total > 10 ? (
                      <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={Math.ceil(total / size)}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination'}
                        pageClassName={'page-cls'}
                        activeClassName={'active'}
                      />
                    ) : null
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Model formData={formData} setFormData={setFormData} seterrorCreate={seterrorCreate} errorCreate={errorCreate} handleChange={handleChange} handleSubmit={handleSubmit} openAddmodel={openAddmodel} setopenAddmodel={setopenAddmodel} />
      <Modelupdate shortvalue={shortvalue} updatedata={updatedata} setupdatedata={setupdatedata} handleSubmitUpdate={handleSubmitUpdate} setupdateError={setupdateError} updateError={updateError} handleChangeUpdate={handleChangeUpdate} openAddmodelUpdate={openAddmodelUpdate} setopenAddmodelUpdate={setopenAddmodelUpdate} />
    </>
  );
};

export default Introduction;
