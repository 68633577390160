import { reportConstants } from "../_constants";

export default function ReportManagementReducer(state = {}, action) {
  switch (action.type) {

    
    
    
    
    case  reportConstants.DELETE_SKILL_ANALYSIS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case  reportConstants.DELETE_SKILL_ANALYSIS_SUCCESS:
      return {
        ...state,
        loading: false,
      
             
      };
    case  reportConstants.DELETE_SKILL_ANALYSIS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    


case  reportConstants.UPDATE_REPORT_REQUEST:
  return {
    ...state,
    loading: true
  };
case  reportConstants.UPDATE_REPORT_SUCCESS:
  return {
    ...state,
    loading: false,
  
         
  };
case  reportConstants.UPDATE_REPORT_FAILURE:
  return {
    ...state,
    loading: false,
    error: action.error
  };





case  reportConstants.GET_SKILL_BY_ID_REQUEST:
  return {
    ...state,
    loading: true
  };
case  reportConstants.GET_SKILL_BY_ID_SUCCESS:
  return {
    ...state,
    loading: false,
    getSkillbyId:action?.user?.data
         
  };
case  reportConstants.GET_SKILL_BY_ID_FAILURE:
  return {
    ...state,
    loading: false,
    error: action.error
  };



    
    
    case  reportConstants.CREATE_SKILL_ANALYSIS_REQUEST:
                return {
                  ...state,
                  loading: true
                };
              case  reportConstants.CREATE_SKILL_ANALYSIS_SUCCESS:
                return {
                  ...state,
                  loading: false,
                       
                };
              case  reportConstants.CREATE_SKILL_ANALYSIS_FAILURE:
                return {
                  ...state,
                  loading: false,
                  error: action.error
                };


              case  reportConstants.GET_ALL_SKILL_ANALYSIS_REQUEST:
                return {
                  ...state,
                  loading: true
                };
              case  reportConstants.GET_ALL_SKILL_ANALYSIS_SUCCESS:
                return {
                  ...state,
                  loading: false,
                  getallSkillsAnalysis:action?.user?.data             
                };
              case  reportConstants.GET_ALL_SKILL_ANALYSIS_FAILURE:
                return {
                  ...state,
                  loading: false,
                  error: action.error
                };

    default:
      return state
  }
}

