import { combineReducers } from 'redux';
import usersReducer from './users.reducer';
import alertReducer from './alert.reducer';
import languageReducer from './language.reducer';
import userManagement from './userManagement.reducer';
import studentManagement from './studentManagement.reducer'
import QuestionsManagement from './question.reducer'
import serviceReducer from "./service.reducer"
import dashboardReducer from './dashboard.reducer'
import ReportManagementReducer from './report.reducer'
import IntroductionReducer from "./introduction.reducer"


const rootReducer = combineReducers({
  users: usersReducer,
  alert: alertReducer,
  language:languageReducer,
  userManagement:userManagement,
  studentManagement:studentManagement,
  QuestionsManagement:QuestionsManagement,
  serviceReducer:serviceReducer,
  dashboardReducer:dashboardReducer,
  ReportManagementReducer:ReportManagementReducer,
  IntroductionReducer:IntroductionReducer

});

export default rootReducer;
