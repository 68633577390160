import { alertActions } from './alert.actions';
import { APIcallFunction, headerForPrivateAPI, APIcallFunctionForFile, headerForPrivateMediaAPI } from '../_helpers';
import { languageConstants } from '../_constants';

export const languageAction = {
    createLanguage,
    getLanguageList,
    updateLanguage,
    updateLanguageSetting,
    deleteLanguage,
    deleteLanguageSetting,
    createLanguageSetting,
    getAllLanguageSetting,
    getServiceById,
    getLoginHistoryList,
    createBoard,
    createNewSkillIndicator,
    getBoardList,
    updateBoard,
    updateSkillIndicator,
    deleteBoard,
    deleteSkillIndicator,
    getSkillIndicatorList,
    getAllSkillIndicator,
    getAllDoner,
    upload,






    getTicketList,
    getMsgListByTicketId,
    replyByAdminForTicket,
    resolvedTicket,



    getFirstScreenList,
    updateFirstScreen,



    sendNotificationForUser,
    getNotification,


    getLanguageSetting2List,
    getLanguageSetting2ById,
    updateLanguageSetting2,
    createLanguageSetting2




};


function createLanguageSetting2(data, temp) {


    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'createLanguageSetting2'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {

                    dispatch(success(user));
                    dispatch(alertActions.success("Created"));

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: languageConstants.CREATE_NEW_LANGUAGE_REQUEST, user } }
    function success(user) { return { type: languageConstants.CREATE_NEW_LANGUAGE_SUCCESS, user } }
    function failure(error) { return { type: languageConstants.CREATE_NEW_LANGUAGE_FAILURE, error } }
}
















function updateLanguageSetting2(data, temp) {


    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'updateLanguageSetting2'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {

                    dispatch(success(user));
                    dispatch(alertActions.success("Updated"));

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: languageConstants.UPDATE_NEW_LANGUAGE_SETTING_REQUEST, user } }
    function success(user) { return { type: languageConstants.UPDATE_NEW_LANGUAGE_SETTING_SUCCESS, user } }
    function failure(error) { return { type: languageConstants.UPDATE_NEW_LANGUAGE_SETTING_FAILURE, error } }
}










function getLanguageSetting2ById(data, temp) {


    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'getLanguageSetting2ById'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {

                    dispatch(success(user));
                    // dispatch(alertActions.success("Notification Send"));

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: languageConstants.GET_LANGUAGE_BY_ID_REQUEST, user } }
    function success(user) { return { type: languageConstants.GET_LANGUAGE_BY_ID_SUCCESS, user } }
    function failure(error) { return { type: languageConstants.GET_LANGUAGE_BY_ID_FAILURE, error } }
}















function getLanguageSetting2List(data, temp) {


    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'getLanguageSetting2List'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {

                    dispatch(success(user));
                    // dispatch(alertActions.success("Notification Send"));

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: languageConstants.GET_LANGUAGE_SETTING_2_REQUEST, user } }
    function success(user) { return { type: languageConstants.GET_LANGUAGE_SETTING_2_SUCCESS, user } }
    function failure(error) { return { type: languageConstants.GET_LANGUAGE_SETTING_2_FAILURE, error } }
}









function getNotification(data, temp) {


    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'getNotification'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {

                    dispatch(success(user));
                    // dispatch(alertActions.success("Notification Send"));

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: languageConstants.GET_NOTIFICATION_REQUEST, user } }
    function success(user) { return { type: languageConstants.GET_NOTIFICATION_SUCCESS, user } }
    function failure(error) { return { type: languageConstants.GET_NOTIFICATION_FAILURE, error } }
}









function sendNotificationForUser(data, paginationdata) {


    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'sendNotificationForUser'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {

                    dispatch(success(user));
                    dispatch(alertActions.success("Notification Send"));
                    dispatch(languageAction.getNotification(paginationdata))

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: languageConstants.NOTIFICATION_SEND_REQUEST, user } }
    function success(user) { return { type: languageConstants.NOTIFICATION_SEND_SUCCESS, user } }
    function failure(error) { return { type: languageConstants.NOTIFICATION_SEND_FAILURE, error } }
}







function updateFirstScreen(data, temp) {


    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'updateFirstScreen'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {

                    dispatch(success(user));
                    dispatch(alertActions.success("Updated"));
                    dispatch(languageAction.getFirstScreenList(temp))

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: languageConstants.UPDATE_FIRST_SCREEN_REQUEST, user } }
    function success(user) { return { type: languageConstants.UPDATE_FIRST_SCREEN_SUCCESS, user } }
    function failure(error) { return { type: languageConstants.UPDATE_FIRST_SCREEN_FAILURE, error } }
}





function getFirstScreenList(data) {


    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'getFirstScreenList'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {

                    dispatch(success(user));

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: languageConstants.GET_FIRST_SCREEN_REQUEST, user } }
    function success(user) { return { type: languageConstants.GET_FIRST_SCREEN_SUCCESS, user } }
    function failure(error) { return { type: languageConstants.GET_FIRST_SCREEN_FAILURE, error } }
}













function resolvedTicket(data, pagination) {


    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'resolvedTicket'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {

                    dispatch(success(user));

                    dispatch(languageAction.getTicketList(pagination))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: languageConstants.TICKET_RESOLVE_REQUEST, user } }
    function success(user) { return { type: languageConstants.TICKET_RESOLVE_SUCCESS, user } }
    function failure(error) { return { type: languageConstants.TICKET_RESOLVE_FAILURE, error } }
}













function replyByAdminForTicket(data, obj) {


    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'replyByAdminForTicket'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {

                    dispatch(success(user));
                    dispatch(languageAction.getMsgListByTicketId(obj))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: languageConstants.REPLY_BY_ADMIN_FOR_TICKET_REQUEST, user } }
    function success(user) { return { type: languageConstants.REPLY_BY_ADMIN_FOR_TICKET_SUCCESS, user } }
    function failure(error) { return { type: languageConstants.REPLY_BY_ADMIN_FOR_TICKET_FAILURE, error } }
}














function getMsgListByTicketId(data) {


    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'getMsgListByTicketId'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {

                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: languageConstants.GET_CHAT_BY_ID_REQUEST, user } }
    function success(user) { return { type: languageConstants.GET_CHAT_BY_ID_SUCCESS, user } }
    function failure(error) { return { type: languageConstants.GET_CHAT_BY_ID_FAILURE, error } }
}










function getTicketList(data) {


    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'getTicketList'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {

                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: languageConstants.GET_ALL_TICKET_REQUEST, user } }
    function success(user) { return { type: languageConstants.GET_ALL_TICKET_SUCCESS, user } }
    function failure(error) { return { type: languageConstants.GET_ALL_TICKET_FAILURE, error } }
}


function getAllDoner(data) {


    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'getAllDoner'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {

                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: languageConstants.GET_ALL_DONER_REQUEST, user } }
    function success(user) { return { type: languageConstants.GET_ALL_DONER_SUCCESS, user } }
    function failure(error) { return { type: languageConstants.GET_ALL_DONER_FAILURE, error } }
}


function getAllSkillIndicator(data) {


    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'getAllSkillIndicator'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {

                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: languageConstants.GET_SKILL_ALL_INDICATOR_REQUEST, user } }
    function success(user) { return { type: languageConstants.GET_SKILL_ALL_INDICATOR_SUCCESS, user } }
    function failure(error) { return { type: languageConstants.GET_SKILL_ALL_INDICATOR_FAILURE, error } }
}




function getSkillIndicatorList(data) {


    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'getSkillIndicatorList'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {

                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: languageConstants.GET_SKILL_INDICATOR_REQUEST, user } }
    function success(user) { return { type: languageConstants.GET_SKILL_INDICATOR_SUCCESS, user } }
    function failure(error) { return { type: languageConstants.GET_SKILL_INDICATOR_FAILURE, error } }
}



function deleteSkillIndicator(data, temp) {
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'deleteSkillIndicator'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success("Skill Deleted"))
                    dispatch(languageAction.getSkillIndicatorList(temp))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: languageConstants.DELETE_SKILL_REQUEST, user } }
    function success(user) { return { type: languageConstants.DELETE_SKILL_SUCCESS, user } }
    function failure(error) { return { type: languageConstants.DELETE_SKILL_FAILURE, error } }
}


function deleteBoard(data, temp) {
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'deleteBoard'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success("Board Deleted Successfully"))
                    dispatch(languageAction.getBoardList(temp))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: languageConstants.DELETE_BOARD_REQUEST, user } }
    function success(user) { return { type: languageConstants.DELETE_BOARD_SUCCESS, user } }
    function failure(error) { return { type: languageConstants.DELETE_BOARD_FAILURE, error } }
}



function getBoardList(data) {


    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'getBoardList'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: languageConstants.BOARD_ADD_REQUEST, user } }
    function success(user) { return { type: languageConstants.BOARD_ADD_SUCCESS, user } }
    function failure(error) { return { type: languageConstants.BOARD_ADD_FAILURE, error } }
}


function updateSkillIndicator(data, temp) {
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'updateSkillIndicator'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success("Skill Updated Successfully"))
                    dispatch(languageAction.getSkillIndicatorList(temp))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: languageConstants.UPDATE_Skill_REQUEST, user } }
    function success(user) { return { type: languageConstants.UPDATE_Skill_SUCCESS, user } }
    function failure(error) { return { type: languageConstants.UPDATE_Skill_FAILURE, error } }
}



function updateBoard(data, temp) {
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'updateBoard'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success("Board Updated Successfully"))
                    dispatch(languageAction.getBoardList(temp))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: languageConstants.UPDATE_BOARD_REQUEST, user } }
    function success(user) { return { type: languageConstants.UPDATE_BOARD_SUCCESS, user } }
    function failure(error) { return { type: languageConstants.UPDATE_BOARD_FAILURE, error } }
}




function createNewSkillIndicator(data, temp) {
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'createNewSkillIndicator'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success("Skill Created Successfully"))
                    dispatch(languageAction.getSkillIndicatorList(temp))

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: languageConstants.SKILL_ADD_REQUEST, user } }
    function success(user) { return { type: languageConstants.SKILL_ADD_SUCCESS, user } }
    function failure(error) { return { type: languageConstants.SKILL_ADD_FAILURE, error } }
}





function createBoard(data, temp) {


    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'createBoard'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success("Board Created Successfully"))
                    dispatch(languageAction.getBoardList(temp))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: languageConstants.BOARD_ADD_REQUEST, user } }
    function success(user) { return { type: languageConstants.BOARD_ADD_SUCCESS, user } }
    function failure(error) { return { type: languageConstants.BOARD_ADD_FAILURE, error } }
}





function getLoginHistoryList(data) {
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'getLoginHistoryList'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: languageConstants.GET_LOGIN_HISTORY_REQUEST, user } }
    function success(user) { return { type: languageConstants.GET_LOGIN_HISTORY_SUCCESS, user } }
    function failure(error) { return { type: languageConstants.GET_LOGIN_HISTORY_FAILURE, error } }
}






function upload(data) {


    const credentials = {
        header: headerForPrivateMediaAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'upload'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunctionForFile(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success("File Uploaded"))

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: languageConstants.FILE_UPLOADED_REQUEST, user } }
    function success(user) { return { type: languageConstants.FILE_UPLOADED_SUCCESS, user } }
    function failure(error) { return { type: languageConstants.FILE_UPLOADED_FAILURE, error } }
}


function getServiceById(data) {
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'getServiceById'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: languageConstants.GET_SINGLE_SERVICE_REQUEST, user } }
    function success(user) { return { type: languageConstants.GET_SINGLE_SERVICE_SUCCESS, user } }
    function failure(error) { return { type: languageConstants.GET_SINGLE_SERVICE_FAILURE, error } }
}



function createLanguageSetting(data, temp) {


    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'createLanguageSetting'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success("Language Setting Created Successfully"))
                    dispatch(languageAction.getAllLanguageSetting(temp))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: languageConstants.LANGUAGE_SETTING_ADD_REQUEST, user } }
    function success(user) { return { type: languageConstants.LANGUAGE_SETTING_ADD_SUCCESS, user } }
    function failure(error) { return { type: languageConstants.LANGUAGE_SETTING_ADD_FAILURE, error } }
}



function createLanguage(data) {

    let temp = {
        "keyWord": "",
        "pageNo": 1,
        "sortBy": "slug",
        "sortOrder": "asc",
        "fromDate": "",
        "toDate": "",
        "size": 10
    }

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'createLanguage'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success("Language Created Successfully"))
                    dispatch(languageAction.getLanguageList(temp))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: languageConstants.ADD_LANGUAGE_REQUEST, user } }
    function success(user) { return { type: languageConstants.ADD_LANGUAGE_SUCCESS, user } }
    function failure(error) { return { type: languageConstants.ADD_LANGUAGE_FAILURE, error } }
}

function getLanguageList(data) {
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'getLanguageListForAdmin'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: languageConstants.GET_LANGUAGE_REQUEST, user } }
    function success(user) { return { type: languageConstants.GET_LANGUAGE_SUCCESS, user } }
    function failure(error) { return { type: languageConstants.GET_LANGUAGE_FAILURE, error } }
}




function updateLanguage(data, temp) {
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'updateLanguage'
    }
    return dispatch => {
        dispatch(request({ data }));

        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(languageAction.getLanguageList(temp))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: languageConstants.UPDATE_LANGUAGE_REQUEST, user } }
    function success(user) { return { type: languageConstants.UPDATE_LANGUAGE_SUCCESS, user } }
    function failure(error) { return { type: languageConstants.UPDATE_LANGUAGE_FAILURE, error } }
}


function updateLanguageSetting(data, temp) {
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'updateLanguageSetting'
    }
    return dispatch => {
        dispatch(request({ data }));

        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(languageAction.getAllLanguageSetting(temp))
                    dispatch(alertActions.success("Updated"));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: languageConstants.UPDATE_LANGUAGE_SETTING_REQUEST, user } }
    function success(user) { return { type: languageConstants.UPDATE_LANGUAGE_SETTING_SUCCESS, user } }
    function failure(error) { return { type: languageConstants.UPDATE_LANGUAGE_SETTING_FAILURE, error } }
}




function deleteLanguageSetting(data, paginationdata) {
    console.log(paginationdata)
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'deleteLanguageSetting'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success("Deleted"));
                    dispatch(languageAction.getAllLanguageSetting(paginationdata))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: languageConstants.DELETE_LANGUAGE_SETTING_REQUEST, user } }
    function success(user) { return { type: languageConstants.DELETE_LANGUAGE_SETTING_SUCCESS, user } }
    function failure(error) { return { type: languageConstants.DELETE_LANGUAGE_SETTING_FAILURE, error } }
}



function deleteLanguage(data, paginationdata) {
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'disableLanguage'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success("Updated"))
                    dispatch(languageAction.getLanguageList(paginationdata))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: languageConstants.DELETE_LANGUAGE_REQUEST, user } }
    function success(user) { return { type: languageConstants.DELETE_LANGUAGE_SUCCESS, user } }
    function failure(error) { return { type: languageConstants.DELETE_LANGUAGE_FAILURE, error } }
}


function getAllLanguageSetting(data) {
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'getLanguageSettingList'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: languageConstants.GET_LANGUAGE_SETTING_REQUEST, user } }
    function success(user) { return { type: languageConstants.GET_LANGUAGE_SETTING_SUCCESS, user } }
    function failure(error) { return { type: languageConstants.GET_LANGUAGE_SETTING_FAILURE, error } }
}
