export const studentManagementConstants = {
    STUDENT_CREATED_REQUEST:"STUDENT_CREATED_REQUEST",
    STUDENT_CREATED_SUCCESS:"STUDENT_CREATED_SUCCESS",
    STUDENT_CREATED_FAILURE:"STUDENT_CREATED_FAILURE",


    STUDENT_GET_REQUEST:"STUDENT_GET_REQUEST",
    STUDENT_GET_SUCCESS:"STUDENT_GET_SUCCESS",
    STUDENT_GET_FAILURE:"STUDENT_GET_FAILURE",


    STUDENT_UPDATED_REQUEST:"STUDENT_UPDATED_REQUEST",
    STUDENT_UPDATED_SUCCESS:"STUDENT_UPDATED_SUCCESS",
    STUDENT_UPDATED_FAILURE:"STUDENT_UPDATED_FAILURE",

   

};


