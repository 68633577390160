import { studentManagementConstants } from "../_constants";


export default function users(state = {}, action) {
  switch (action.type) {
    case studentManagementConstants.STUDENT_CREATED_REQUEST:
      return {
        ...state,
        loading: true
      };
    case studentManagementConstants.STUDENT_CREATED_SUCCESS:
      return {
        ...state,
        studentCreated: action.user,
        loading: false,
      };
    case studentManagementConstants.STUDENT_CREATED_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



      case studentManagementConstants.STUDENT_GET_REQUEST:
        return {
          ...state,
          loading: true
        };
      case studentManagementConstants.STUDENT_GET_SUCCESS:
        return {
          ...state,
          studentList: action&&action?.user&&action.user?.data,
          loading: false,
        };
      case studentManagementConstants.STUDENT_GET_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error
        };



        case studentManagementConstants.STUDENT_UPDATED_REQUEST:
          return {
            ...state,
            loading: true
          };
        case studentManagementConstants.STUDENT_UPDATED_SUCCESS:
          return {
            ...state,
            loading: false,
          };
        case studentManagementConstants.STUDENT_UPDATED_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.error
          };
  




    default:
      return state
  }
}

