/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Button from '../../components/Button';
import ServiceJSON from './Service.json'
import Model from './Modle/Model';
import Modelupdate from './Modle/Modelupdate';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import { languageAction, serviceAction } from '../../_actions';
import parse from 'html-react-parser';
import Tooltip from '@mui/material/Tooltip';
import Loading from "../../components/Loader/Loader"
import SearchIcon from '@mui/icons-material/Search';
import { RiEditLine } from "react-icons/ri";

const LanguageSettingNew = () => {
  let dispatch = useDispatch()
  let selector = useSelector(state => state)

  //create
  let [openAddmodel, setopenAddmodel] = useState(false)
  const [formData, setFormData] = useState({});
  let [errorCreate, seterrorCreate] = useState({})

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    seterrorCreate({ ...errorCreate, [e.target.name]: "" })

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleValidationCreate()) {
      setFormData({})
      seterrorCreate({})
      setopenAddmodel(false)
    }

  };

  const handleValidationCreate = () => {

    let formIsValid = true;
    let errors = {};

    if (!formData.fullName || formData.fullName === "") {
      formIsValid = false;
      errors.fullName = "Name cannot be empty";
    }

    if (!formData.email) {
      formIsValid = false;
      errors.email = "Email cannot be empty";
    }
    if (!formData.mobNo) {
      formIsValid = false;
      errors.mobNo = "Mobile Number cannot be empty";
    }
    if (!formData.dob) {
      formIsValid = false;
      errors.dob = "Date of Birth cannot be empty";
    }
    if (!formData.gender) {
      formIsValid = false;
      errors.gender = "Gender  cannot be empty";
    }

    if (!formData.email) {
      formIsValid = false;
      errors.email = "Email  cannot be empty";
    }

    if (!formData.grade) {
      formIsValid = false;
      errors.grade = "Grade  cannot be empty";
    }
    seterrorCreate(errors);
    return formIsValid;
  };

  // updatemodel
  let [openAddmodelUpdate, setopenAddmodelUpdate] = useState(false)
  let [updatedata, setupdatedata] = useState({})
  let [updateError, setupdateError] = useState({})
  let handleChangeUpdate = (e) => {

    setupdatedata({
      ...updatedata,
      [e.target.name]: e.target.value
    });
    setupdateError({ ...errorCreate, [e.target.name]: "" })

  }

  let handleSubmitUpdate = (e) => {
    e.preventDefault();
    if (handleValidationUpdate()) {

      setupdatedata({})
      setopenAddmodelUpdate(false)
    }

  }

  const handleValidationUpdate = () => {
    let formIsValid = true;
    let errors = {};

    if (!updatedata.fullName || updatedata.fullName === "") {
      formIsValid = false;
      errors.fullName = "Name cannot be empty";
    }

    if (!updatedata.email) {
      formIsValid = false;
      errors.email = "Email cannot be empty";
    }
    if (!updatedata.mobNo) {
      formIsValid = false;
      errors.mobNo = "Mobile Number cannot be empty";
    }
    if (!updatedata.dob) {
      formIsValid = false;
      errors.dob = "Date of Birth cannot be empty";
    }
    if (!updatedata.gender) {
      formIsValid = false;
      errors.gender = "Gender  cannot be empty";
    }

    if (!updatedata.grade) {
      formIsValid = false;
      errors.grade = "Grade  cannot be empty";
    }

    setupdateError(errors);
    return formIsValid;
  };
  // updatemodel

  //--------Pagination-------------------------
  const [size, setSize] = useState(10);
  const [isMobile, setIsMobile] = useState(true);
  //--------Pagination-------------------------
  let [offset, setoffset] = useState(0)
  const handlePageClick = (data) => {
    setoffset(Math.ceil(data.selected * 10))
    let datas = {
      "keyWord": "",
      "pageNo": data.selected + 1,
      "size": 10
    }

    let temps = {
      "keyWord": "",
      "pageNo": 1,
      "sortBy": "",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": 150
    }
    dispatch(languageAction.getLanguageList(temps))
    let tempdata = {
      "keyWord": searchKeyword,
      "pageNo": data.selected + 1,
      "sortBy": "",
      "sortOrder": "asc",
      "size": 10
    }
    dispatch(languageAction.getLanguageSetting2List(tempdata))


    // dispatch(serviceAction.getAllService(datas))

  };
  //--------Pagination-------------------------

  //Search
  let [searchKeyword, setsearchKeyword] = useState("")
  let handleSearch = (e) => {
    setsearchKeyword(e.target.value)
  }
  useEffect(() => {

    let temps = {
      "keyWord": "",
      "pageNo": 1,
      "sortBy": "",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": 150
    }
    dispatch(languageAction.getLanguageList(temps))
    let tempdata = {
      "keyWord": searchKeyword,
      "pageNo": 1,
      "sortBy": "",
      "sortOrder": "asc",
      "size": 10
    }
    dispatch(languageAction.getLanguageSetting2List(tempdata))
  }, [searchKeyword])
  //search
  //Delete



  let { language } = selector || {}

  let { loading } = language && language ? language : {}

  let { list, total } = language && language?.languageSettingTwo && language?.languageSettingTwo?.data ? language?.languageSettingTwo?.data : {}



  let shortvalue = "en"



  return (
    <>
      <Loading loading={loading} />
      <div className=' h-[90vh] w-full overflow-y-scroll  py-3 md:px-5 px-1  '>
        <div className=' w-full h-full  max-w-[100vw] '>
          <div className='flex justify-between md:flex-row flex-col gap-2 p-5'>
            <div className=' font-semibold text-2xl'>{ServiceJSON.Heading}</div>
            <Link to={"/app/languageSetting/add"}>
              <Button data={"Add Language"} />
            </Link>
          </div>
          <div className='md:py-10 md:px-10 '>
            <div className=''>
              <div className='flex flex-col items-start gap-4 text-[#7E84A3]'>
                <div className=' max-w-lg bg-white h-[40px] flex items-center border border-[#D9E1EC] rounded gap-2'>
                  <SearchIcon className='w-6 h-6 text-gray-400 ml-2' />
                  <input
                    className='flex-grow h-full outline-none text-sm'
                    onChange={(e) => handleSearch(e)}
                    value={searchKeyword}
                    type='text'
                    placeholder='Search '
                  />
                </div>
              </div>
              <div className=' flex items-center gap-4 text-[#7E84A3] '>

              </div>


              {/*Table  */}
              <div className=' '>
                <div className="relative overflow-y-scroll   shadow-md sm:rounded-lg mt-5 ">
                  <table className=" w-full text-sm text-left rtl:text-right  text-gray-500 divide-y-4">
                    <thead className="text-[14px] text-[#5A607F] w-full    bg-white capitalize  ">
                      <tr className=' w-full'>

                        <th className="px-6 whitespace-nowrap  font-bold  py-3">
                          Name
                        </th>

                        <th className="px-6 py-3  font-bold ">
                          Key
                        </th>

                        <th className="px-6 whitespace-nowrap py-3  font-bold ">
                          Value
                        </th>

                        <th className="px-6 py-3  font-bold ">
                          Action
                        </th>


                      </tr>
                    </thead>


                    {
                      list && total > 0 && list.map((e, i) => {

                        return (
                          <tbody key={i} className=' text-gray-500'>
                            <tr className='bg-white'>
                              <td className="px-6 py-3 font-normal ">
                                <Tooltip title={e.name || "--"} arrow>
                                  <div className=' w-36' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                    {e.name || "--"}
                                  </div>
                                </Tooltip>

                              </td>

                              <td className="px-6 whitespace-nowrap overflow-ellipsis font-normal py-3">
                                <Tooltip title={parse(e.key || "--")} arrow>
                                  <div className=' overflow-hidden  w-36 '>
                                    {parse(e.key || "--")}
                                  </div>
                                </Tooltip>
                              </td>


                              <td className="px-6 py-3 font-normal ">
                                <Tooltip title={e.value.en || "--"} arrow>
                                  <div className=' w-36' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                    {e.value.en || "--"}
                                  </div>
                                </Tooltip>

                              </td>






                              <td className="flex items-center justify-start px-6 py-2 gap-3">
                                < Link title='Edit' to={`/app/languageSetting/add/${e.id}`} className='cursor-pointer shadow-md border p-1 '><RiEditLine className=' text-blue-600 text-2xl' /></Link>
                              </td>
                            </tr>
                          </tbody>
                        )
                      })
                    }

                  </table>

                  {isMobile ? (
                    list && total > 10 ? (
                      <ReactPaginate
                        previousLabel={'Prev'}
                        nextLabel={'Next'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={Math.ceil(total / size)}
                        marginPagesDisplayed={0}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination'}
                        pageClassName={'page-cls'}
                        activeClassName={'active'}
                      />
                    ) : null
                  ) : (
                    list && total > 10 ? (
                      <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={Math.ceil(total / size)}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination'}
                        pageClassName={'page-cls'}
                        activeClassName={'active'}
                      />
                    ) : null
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Model formData={formData} setFormData={setFormData} seterrorCreate={seterrorCreate} errorCreate={errorCreate} handleChange={handleChange} handleSubmit={handleSubmit} openAddmodel={openAddmodel} setopenAddmodel={setopenAddmodel} />
      <Modelupdate shortvalue={shortvalue} updatedata={updatedata} setupdatedata={setupdatedata} handleSubmitUpdate={handleSubmitUpdate} setupdateError={setupdateError} updateError={updateError} handleChangeUpdate={handleChangeUpdate} openAddmodelUpdate={openAddmodelUpdate} setopenAddmodelUpdate={setopenAddmodelUpdate} />
    </>
  );
};

export default LanguageSettingNew;
