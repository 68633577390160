/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import LanguageManagementJSON from './LanguageManagement.json'
import { RiEditLine } from "react-icons/ri";
import Model from "./Modle/Model"
import { useDispatch, useSelector } from 'react-redux';
import { languageAction } from '../../_actions/language.action';
import Modelupdate from './Modle/Modelupdate';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactPaginate from 'react-paginate';

import Loading from "../../components/Loader/Loader"
import axios from 'axios';
import { authHeader } from '../../_helpers';

const FirstScreen = () => {
  let selector = useSelector(state => state)
  let dispatch = useDispatch()

  let [openAddmodel, setopenAddmodel] = useState(false)
  const [formData, setFormData] = useState({});
  let [errorCreate, seterrorCreate] = useState({})
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    seterrorCreate({ ...errorCreate, [e.target.name]: "" })

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleValidationCreate()) {
      dispatch(languageAction.createLanguage(formData))
      setFormData({})
      setopenAddmodel(false)
    }

  };
  const [loaderImage, setLoaderImage] = useState(false)
  const [imageChange, setimageChange] = useState({})

  let handleImage = async (e) => {
    console.log("hellow")
    let { name } = e.target;
    const formData = new FormData();
    formData.append('image', e.target.files[0]);
    try {
      setLoaderImage(true)
      const response = await axios.post(`https://orkid.jamsara.com/api/upload`, formData, {
        headers: {
          'Authorization': authHeader().Authorization,
          'Content-Type': 'multipart/form-data'
        }
      });
      const responseData = response.data;

      setimageChange({ ...imageChange, [name]: responseData?.imageURL });
      setLoaderImage(false)
      return responseData?.imageURL;
    } catch (error) {
      setLoaderImage(false)
      console.error('Error:', error);
      throw error;
    }
  }



  const handleValidationCreate = () => {
    let formIsValid = true;
    let errors = {};

    if (!formData.name) {
      formIsValid = false;
      errors.name = "Language cannot be empty";
    }

    if (!formData.scriptName) {
      formIsValid = false;
      errors.scriptName = "ScriptName canot be empty";
    }
    if (!formData.shortName) {
      formIsValid = false;
      errors.shortName = "ShortName canot be empty";
    }

    seterrorCreate(errors);
    return formIsValid;
  };

  // updatemodel
  let [openAddmodelUpdate, setopenAddmodelUpdate] = useState(false)
  let [updatedata, setupdatedata] = useState({})
  let [id,setId]=useState('')
  let [updateError, setupdateError] = useState({})
  let handleUpdatemodel = (data) => {
    setopenAddmodelUpdate(true)
    setupdatedata(data)
    setId(data._id)

  }
  console.log(id)
  let handleChangeUpdate = (content, delta, source, editor) => {

    setupdatedata({
      ...updatedata,
      link: content
    });

  }

  let handleSubmitUpdate = (e) => {

    e.preventDefault();
    if (handleValidationUpdate()) {
      let obj = {
        "id": id,
        "link": updatedata.link,
      }
      let temp = {
        "keyWord": "",
        "pageNo": 1,
        "sortBy": "slug",
        "sortOrder": "asc",
        "fromDate": "",
        "toDate": "",
        "size": 10
      }
      dispatch(languageAction.updateFirstScreen(obj, temp))
      setopenAddmodelUpdate(false)
      setFormData({})
      setId('')
    }

  }

  const handleValidationUpdate = () => {
    let formIsValid = true;
    let errors = {};

    if (!updatedata.link) {
      formIsValid = false;
      errors.link = "Link Cannot be Empty ";
    }


    setupdateError(errors);
    return formIsValid;
  };
  //Delete
  let handleDelete = (data) => {
    console.log(data)
    let datatemp = {
      "id": data._id,
    }
    let paginationdata = {
      "keyWord": "",
      "pageNo": 1,
      "size": 10
    }
    confirmAlert({
      title: ' Delete ',
      message: `Are you sure want to delete ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(languageAction.deleteLanguage(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  //Search
  let [searchKeyword, setsearchKeyword] = useState("")
  let handleSearch = (e) => {
    setsearchKeyword(e.target.value)
  }
  useEffect(() => {
    let temp = {
      "keyWord": "",
      "pageNo": 1,
      "sortBy": "slug",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": 10
    }
    dispatch(languageAction.getFirstScreenList(temp))
  }, [searchKeyword])


  //--------Pagination-------------------------
  const [size, setSize] = useState(10);
  const [isMobile, setIsMobile] = useState(true);
  //--------Pagination-------------------------
  let [offset, setoffset] = useState(0)
  const handlePageClick = (data) => {
    setoffset(Math.ceil(data.selected * 10))
    let datas = {
      "keyWord": "",
      "pageNo": data.selected + 1,
      "size": 10,
      "sortBy": "slug",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
    }
    dispatch(languageAction.getLanguageList(datas))
  };
  //--------Pagination-------------------------


  let { language } = selector || {}
  let { loading, firstScreenData } = language && language ? language : {}
  let { data } = firstScreenData && firstScreenData ? firstScreenData : {}

  let { list, total } = data && data ? data : {}


  return (

    <>


      <Loading loading={loading} />

      <div className=' h-[90vh] w-full overflow-y-scroll  py-3 md:px-5 px-1  '>
        <div className=' w-full h-full  max-w-[100vw] '>
          <div className=' flex justify-between w-[80vw] flex-wrap gap-2 p-5'>
            <div className=' font-semibold text-2xl'>{LanguageManagementJSON.Heading}</div>
            {/* <div onClick={() => setopenAddmodel(true)}>
              <Button data={"Add Language"} />
            </div> */}
          </div>

          <div className='md:py-10 md:px-10 '>
            <div className=''>
              {/* search and filter area */}
              <div className=' flex items-center gap-4 text-[#7E84A3] '>


              </div>

              <div className='  '>
                {/* table--------------------------------------------------------------------------------------- */}
                <div className="relative overflow-x-scroll w-full  shadow-md sm:rounded-lg mt-5 ">
                  <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 divide-y-4">

                    <thead className="text-[14px] text-[#5A607F]    bg-white capitalize  ">
                      <tr>

                        <th className="px-6 whitespace-nowrap font-bold  py-3">
                          Link
                        </th>

                        <th className="px-6 py-3 font-bold ">

                        </th>

                      </tr>
                    </thead>
                    {list && total > 0 && list.map((e, i) => (


                      <tbody key={i} className=' divide-y-4'>
                        <tr className="bg-white border-b   hover:bg-gray-50  ">
                          <td className="w-4  overflow-hidden  p-4 ">
                            <div className="flex w-full gap-2 items-center  space-x-6 whitespace-nowrap">

                              <div style={{ width: "600px", height: "400px", overflow: 'hidden' }} dangerouslySetInnerHTML={{ __html: e?.link || "" }} />
                            </div>
                          </td>

                          <td className="flex items-center justify-end  px-6 py-4 gap-3 ">
                            <div onClick={() => handleUpdatemodel(e)} className=' cursor-pointer shadow-md border p-1 '><RiEditLine className=' text-blue-600 text-2xl' /></div>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </table>
                  {isMobile ? (
                    list && total > 10 ? (
                      <ReactPaginate
                        previousLabel={'Prev'}
                        nextLabel={'Next'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={Math.ceil(total / size)}
                        marginPagesDisplayed={0}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination'}
                        pageClassName={'page-cls'}
                        activeClassName={'active'}
                      />
                    ) : null
                  ) : (
                    list && total > 10 ? (
                      <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={Math.ceil(total / size)}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination'}
                        pageClassName={'page-cls'}
                        activeClassName={'active'}
                      />
                    ) : null
                  )}
                </div>
                {/* table--------------------------------------------------------------------------------------- */}
              </div>
            </div>
          </div>
        </div>
      </div>



      <Model formData={formData} setFormData={setFormData} errorCreate={errorCreate} handleChange={handleChange} handleSubmit={handleSubmit} openAddmodel={openAddmodel} setopenAddmodel={setopenAddmodel} />
      <Modelupdate imageChange={imageChange} setimageChange={setimageChange} loaderImage={loaderImage} setLoaderImage={setLoaderImage} handleImage={handleImage} updatedata={updatedata} handleSubmitUpdate={handleSubmitUpdate} updateError={updateError} handleChangeUpdate={handleChangeUpdate} setupdateError={setupdateError} openAddmodelUpdate={openAddmodelUpdate} setopenAddmodelUpdate={setopenAddmodelUpdate} />

    </>
  );
};


export default FirstScreen;
