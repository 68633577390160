/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ServicesComponent from '../../components/ServicesComponent';
import { useDispatch, useSelector } from 'react-redux';
import { languageAction } from '../../_actions/language.action';
import Button from '../../components/Button';
import { alertActions, serviceAction } from '../../_actions';
import ServiceJSON from './Service.json'
import Loader from '../../components/Loader/Loader'
import { useNavigate } from 'react-router';



const ServiceAdd = () => {

  let dispatch = useDispatch()
  let selector = useSelector(state => state)
  let temp = {
    "keyWord": "",
    "pageNo": 1,
    "sortBy": "",
    "sortOrder": "asc",
    "fromDate": "",
    "toDate": "",
    "size": 150
  }
  useEffect(() => {

    dispatch(languageAction.getLanguageList(temp))
  }, [])
  let { language } = selector;
  let { getlanguageSuccess, loading } = language ? language : {}
  let { list, total } = getlanguageSuccess && getlanguageSuccess ? getlanguageSuccess : {}

  let handleChange = (e) => {
    let { name, value } = e.target
    setvaluetoSubmit({ ...valuetoSubmit, [name]: value })

  }
  const [value, setValue] = useState({});

  const [textdata, setTextdata] = useState({})


  const handleTextEditor = (e, naam) => {
    console.log(e, naam)

    setTextdata({ ...textdata, [naam]: e })
    // setTextdata({...textdata,[delta]:content})
  };

  //Service
  let [valuetoSubmit, setvaluetoSubmit] = useState({})
  //textEditor
  let [imageChange, setimageChange] = useState({})



  let navigate = useNavigate()
  let create = (obj) => {
    dispatch(serviceAction.createNewService(obj, temp))
    navigate("/app/service")
  }


  let handleSubmit = () => {

    let obj = {
      "service": valuetoSubmit,
      "image": imageChange,
      "content": textdata || ""
    }

    console.log(obj)

    Object.keys(obj.service).length !== 0 ? create(obj) : alertActions.error("Fields are Empty")

  }
  return (
    <>
      <Loader loading={loading} />
      <div className=' h-[90vh] overflow-y-scroll flex-col w-full ovewr  py-3 px-5 flex '>
        <div className=' flex justify-between p-5'>
          <div className=' font-semibold text-2xl'>{ServiceJSON.Heading}</div>
        </div>
        <div className=' p-10 w-full h-full flex flex-col gap-4'>
          {list && total > 0 && list.filter((e) => e.shortName === "en").map((elem, index) => {
            return <div key={index}>
              <ServicesComponent value={value} setValue={setValue} elem={elem} handleChange={handleChange} valuetoSubmit={valuetoSubmit} imageChange={imageChange} setimageChange={setimageChange} handleTextEditor={handleTextEditor} setvaluetoSubmit={setvaluetoSubmit} />
            </div>

          })}
          <div onClick={handleSubmit} >

            <Button data={"Add"} />
          </div>

        </div>
      </div>

    </>
  );
};

export default ServiceAdd;
