import { alertActions } from './alert.actions';
import { APIcallFunction, headerForPrivateAPI } from '../_helpers';
import { userManagementConstants } from '../_constants';


export const userManagementAction = {
    getUserList,
    createNewUser,
    updateUser,
    deleteUser,
    getAllUser
};
function getAllUser(data) {
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'getAllUser'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: userManagementConstants.GET_ALL_USER_MANAGEMENT_LIST_REQUEST, user } }
    function success(user) { return { type: userManagementConstants.GET_ALL_USER_MANAGEMENT_LIST_SUCCESS, user } }
    function failure(error) { return { type: userManagementConstants.GET_ALL_USER_MANAGEMENT_LIST_FAILURE, error } }
}



function getUserList(data) {
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'getUserList'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: userManagementConstants.GET_USER_MANAGEMENT_LIST_REQUEST, user } }
    function success(user) { return { type: userManagementConstants.GET_USER_MANAGEMENT_LIST_SUCCESS, user } }
    function failure(error) { return { type: userManagementConstants.GET_USER_MANAGEMENT_LIST_FAILURE, error } }
}

function createNewUser(data, temp) {
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'createNewUser'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(userManagementAction.getUserList(temp))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: userManagementConstants.CREATE_USER_MANAGEMENT_LIST_REQUEST, user } }
    function success(user) { return { type: userManagementConstants.CREATE_USER_MANAGEMENT_LIST_SUCCESS, user } }
    function failure(error) { return { type: userManagementConstants.CREATE_USER_MANAGEMENT_LIST_FAILURE, error } }
}

function updateUser(data, temp) {
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'updateUser'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success("Updated"));
                    dispatch(userManagementAction.getUserList(temp))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: userManagementConstants.CREATE_USER_MANAGEMENT_LIST_REQUEST, user } }
    function success(user) { return { type: userManagementConstants.CREATE_USER_MANAGEMENT_LIST_SUCCESS, user } }
    function failure(error) { return { type: userManagementConstants.CREATE_USER_MANAGEMENT_LIST_FAILURE, error } }
}


function deleteUser(data, temp) {
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'deleteUser'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success("Deleted"));
                    dispatch(userManagementAction.getUserList(temp))

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: userManagementConstants.DELETE_USER_MANAGEMENT_LIST_REQUEST, user } }
    function success(user) { return { type: userManagementConstants.DELETE_USER_MANAGEMENT_LIST_SUCCESS, user } }
    function failure(error) { return { type: userManagementConstants.DELETE_USER_MANAGEMENT_LIST_FAILURE, error } }
}